import Tooltip from '@/components/Tooltip/Tooltip';
import {
  ActionCategory,
  ActionEffort,
  actionEffortDescription,
  ActionImpact,
} from '../types';
import { ActionHeaderImpactBubbles } from './ActionHeaderImpactBubbles';

export default function ActionImpactAndEffortDisplay({
  impact,
  effort,
  category,
}: {
  impact?: ActionImpact;
  effort?: ActionEffort;
  category?: ActionCategory;
}) {
  if (!impact) return null;

  return (
    <div className="flex items-center space-x-2">
      <Tooltip tooltip="Impact">
        <ActionHeaderImpactBubbles impact={impact} />
      </Tooltip>
      {impact && effort ? <p className="p5">·</p> : null}
      <Tooltip tooltip="Effort">
        <p className="p6">{actionEffortDescription(effort)}</p>
      </Tooltip>
      {category && category.title ? <p className="p5">·</p> : null}
      {category ? <p className="p5">{category?.title}</p> : null}
    </div>
  );
}
