import { IIcon } from './types';

function Analytics({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M22.0017 40.8571C32.4162 40.8571 40.8588 32.4145 40.8588 22C40.8588 11.5855 32.4162 3.14282 22.0017 3.14282C11.5872 3.14282 3.14453 11.5855 3.14453 22C3.14453 32.4145 11.5872 40.8571 22.0017 40.8571Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22V3.14282"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3334 12.5714L5.67188 31.4286"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Analytics.displayName = 'Analytics';

export default Analytics;
