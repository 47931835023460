import { IIcon } from './types';

function Check({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 16 17">
      <circle cx="8" cy="8.5" r="8" fill="#19A47A" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M11.335 6L6.75 10.583 4.668 8.5"
      />
    </svg>
  );
}

Check.displayName = 'Check';

export default Check;
