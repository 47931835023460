import SocialFacebook from '@/components/Icons/SocialFacebook';
import SocialLinkedIn from '@/components/Icons/SocialLinkedIn';
import { segmentEventName, sendAnalyticsEvent } from '@/helpers/analytics';
import { useInterval } from '@/hooks/useInterval';
import styled from '@emotion/styled';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckCircle, Copy } from 'react-feather';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import tw from 'twin.macro';

const ShareButtonContainer = styled.div`
  ${tw`flex h-11 items-center justify-start rounded-md px-4`}
`;

const ShareButtonLabel = styled.span`
  h7
  ${tw`block p-2 whitespace-nowrap`}
`;

export default function GenericShare() {
  const shareUrl = 'https://greener.com.au';

  const [copyLinkConfirmation, setCopyLinkConfirmation] =
    React.useState<boolean>(false);
  useInterval(
    () => {
      setCopyLinkConfirmation(false);
    },
    copyLinkConfirmation ? 3000 : null,
  );

  return (
    <div className="flex flex-col items-center space-y-6.5 rounded-2.5xl bg-green-100 px-5 py-6.5 md:space-y-8 md:px-7.5 md:py-10">
      <ShareText />
      <div className="flex flex-row items-stretch justify-center space-x-4">
        <CopyToClipboard
          options={{ message: 'Copied' }}
          text={shareUrl}
          onCopy={() => {
            setCopyLinkConfirmation(true);
            sendAnalyticsEvent({
              event: segmentEventName.buttonClicked,
              properties: {
                action: 'Share button',
                share_link: shareUrl,
                share_button_type: 'copy',
              },
            });
          }}
        >
          <button>
            <ShareButtonContainer className="border-gray-400 bg-gray-50 text-gray-900">
              {copyLinkConfirmation ? (
                <CheckCircle className="h-5 w-5" />
              ) : (
                <Copy className="h-5 w-5" />
              )}

              <ShareButtonLabel className="w-22">
                {copyLinkConfirmation ? 'Copied!' : 'Copy link'}
              </ShareButtonLabel>
            </ShareButtonContainer>
          </button>
        </CopyToClipboard>

        <LinkedinShareButton
          url={shareUrl}
          onClick={() => {
            sendAnalyticsEvent({
              event: segmentEventName.buttonClicked,
              properties: {
                acton: 'Share button',
                share_link: shareUrl,
                share_button_type: 'linked_in',
              },
            });
          }}
          // title="Title"
          // summary="Summary"
          // source="Greener for Business"
        >
          <ShareButtonContainer className="bg-[#2A66BC] text-white">
            <SocialLinkedIn color="white" className="h-5 w-5" />
            <ShareButtonLabel className="max-md:hidden">Share</ShareButtonLabel>
          </ShareButtonContainer>
        </LinkedinShareButton>

        <FacebookShareButton
          url={shareUrl}
          // quote="Quote"
          hashtag="#greenerforbusiness"
          onClick={() => {
            sendAnalyticsEvent({
              event: segmentEventName.buttonClicked,
              properties: {
                acton: 'Share button',
                share_link: shareUrl,
                share_button_type: 'facebook',
              },
            });
          }}
        >
          <ShareButtonContainer className="bg-[#0866FF] text-white">
            <SocialFacebook color="white" className="h-5 w-5" />
            <ShareButtonLabel className="max-md:hidden">Share</ShareButtonLabel>
          </ShareButtonContainer>
        </FacebookShareButton>
      </div>

      {/* Image hided for now */}
      {/* <div className="relative min-h-[9.875rem] min-w-[18.75rem] md:min-h-[17rem] md:w-[32.5rem]">
        <Image
          alt="Share Image"
          layout="fill"
          objectFit="contain"
          src={ShareImageGeneric}
        />
      </div> */}
    </div>
  );
}

function ShareText() {
  return (
    <div className="space-y-2">
      <h4 className="text-center">Make your impact go further</h4>
      <p className="p4 text-center">
        Encouraging more businesses to take action is one of the most impactful
        things you can do. Copy or share your personal invite link.
      </p>
    </div>
  );
}

// Old URL - Keep it here just a little bit to be sure is not used any more - 14/08/24 (DD/MM/YYYY)
// const organisationId = useOrganisationId();
// const shareUrl =
//   process.env.NODE_ENV == 'development'
//     ? `https://business.getgreener.com/join`
//     : `https://${process.env.NEXT_PUBLIC_SITE_URL}/share/organisations/${organisationId}`;
