import api from 'lib/api/api';
import Router from 'next/router';
import { DataResponse } from '../api/types';
import {
  AppInvitation,
  CreateOrganisationParams,
  CurrentUserOrganisation,
  Organisation,
  OrganisationAddress,
  OrganisationOnboardingForm,
  OrganisationPoints,
} from './types';

const organisationApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'Organisation',
      'OrganisationMember',
      'OrganisationPoints',
      'OrganisationOnboardingForm',
      'OrganisationActionsOnboarding',
      'OrganisationAppInvitation',
    ],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      /* List the organisations that the current user has access to */
      listOrganisations: builder.query<CurrentUserOrganisation[], void>({
        query: () => ({
          url: '/api/cso/organisations?version=2',
        }),
        providesTags: [{ type: 'Organisation', id: 'LIST' }],
        transformResponse: (r: DataResponse<CurrentUserOrganisation[]>) =>
          r.data,
      }),
      /* Get organisation by id */
      getOrganisation: builder.query<
        CurrentUserOrganisation,
        { id: string; dontRedirectOn404?: boolean }
      >({
        query: ({ id }) => ({
          url: `/api/cso/organisations/${id}`,
        }),
        transformResponse: (r: DataResponse<CurrentUserOrganisation>) => r.data,
        async onQueryStarted({ dontRedirectOn404 }, { queryFulfilled }) {
          // `onStart` side-effect
          // dispatch(messageCreated('Fetching posts...'))
          try {
            await queryFulfilled;
            // `onSuccess` side-effect
            // dispatch(messageCreated('Posts received!'))
          } catch (err) {
            // `onError` side-effect

            // Turn the error into a thign we can work with..
            let error: { error: { originalStatus: number } } | undefined;
            if (Object.prototype.hasOwnProperty.call(err, 'error')) {
              error = err as { error: { originalStatus: number } };
            }

            // If they haven't said they dont want to redirect when this 404's,
            // redirect them to the home page
            if (!dontRedirectOn404 && error?.error?.originalStatus == 404) {
              Router.push('/organisations/select');
            }
          }
        },
        providesTags: ['Organisation'],
      }),
      /* Create a new organisation */
      createOrganisation: builder.mutation<
        Organisation,
        {
          orgParams: CreateOrganisationParams;
        }
      >({
        query: ({ orgParams }) => ({
          url: `/api/cso/organisations`,
          method: 'POST',
          body: {
            organisation: orgParams,
          },
        }),
        invalidatesTags: ['Organisation'],
        transformResponse: (r: DataResponse<Organisation>) => r.data,
      }),
      /* Update an organisation */
      updateOrganisation: builder.mutation<
        Organisation,
        {
          id: string;
          orgParams: CreateOrganisationParams;
        }
      >({
        query: ({ id, orgParams }) => ({
          url: `/api/cso/organisations/${id}`,
          method: 'PUT',
          body: { organisation: orgParams },
        }),
        transformResponse: (r: DataResponse<Organisation>) => r.data,
        invalidatesTags: ['Organisation'],
      }),
      /* Get total points of an organisation */
      getOrganisationPoints: builder.query<OrganisationPoints, { id: string }>({
        query: ({ id }) => ({
          url: `/api/cso/organisations/${id}/points`,
        }),
        providesTags: ['OrganisationPoints'],
        transformResponse: (r: DataResponse<OrganisationPoints>) => r.data,
      }),
      getOnboardingForm: builder.query<
        OrganisationOnboardingForm,
        { organisationId: string }
      >({
        query: ({ organisationId }) => ({
          url: `/api/cso/organisations/${organisationId}/onboarding_form`,
        }),
        providesTags: (_response, _error, c) => [
          { type: 'OrganisationOnboardingForm', id: c.organisationId },
        ],
        transformResponse: (r: DataResponse<OrganisationOnboardingForm>) =>
          r.data,
      }),
      /* Create the form - e.g. get a token */
      createOnboardingForm: builder.mutation<
        OrganisationOnboardingForm,
        { organisationId: string }
      >({
        query: ({ organisationId: id }) => ({
          url: `/api/cso/organisations/${id}/onboarding_form`,
          method: 'POST',
          body: {},
        }),
        transformResponse: (r: DataResponse<OrganisationOnboardingForm>) =>
          r.data,
        invalidatesTags: (_response, _error, c) => [
          { type: 'OrganisationOnboardingForm', id: c.organisationId },
        ],
      }),
      /* Update the form state */
      updateOnboardingForm: builder.mutation<
        OrganisationOnboardingForm,
        {
          organisationId: string;
          is_onboarding_form_submitted: boolean;
          onboarding_form_submitted_at?: number;
        }
      >({
        query: ({
          organisationId: id,
          is_onboarding_form_submitted,
          onboarding_form_submitted_at,
        }) => ({
          url: `/api/cso/organisations/${id}/onboarding_form`,
          method: 'PUT',
          body: {
            onboarding_form: {
              is_onboarding_form_submitted,
              onboarding_form_submitted_at,
            },
          },
        }),
        transformResponse: (r: DataResponse<OrganisationOnboardingForm>) =>
          r.data,
        invalidatesTags: (_response, _error, c) => [
          { type: 'OrganisationOnboardingForm', id: c.organisationId },
        ],
      }),

      getAppInvitation: builder.query<
        AppInvitation,
        { organisationId: string }
      >({
        query: ({ organisationId }) => ({
          url: `/api/cso/organisations/${organisationId}/app_invitation`,
          method: 'GET',
        }),
        providesTags: ['OrganisationAppInvitation'],
        transformResponse: (r: DataResponse<AppInvitation>) => r.data,
      }),

      updateAppInvitation: builder.mutation<
        AppInvitation,
        { organisationId: string }
      >({
        query: ({ organisationId }) => ({
          url: `/api/cso/organisations/${organisationId}/app_invitation`,
          method: 'PUT',
          body: {},
        }),
        invalidatesTags: ['OrganisationAppInvitation'],
        transformResponse: (r: DataResponse<AppInvitation>) => r.data,
      }),

      currentUserOrganisationMemberInvitationUpdate: builder.mutation<
        void,
        { organisationMemberId: string; action: 'accept' | 'decline' }
      >({
        query: ({ organisationMemberId: id, action }) => ({
          url:
            action === 'accept'
              ? `/api/cso/organisation_members/${id}/accept`
              : `/api/cso/organisation_members/${id}`,
          method: action === 'accept' ? 'POST' : 'DELETE',
        }),
        invalidatesTags: (_response, _error, _params) => [
          { type: 'Organisation', id: 'LIST' },
        ],
      }),

      transferOwnership: builder.mutation<
        void,
        { organisationId: string; newOwnerOrganisationMemberId: string }
      >({
        query: ({ organisationId, newOwnerOrganisationMemberId }) => ({
          url: `/api/cso/organisations/${organisationId}/transfer/${newOwnerOrganisationMemberId}`,
          method: 'POST',
        }),
        invalidatesTags: (_response, _error, params) => [
          { type: 'Organisation', id: params.organisationId },
          { type: 'Organisation', id: `LIST-${params.organisationId}` },
          { type: 'OrganisationMember' },
        ],
      }),
      getOrganisationAddress: builder.query<
        OrganisationAddress[],
        { input: string }
      >({
        query: ({ input }) => ({
          url: `/api/cso/comparison/address_lookup?input=${input}`,
        }),
        transformResponse: (r: DataResponse<OrganisationAddress[]>) => r.data,
      }),
    }),
  });

export default organisationApi;

export const {
  useListOrganisationsQuery,
  useGetOrganisationQuery,
  useGetOrganisationPointsQuery,
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
  // Onboarding form
  useUpdateOnboardingFormMutation,
  useCreateOnboardingFormMutation,
  useGetOnboardingFormQuery,
  // App invitation
  useGetAppInvitationQuery,
  useUpdateAppInvitationMutation,
  // Organisation invitations
  useCurrentUserOrganisationMemberInvitationUpdateMutation,
  useTransferOwnershipMutation,
  // Address lookup
  useLazyGetOrganisationAddressQuery,
} = organisationApi;
