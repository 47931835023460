import BJsPJsHeader from '@/public/images/origin/case_study/casestudy_header_BJsPJs.png';
import FigtreephysioHeader from '@/public/images/origin/case_study/casestudy_header_figtreephysio.png';
// import PocketHeader from '@/public/images/origin/case_study/casestudy_header_pocketbar.png';
import ReyWhiteHeader from '@/public/images/origin/case_study/casestudy_header_raywhite.png';
import SilyHeader from '@/public/images/origin/case_study/casestudy_header_sily.png';
import StitchHeader from '@/public/images/origin/case_study/casestudy_header_stitchbar.png';
import BJsPJsBackground from '@/public/images/origin/case_study/caseStudy_image_BJsPJs.png';
import FigtreephysioImage from '@/public/images/origin/case_study/casestudy_image_figtreephysio.png';
// import PocketBackground from '@/public/images/origin/case_study/casestudy_image_pocketbar.png';
import RayWhiteImage from '@/public/images/origin/case_study/casestudy_image_raywhite.png';
import SilyBackground from '@/public/images/origin/case_study/casestudy_image_sily.png';
import StitchBackground from '@/public/images/origin/case_study/casestudy_image_stitchbar.png';
import BJsPJsLogo from '@/public/images/origin/case_study/caseStudy_logo_BJsPJs.png';
import FigtreephysioLogo from '@/public/images/origin/case_study/casestudy_logo_figtreephysio.png';
// import PocketLogo from '@/public/images/origin/case_study/casestudy_logo_pocketbar.png';
import VillageChefHeader from '@/public/images/origin/case_study/casestudy_header_thevillagechef.png';
import VillageChefBackground from '@/public/images/origin/case_study/casestudy_image_thevillagechef.png';
import ReyWhiteLogo from '@/public/images/origin/case_study/casestudy_logo_raywhite.png';
import SilyLogo from '@/public/images/origin/case_study/casestudy_logo_sily.png';
import StitchLogo from '@/public/images/origin/case_study/casestudy_logo_stitchbar.png';
import VillageChefLogo from '@/public/images/origin/case_study/casestudy_logo_thevillagechef.png';

import TheBerryTeaShopHeader from '@/public/images/origin/case_study/casestudy_header_theberryteashop.png';
import TheBerryTeaShopBackground from '@/public/images/origin/case_study/casestudy_image_theberryteashop.png';
import TheBerryTeaShopLogo from '@/public/images/origin/case_study/casestudy_logo_theberryteashop.png';

import { StaticImageData } from 'next/legacy/image';

export interface CaseOfStudyItem {
  id: string | number;
  headerImage: StaticImageData;
  backgroundImage: StaticImageData;
  logoImage: StaticImageData;
  name: string;
  location?: string;
  description: string;
  quote?: string;
  quoteAuthor?: string;
  disclaimer: string;
  annualSaving: string;
  carbonFootprintPercentageReduction: number;
  carbonFootprintTonnesReduction: number;
}

export const COMMON_DISCLAIMER = `Comparative costs calculated using business’ annual electricity consumption and cost with previous energy provider, and estimated cost for same electricity consumption with new energy provider.<br/><br/>Estimated emissions saving calculated using annual energy consumption provided by business, and emissions intensity of 0.68 kilograms CO2e/kWh for electricity generated from fossil fuels based on values from <a className="underline" href="https://www.dcceew.gov.au/sites/default/files/documents/national-greenhouse-accounts-factors-2022.pdf" target="_blank">Department of Climate Change, Energy, the Environment and Water.</a><br/><br/>Estimated equivalent number of cars off the road per year assumes annual car travel of 15,000 kilometres and an emissions intensity of 146.5 grams/kilometre based on data from National Transport Commission.`;
export const CarouselItems: CaseOfStudyItem[] = [
  {
    id: 1,
    headerImage: FigtreephysioHeader,
    backgroundImage: FigtreephysioImage,
    logoImage: FigtreephysioLogo,
    name: 'Figtree Physio',
    location: 'Wollongong',
    description:
      'Figtree Physio co-owner Llew Scott was looking at paying over $5,300 on electricity in the coming year with his previous energy provider.<br/><br/>But he now has less reason to make his money stretch. Because signing up to Greener for Business unlocked an exclusive energy offer, and Llew is now estimated to pay less than $4,800 – a saving of more than $500.<br/><br/>Llew is also supporting renewable energy, reducing his business’ carbon footprint by 9 tonnes a year and taking the equivalent of 4 cars off the road.',
    quote: `“Our business had started to think about ways we could save money on electricity, including looking at solar options, which we weren’t ready for at this stage. So switching to GreenPower made sense to save significantly on our energy bills and to do our bit for the environment and climate change. <br/><br/>“In the end it was really easy to do with no catches. It makes good business sense and we feel good that we can do our little bit.”`,
    disclaimer: COMMON_DISCLAIMER,
    quoteAuthor: 'Llew Scott, Co-Owner',
    annualSaving: '545',
    carbonFootprintPercentageReduction: 12,
    carbonFootprintTonnesReduction: 9,
  },
  {
    id: 2,
    headerImage: ReyWhiteHeader,
    backgroundImage: RayWhiteImage,
    logoImage: ReyWhiteLogo,
    name: 'Ray White',
    location: 'Clayfield',
    description:
      'As Principal at Ray White Clayfield, Nicholas Given expected to pay his previous energy provider more than $4,400 on electricity bills over the next year.<br/><br/>Thanks to an exclusive energy offer available to Greener for Business members, Nicholas is now estimated to pay around $4,000, saving his team $400.<br/><br/>And by supporting renewable energy, Ray White Clayfield has lowered its carbon footprint by the equivalent of taking 3 cars off the road – that’s 7 tonnes of carbon dioxide going, going, gone!',
    quote: `“It’s great to know we’re doing the right thing by the environment and we’re saving money too, so for us it was a no-brainer. <br/><br/>“The whole signup process was so quick and easy for us. And electricity isn't something we really think about day-to-day, so all we’ve done is agree to something that is a really great concept that saves us money and is better for the environment… It’s nice to be a pioneer of it all.”`,
    quoteAuthor: 'Nicholas Given, Principal',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '388',
    carbonFootprintPercentageReduction: 12,
    carbonFootprintTonnesReduction: 7,
  },
  {
    id: 3,
    headerImage: SilyHeader,
    backgroundImage: SilyBackground,
    logoImage: SilyLogo,
    name: 'Since I Left You',
    location: 'Sydney',
    description:
      'Nick White, the owner of Sydney bar Since I Left You, was looking at paying almost $9,000 a year on electricity with his previous energy provider.<br/><br/>After signing up to Greener for Business, Nick got access to an exclusive energy offer and is now estimated to pay $5,800, slashing costs by almost $3,200.<br/><br/>Plus, Nick is supporting renewable energy, which lowers Since I Left You’s carbon footprint by 12 tonnes – the same impact as taking 5 cars off the road. That deserves a toast!',
    quote: `"As a business owner, I increasingly find sustainability is a factor in my decision making, but decisions have to also make financial sense. <br/><br/>“It’s a common misnomer that being sustainable comes at the expense of your bottom line, and switching to GreenPower with Greener is all the proof you need to debunk that. Doing better by the earth, while saving my business a considerable amount off my energy bills? That's a no brainer."`,
    quoteAuthor: 'Nick White, Owner',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '3,164',
    carbonFootprintPercentageReduction: 14,
    carbonFootprintTonnesReduction: 12,
  },
  {
    id: 4,
    headerImage: StitchHeader,
    backgroundImage: StitchBackground,
    logoImage: StitchLogo,
    name: 'Stitch Bar',
    location: 'Sydney',
    description:
      'With his previous energy provider, Stitch Bar owner Karl Schlothauer was looking at paying over $18,800 for electricity over the next 12 months.<br/><br/>By signing up to Greener for Business and unlocking an exclusive energy offer, Karl is now estimated to pay around $16,500 – a saving of more than $2,300.<br/><br/>Plus, Karl is supporting renewable energy, reducing his business’ carbon footprint by 34 tonnes – the equivalent of taking 15 cars off the road. The venue might be a speakeasy, but we’d say that’s worth shouting about.',
    quote: `“Doing business in today’s environment has never been more challenging. Rising cost of doing business, staff retention and getting people off the couch to go out are just some of the challenges.<br/><br/>“Making this switch I feel helps address some of those issues. Not only am I saving a considerable amount of money per year whilst enjoying price certainty, it also sends a message to our staff and guests that we give a $h!t about the impact we are having on the environment.”`,
    quoteAuthor: 'Karl Schlothauer, Owner',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '2,322',
    carbonFootprintPercentageReduction: 14,
    carbonFootprintTonnesReduction: 34,
  },
  // {
  //   id: 5,
  //   headerImage: PocketHeader,
  //   backgroundImage: PocketBackground,
  //   logoImage: PocketLogo,
  //   name: 'Pocket Bar',
  //   location: 'Terrigal',
  //   description:
  //     'Following recent rate rises from its previous energy provider, Pocket Bar was likely to pay over $19,500 annually on its power bills.<br/><br/>After signing up to Greener for Business and getting access to an exclusive energy offer, Pocket Bar is now estimated to pay around $18,600, saving over $900.<br/><br/>And because Pocket Bar is supporting renewable energy, the business is reducing its carbon footprint by 38 tonnes – that’s like taking 17 cars off the road.',
  //   disclaimer: COMMON_DISCLAIMER,
  //   annualSaving: '963',
  //   carbonFootprint: 14,
  // },
  {
    id: 6,
    headerImage: BJsPJsHeader,
    backgroundImage: BJsPJsBackground,
    logoImage: BJsPJsLogo,
    name: 'BJ’s PJ’s',
    description: `Belinda Jane Keehn, founder of lounge and sleepwear brand BJ’s PJ’s, was anticipating paying more than $2,500 on her home office’s electricity over the next 12 months if she stayed with her previous energy provider.<br/><br/>Having signed up for Greener for Business to get an exclusive energy offer, Belinda is now estimated to pay $1,800, saving her $700 – a great reason to rest easy.<br/><br/>Plus, she has one less thing to lose sleep over. Because by supporting renewable energy, Belinda is reducing BJ’s PJ’s carbon footprint by 5 tonnes and taking the equivalent of 2 cars off the road.`,
    quote: `“We are all struggling at the moment and as a small business it’s super tough to keep things going. So the fact that Greener for Business is actually helping me to save time and money while also helping the planet, and making it quick and easy is invaluable. <br/><br/>“It makes me feel really good that I'm doing the right thing to give the best experience to my customers and stick to our brand promise of making more sustainable choices, and I'm saving my business money at the same time. Work smarter, not harder."`,
    quoteAuthor: 'Belinda J Keehn, Founder',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '700',
    carbonFootprintPercentageReduction: 12,
    carbonFootprintTonnesReduction: 5,
  },
  {
    id: 7,
    headerImage: VillageChefHeader,
    backgroundImage: VillageChefBackground,
    logoImage: VillageChefLogo,
    name: 'The Village Chef',
    location: 'North Shore',
    description: `Operating three Meals on Wheels charity kitchens across Sydney’s North Shore, The Village Chef owner Alex Kane anticipated paying his previous energy provider over $73,000 in power bills over the coming 12 months.<br/><br/>By taking advantage of an exclusive energy offer for Greener for Business members, Alex is now estimated to pay around $57,000, saving almost $16,000 across his three sites. Imagine how many more meals could be made for the community with those savings?<br/><br/>Alex is also supporting renewable energy, lowering his business’ carbon footprint by almost 129 tonnes. So not only are the team taking delicious dishes on the road for those in need, they’re also taking the equivalent of 59 cars off the road.`,
    quote: `“When we first looked into switching to renewable energy, we thought it would be difficult and possibly not worth the effort. That’s when our local council’s sustainability program Better Business Partnership pointed us in the direction of Greener for Business.<br/><br/>“We were absolutely blown away by how easy it was to make the switch and how much money we will save. All while doing our bit for the planet. We are absolutely thrilled.”`,
    quoteAuthor: 'Alex Kane, Owner',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '15,800',
    carbonFootprintPercentageReduction: 14,
    carbonFootprintTonnesReduction: 129,
  },
  {
    id: 8,
    headerImage: TheBerryTeaShopHeader,
    backgroundImage: TheBerryTeaShopBackground,
    logoImage: TheBerryTeaShopLogo,
    name: 'The Berry Tea Shop',
    location: 'Berry',
    description: `At The Berry Tea Shop on New South Wales’ south coast, owners Paulina and Cliff Collier expected to pay almost $6,700 on electricity over the next year with their previous energy provider.<br/><br/>They’re now estimated to pay less than $6,200, saving $537. And they didn’t have to read the tea leaves to do it – they just switched to an exclusive energy offer for Greener for Business members.<br/><br/>Better yet, they’re supporting renewable energy and reducing their carbon footprint by 11 tonnes, which is like taking 5 cars off the road. All the more reason to unwind with a cuppa!`,
    quote: `“Our teas come from all over the world – so of course we want to keep that world beautiful.<br/><br/>“We’re already doing what we can to reduce our packaging and waste. We recycle soft plastics, we use compostable and recyclable packaging materials, and we let customers refill their tea canisters without a bag. The next piece of the puzzle is energy.<br/><br/>“Switching to 100% GreenPower with Greener just makes sense. Knowing we’re supporting renewable energy projects around Australia is a great feeling. It makes each cuppa that little bit more relaxing! And the fact that we’re saving at the same time is the cherry on top.”`,
    quoteAuthor: 'Paulina and Cliff Collier, Owners',
    disclaimer: COMMON_DISCLAIMER,
    annualSaving: '537',
    carbonFootprintPercentageReduction: -1,
    carbonFootprintTonnesReduction: 11,
  },
];
