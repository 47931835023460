import { FieldError } from 'react-hook-form';

export const COMPONENT_NAME = 'Input';

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  invalid?: boolean;
  errors?: FieldError;
  children?: React.ReactNode;
  info?: string;
  placeholderIsLabel?: boolean;
  labelClassname?: string;
}
