export const COMPONENT_NAME = 'Header';

export interface IHeaderMenuItem {
  id: string;
  name: string;
  href?: string;
  break?: boolean;
  isLogout?: boolean;
  subItems?: IHeaderMenuItem[];
  badge?: number;
  hidden?: boolean;
  beta?: boolean;
}

export interface IHeader {
  // imageAlt?: string;
  // menuItems?: IHeaderMenuItem[];
  // navItems: IHeaderMenuItem[];
}
