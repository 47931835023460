import { IIcon } from './types';

function Emissions({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M38.5 37.8125V23.6697H27.5"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.35882 37.8125H41.6445"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 37.8125V14.2411H16.5"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 4.8125H16.5V37.8125H5.5V4.8125Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Emissions.displayName = 'Emissions';

export default Emissions;
