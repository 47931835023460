import { OrganisationMemberRole } from './types';

import OrgLogo from '../../public/images/OrganisationLogoDefault.svg';

export const OrganisationLogoDefault = OrgLogo;

export const OrganisationLogoUploadEnabled =
  process.env.NEXT_PUBLIC_ORGANISATION_LOGO_UPLOAD_ENABLED == 'true';

export function formatOrganisationMemberRole(role: OrganisationMemberRole) {
  switch (role) {
    case 'admin':
      return 'Administrator';
    case 'owner':
      return 'Owner';
    case 'user':
      return 'Member';
  }
}

// Returns an organisation specific path
// e.g. if organisationId = 1, and path is /settings
// it will return /organisations/1/settings
// if organisationId is undefined, and path is settings
// it will return /organisation/settings
// If fallback is provided, its value will be used instead.
export function organisationPath(
  organisationId: string | undefined,
  path: string,
  fallback?: string,
) {
  if (typeof organisationId === 'string') {
    return `/organisations/${organisationId}${path}`;
  }
  if (fallback) {
    return fallback;
  }
  return `/organisation${path}`;
}
