import { ICard } from '@/components/Card';
import { StaticRequire } from 'next/dist/shared/lib/get-img-props';
import { ImageAsset } from '../api/types';
import { Badge, BadgeGroup, BadgeWithEarnedValue } from '../badges/types';
import { GreenerCategoryBase } from '../categories/types';
import { Industry } from '../organisations/types';

export interface IAction extends ICard {
  effort?: ActionEffort;
  progress_status?: ActionProgress;
}

export interface IFilteredPathwayProps<T extends GreenerCategoryBase> {
  heading?: string;
  description?: string;
  emptyHeading?: string;
  items: IAction[];
  categories: T[];
  selectedCategory?: T;
  mode: 'home' | 'actions';
  orderBy: SustainabilityActionSortOrder;
  setOrderBy: (orderBy: SustainabilityActionSortOrder) => void;
  onCategorySelected(item: T): void;
  onActionHover?: (slug: string) => void;
}

export interface ListActionsForOrganisationParams {
  organisationId: string;
  progressStatus?: ActionProgress;
  progressStatuses?: ActionProgress[];
  hidden?: boolean;
  badgeGroup?: BadgeGroup;
  badgeSlugs?: string[];
  categorySlugs?: string[];
  effort?: ActionEffort;
  sequence?: ActionSequence;
  orderBy?: 'convenience' | 'planet' | 'savings';
  industry?: Industry;
}

export type HideActionReason = 'unable' | 'not_relevant' | 'other';

export interface UpdateActionProgressParams {
  organisationId: string;
  actionIdOrSlug: string;
  method: 'hide' | 'complete' | 'in_progress' | 'uncomplete' | 'read';
  hide?: {
    hide_reason?: HideActionReason;
    hide_comment?: string;
    is_hidden?: boolean;
  };
}
export interface UpdateActionProgressChecklistParams {
  organisationId: string;
  actionIdOrSlug: string;
  checklist: {
    [key: string]: boolean;
  };
}

export type ActionImpact = 'low' | 'medium' | 'high';
export type ActionEffort = 'easy' | 'moderate' | 'high';
export type TimeInvestment =
  | 'less_than_5_minutes'
  | 'less_than_1_hour'
  | 'less_than_one_day'
  | 'one_day'
  | 'one_week'
  | 'one_month'
  | 'three_months'
  | 'six_months_or_more';

export function actionEffortDescription(effort?: ActionEffort) {
  switch (effort) {
    case 'easy':
      return 'Easy';
    case 'moderate':
      return 'Moderate effort';
    case 'high':
      return 'Higher effort';
    default:
      return undefined;
  }
}

export function actionEffortShortDescription(effort?: ActionEffort) {
  switch (effort) {
    case 'easy':
      return 'Easy';
    case 'moderate':
      return 'Moderate';
    case 'high':
      return 'Higher';
    default:
      return undefined;
  }
}

export function actionTimeInvestmentDescription(time?: TimeInvestment) {
  switch (time) {
    case 'less_than_1_hour':
      return '< 1 hour';
    case 'less_than_5_minutes':
      return '< 5 minutes';
    case 'less_than_one_day':
      return '< 1 day';
    case 'one_day':
      return '1 day';
    case 'one_week':
      return '1 week';
    case 'one_month':
      return '1 month';
    case 'three_months':
      return '3 months';
    case 'six_months_or_more':
      return '6+ months';
    default:
      return undefined;
  }
}

export type ActionSequence = 'early' | 'next' | 'later' | 'advanced';

export type ActionProgress =
  | 'incomplete'
  | 'in_progress'
  | 'completed'
  | 'hide';

export enum ActionProgressText {
  incomplete = 'To do',
  in_progress = 'In progress',
  completed = 'Completed',
  hide = 'Not relevant',
}

export interface SustainabilityActionTask {
  id: string;
  confirmation_title: string;
  confirmation_message: string;
}

export interface ActionBenefit {
  id: string;
  benefit: string;
  description: string;
  icon: ImageAsset;
}

export interface ActionCategory {
  id: string;
  parent_id: string;
  slug: string;
  title: string;
}
export interface SustainabilityAction {
  id: string;
  slug: string;
  parent_id: string | null;
  parent_slug: string | null;
  title: string;
  short_description: string | null;
  promo_text?: string;
  description: string | null;
  hero_images: ImageAsset[];
  badge_points: number;
  sequence: ActionSequence;
  impact: ActionImpact;
  effort: ActionEffort;
  time_investment: TimeInvestment;
  savings: string | null;
  annualized_emission_savings: number | null;
  savings_info: string | null;
  average_cost_savings_per_year: number | null;
  average_emission_savings_per_year: number | null;
  payback_period: string | null;
  payback_period_info: string | null;
  emissions_reduction_percentage: number | null;
  emissions_reduction_percentage_info: string | null;
  progress_status: ActionProgress;
  is_hidden?: boolean;
  badges: Badge[];
  tasks: SustainabilityActionTask[];
  benefits?: ActionBenefit[];
  statistics?: SustainabilityActionStatistics | null;
  onboarding_title?: string;
  onboarding_subtitle?: string;
  icon?: ImageAsset;
  categories: ActionCategory[];
  checklist: ChecklistItem[];
  is_read: boolean;
  // Xero updates
  annualized_cost_savings: number | null;
  annualized_emission_reduction: number;
}

export type SustainabilityActionCompletedOrganisation = {
  id: string;
  name: string;
  logo_image_url: string;
  location?: string | null;
};

export type SustainabilityActionStatistics = {
  completed_count: number;
  organisation_logos: SustainabilityActionCompletedOrganisation[] | null;
};

export interface SustainabilityActionContent {
  featured_case_studies: CaseStudy[];
  content: ActionContentBlock[];
}

export interface OrganisationSustainabilityAction extends SustainabilityAction {
  badges: BadgeWithEarnedValue[];
  user_vote: boolean | null;
  user_vote_feedback?: string;
  is_hidden: boolean;
}

export interface OrganisationDynamicSavings {
  id: string;
  slug: string;
  annualized_cost_savings: number;
  annualized_emission_reduction: number;
  average_cost_savings_per_year: number;
  average_emission_savings_per_year: number;
  emissions_reduction_percentage: number;
}

interface ChecklistItem {
  heading: string;
  slug: string;
  sort_order: number;
  description?: string;
}

export interface OrganisationSustainabilityActionCompletetionResult {
  sustainability_action: OrganisationSustainabilityAction;
  completed_badges: Badge[];
}

export interface OrganisationSustainabilityActionProgressChecklistResult {
  checklist: {
    [key: string]: boolean;
  };
}

export interface ActionFeedbackBody {
  feedback: {
    user_vote?: boolean | null;
    user_vote_feedback?: string | null;
    user_vote_reason?: string | null;
    is_hidden?: boolean | null;
  };
}

// Action content //
export interface ActionContentBlockComponentProps<
  T extends ActionContentBlock,
> {
  action?: OrganisationSustainabilityAction | SustainabilityAction;
  block: T;
}

export type ActionContentBlockType =
  | 'section_heading'
  | 'html'
  | 'feature_box'
  | 'bar_chart'
  | 'callout'
  | 'key_stats'
  | 'key_benefits'
  | 'separator'
  | 'suppliers'
  | 'sustainability_actions'
  | 'accordion_content'
  | 'products'
  | 'custom_block'
  | 'case_studies_carousel';

export interface ActionContentBlock {
  id: string;
  type: ActionContentBlockType;
}

export interface ActionContentBlockSectionHeading extends ActionContentBlock {
  type: 'section_heading';
  heading?: string;
}

export interface ActionContentBlockHTML extends ActionContentBlock {
  type: 'html';
  html?: string;
}

export interface ActionContentBlockFeatureBox extends ActionContentBlock {
  type: 'feature_box';
  heading?: string;
  subtitle?: string;
  button_text?: string;
  button_url?: string;
  description?: string;
  feature_image?: ImageAsset[];
}

export interface ProductContentblock extends ActionContentBlock {
  type: 'products';
  heading?: string;
  description?: string;
  sort_order?: number;
  product_content_matrix: ProductContentMatrix[];
}

export interface ProductContentMatrix {
  category: string;
  product_items: {
    id: string;
    title: string;
    description: string;
    images: ImageAsset[];
    logo_image: ImageAsset[];
    buttonUrl: string;
    buttonText: string;
    product_badge_text: string;
    sort_order: number;
  }[];
}

// Bar Chart //

export interface BarChartData {
  color: 'gray' | 'green' | 'red' | 'yellow' | 'blue' | 'purple';
  bar_chart_label?: string;
  number?: number;
}
export interface ActionContentBlockBarChartBox extends ActionContentBlock {
  type: 'bar_chart';
  heading?: string;
  subtitle?: string;
  unit?: string;
  unit_position?: 'none' | 'prefix' | 'suffix';
  description?: string;
  data?: BarChartData[];
}

// Key Stats //
export interface DisclaimerInfoModal {
  id: string;
  slug: string;
}

export interface KeyStatsData {
  amount?: string;
  key_stats_label?: string;
  suffix?: string;
}

export interface ActionContentBlockKeyStatsBox extends ActionContentBlock {
  type: 'key_stats';
  disclaimer_info_modal?: DisclaimerInfoModal[];
  disclaimer_text?: string;
  html?: string | null;
  icon?: ImageAsset[];
  data?: KeyStatsData[];
}

export interface ActionContentBlockCallOutBox extends ActionContentBlock {
  type: 'callout';
  html?: string;
}

export interface ActionKeyBenefitsBlock extends ActionContentBlock {
  type: 'key_benefits';
  heading?: string;
}
export interface ActionContentBlockSuppliers extends ActionContentBlock {
  type: 'suppliers';
  heading?: string;
  supplier_tags?: { title: string }[] | null;
  display_mode?: 'list' | 'card';
  supplier_variation_type?: string | null;
}

export interface SupplierContentBlockCustom extends ActionContentBlock {
  type: 'suppliers';
  block_type: string;
}

export interface AccordionContentBlock extends ActionContentBlock {
  type: 'accordion_content';
  accordion_content_matrix: {
    id: string;
    heading: string;
    description: string;
    sort_order: number;
  }[];
}

export interface ActionContentSustainabilityActions extends ActionContentBlock {
  type: 'sustainability_actions';
  heading?: string;
}

export type SustainabilityActionSortOrder =
  | 'savings'
  | 'planet'
  | 'convenience';

export interface SustainabilityCategory extends GreenerCategoryBase {
  sustainability_action_sort_order?: SustainabilityActionSortOrder;
}

export interface CaseStudy {
  id: string;
  title: string | null;
  location: string | null;
  annual_saving: string | null;
  footprint_reduction: string | null;
  description: string | null;
  short_description: string | null;
  html: string | null;
  logo_image: ImageAsset[] | null;
  hero_images: ImageAsset[] | null;
  hero_images_wide: ImageAsset[] | null;
  video_embed_url: string;
  disclaimer_info: { slug: string }[] | null;
}

export interface ActionContentBlockCaseStudiesCarousel
  extends ActionContentBlock {
  type: 'case_studies_carousel';
  case_studies: CaseStudy[];
}

export interface CarbonProgressBase {
  // The annualized carbon footprint of the user
  annualized_carbon: number;
  // The annualized amount of carbon offset by greener
  annualized_offset: number;
  // The annualized amount of recent carbon offsets
  annualized_recent_offset_change: number;

  // The change in offset from the last update date
  recent_offset_change: number;
  // The number of offset transactions since the last update date
  recent_offset_transaction_count: number;

  // The total # of transactions since the last update date
  recent_transaction_count: number;

  // New stuff. Not sure if to delete the old.
}

// Some extra fields we calculate on the client
export interface CarbonProgress extends CarbonProgressBase {
  carbon_footprint: number;

  // The total carbon progress, including offsets
  carbon_total_progress: number;
  // The total carbon progress, excluding offsets
  carbon_before_offset_progress: number;
  // The total carbon progress, including offsets, minus the recent offsets
  carbon_total_without_recent_progress: number;

  recent_no_offset_transasction_count: number;

  collective_message: {
    title: string;
    body: string;
    invite_button: string;
    invite_text: string;
    invite_url: string;
  };
}

export interface Milestone {
  slug: string;
  value: number;
  description: string;
  medalIcon: StaticRequire;
  medalIconSmall: StaticRequire;
}
