// https://bitbucket.org/growtham/gam-user-analytics-v2/src/main/

import pick from 'lodash/pick';
import GAMScript from './components/GAMScript';

// Typescript definition for the GAM object
// This is by no means a complete definition but it was all i needed for now
// use `window.gam` in the browser if you want to see what selse is there
export interface GAM {
  utm_campaign_first?: string;
  utm_campaign_last?: string;
  utm_content_first?: string;
  utm_content_last?: string;
  utm_medium_first?: string;
  utm_medium_last?: string;
  utm_source_first?: string;
  utm_source_last?: string;
  utm_term_first?: string;
  utm_term_last?: string;

  config?: Object;
}

// Define a global for the gam object
declare global {
  const gam: GAM | undefined;
}

// Get the properties to add to segment events
export function getGamEventProperties() {
  if (typeof window === 'undefined') return {};
  const w = window as unknown as { gam: GAM };
  return pick(w.gam, [
    'utm_campaign_first',
    'utm_campaign_last',
    'utm_content_first',
    'utm_content_last',
    'utm_medium_first',
    'utm_medium_last',
    'utm_source_first',
    'utm_source_last',
    'utm_term_first',
    'utm_term_last',
    'greenermid_first',
    'greenermid_last',
  ]);
}

export function getGamRawProperties() {
  if (typeof window === 'undefined') return {};
  const w = window as unknown as { gam: GAM };
  return w.gam;
}

// Makes importing the global work...
export const defineGlobals = undefined;

export { GAMScript };
