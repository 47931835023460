import BronzeMedalBig from '@/public/icons/actions_progress_bar/medals/ic_bronze_medal_big.svg';
import GoldMedalBig from '@/public/icons/actions_progress_bar/medals/ic_gold_medal_big.svg';
import GreenMedalBig from '@/public/icons/actions_progress_bar/medals/ic_green_medal_big.svg';
import SilverMedalBig from '@/public/icons/actions_progress_bar/medals/ic_silver_medal_big.svg';

import BronzeMedal from '@/public/icons/actions_progress_bar/medals/ic_broze_medal.svg';
import GoldMedal from '@/public/icons/actions_progress_bar/medals/ic_gold_medal.svg';
import GreenMedal from '@/public/icons/actions_progress_bar/medals/ic_green_medal.svg';
import SilverMedal from '@/public/icons/actions_progress_bar/medals/ic_silver_medal.svg';
import { Milestone } from './types';

export const MILESTONES_ITEMS: Milestone[] = [
  {
    slug: 'getting-greener',
    value: 1,
    description: 'Getting greener',
    medalIcon: BronzeMedalBig,
    medalIconSmall: BronzeMedal,
  },
  {
    slug: 'ahead-of-the-pack',
    value: 3,
    description: 'Ahead of the pack',
    medalIcon: SilverMedalBig,
    medalIconSmall: SilverMedal,
  },
  {
    slug: 'climate-champion',
    value: 6,
    description: 'Climate champion',
    medalIcon: GoldMedalBig,
    medalIconSmall: GoldMedal,
  },
  {
    slug: 'supergreen',
    value: 9,
    description: 'Supergreen',
    medalIcon: GreenMedalBig,
    medalIconSmall: GreenMedal,
  },
];
