import { IIcon } from './types';

function SocialLinkedIn({ className = '', color = '#2B2B2B' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 25 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1055 19.6058H16.904V14.5922C16.904 13.3982 16.8836 11.8598 15.2396 11.8598C13.5716 11.8598 13.3184 13.163 13.3184 14.5082V19.6058H10.1168V9.2978H13.1876V10.7066H13.2319C13.6592 9.8966 14.7043 9.04219 16.2632 9.04219C19.5068 9.04219 20.1068 11.1758 20.1068 13.9526V19.6058H20.1055ZM6.50595 7.89019C6.26201 7.89043 6.02041 7.84262 5.79494 7.74948C5.56948 7.65635 5.36456 7.51972 5.1919 7.34739C5.01924 7.17506 4.88221 6.97042 4.78864 6.74513C4.69507 6.51985 4.64679 6.27834 4.64655 6.0344C4.64631 5.79045 4.69413 5.54885 4.78726 5.32339C4.8804 5.09792 5.01703 4.89301 5.18936 4.72035C5.36168 4.54768 5.56633 4.41066 5.79162 4.31709C6.0169 4.22351 6.25841 4.17523 6.50235 4.175C6.99502 4.17452 7.46769 4.36977 7.8164 4.7178C8.1651 5.06583 8.36127 5.53813 8.36175 6.03079C8.36223 6.52346 8.16698 6.99614 7.81895 7.34484C7.47092 7.69355 6.99862 7.88972 6.50595 7.89019ZM4.90155 19.6058H8.10795V9.2978H4.90035V19.6058H4.90155ZM21.7015 1.2002H3.29355C2.41395 1.2002 1.69995 1.8974 1.69995 2.7578V21.2414C1.69995 22.1018 2.41395 22.8002 3.29355 22.8002H21.7015C22.5823 22.8002 23.2999 22.1018 23.2999 21.2414V2.7578C23.2999 1.8974 22.5823 1.2002 21.7015 1.2002Z"
        fill={color}
      />
    </svg>
  );
}

SocialLinkedIn.displayName = 'SocialLinkedIn';

export default SocialLinkedIn;
