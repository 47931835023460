import { IIcon } from './types';

function Revenue({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M40 39H3V5"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 8L23.1818 25.8571L16.4545 18.7143L3 33"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 16V8H32"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Revenue.displayName = 'Revenue';

export default Revenue;
