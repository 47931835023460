import { IIcon } from './types';

function Listing({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M17 20C14.2438 20 12 17.7562 12 15C12 12.2438 14.2438 10 17 10C19.7562 10 22 12.2438 22 15C22 17.7562 19.7562 20 17 20Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M32.471 32.6083L31.9422 31.7414L31.0835 32.2833L28.4297 33.9581V20.7037L34.4975 24.0211L40.5585 27.3349L37.4891 29.0484L36.5817 29.555L37.1228 30.4422L40.13 35.373L35.4954 37.567L32.471 32.6083Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.6801 17.8366C29.8924 16.9348 30 15.9811 30 15C30 7.83172 24.1683 2 17 2C9.83172 2 4 7.83172 4 15C4 17.2892 4.60126 19.5327 5.73571 21.4898L5.88602 21.7427L17 41L25.1687 26.8615"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Listing.displayName = 'Listing';

export default Listing;
