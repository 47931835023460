import { IIcon } from './types';

function Customers({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M15.3008 16.8V6.8C15.3008 3.6 17.9008 1 21.1008 1C24.3008 1 27.0008 3.6 27.0008 6.8V16.8"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 39.2H7L9 11.7H33L33.5 25.4"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 16.8H16"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1992 16.8H27.6992"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9016 28.3999L30.6016 39.3999L33.7016 37.5999L36.3016 42.1999L40.1016 39.9999L37.4016 35.3999L40.5016 33.6999L30.9016 28.3999Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Customers.displayName = 'Customers';

export default Customers;
