import LoginOrSignUpBaseFormLayout from '@/containers/LoginOrSignUpBaseFormLayout/LoginOrSignUpBaseFormLayout';
import { segmentEventName, sendAnalyticsEvent } from '@/helpers/analytics';
import useLoginRedirectUrl from '@/lib/session/hooks/useLoginRedirectUrl';
import LoginOrSignUpImage from '@/public/images/session/img_login_or_signup.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useValidateEmailMutation } from 'lib/auth/api';
import { handleFormSubmitException } from 'lib/forms/helper/validation';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { Check } from 'react-feather';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { BUTTON_SIZE } from '../Button/Button';
import { FormInputError } from '../FormInputError';
import { Input } from '../Input';
import { COMPONENT_NAME } from './types';

const JoinChecklistItems = [
  'Get a simple step-by-step action plan',
  'Save money with exclusive member offers and expert tips',
  'Boost sales with the Greener app',
];
interface FormData {
  email: string;
}

const schema = Yup.object({
  email: Yup.string().email('Enter a valid email').required('Enter your email'),
});

function LoginOrSignUp({ isPopUp = false }: { isPopUp?: boolean }) {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const showFeatures = router.query.features !== 'false';

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [validateEmail, { isLoading }] = useValidateEmailMutation();

  const redirectUrl = useLoginRedirectUrl();

  const onSubmit = handleSubmit(async ({ email }) => {
    if (isLoading) return;

    try {
      setIsSubmitting(true);

      sendAnalyticsEvent({
        event: segmentEventName.emailEntered,
        properties: {
          email,
          g4b_lead: true,
        },
      });

      const result = await validateEmail({ email }).unwrap();

      const searchParams = new URLSearchParams({
        email: email,
        redirect: redirectUrl,
      });

      // What's shown to the browser, email hidden
      const displayParams = new URLSearchParams({
        redirect: redirectUrl,
      });

      if (result.magic) {
        // Magic link
        router.push(
          `/session/token?${searchParams.toString()}`,
          `/session/token?${displayParams.toString()}`,
        );
      } else {
        // Password login
        router.push(
          `/session/login?${searchParams.toString()}`,
          `/session/login?${displayParams.toString()}`,
        );
      }
    } catch (e) {
      setIsSubmitting(false);
      const error = e as FetchBaseQueryError | SerializedError;
      if ('data' in error && error.status === 422) {
        // User does not exists, lets move him to sign up.
        const searchParams = new URLSearchParams({
          email: email,
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        // What's shown to the browser, email hidden
        const displayParams = new URLSearchParams({
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        router.push(
          `/session/signup?${searchParams.toString()}`,
          `/session/signup?${displayParams.toString()}`,
        );
        return;
      }

      handleFormSubmitException(e, setError);
    }
  });

  return (
    <div>
      <Head>
        <title>Log in or Sign up</title>
      </Head>
      <LoginOrSignUpBaseFormLayout
        isPopUp={isPopUp}
        disabled={isLoading || isSubmitting}
        loading={isLoading || isSubmitting}
        buttonProps={{
          type: 'submit',
          title: 'Continue',
          fullWidth: true,
          size: BUTTON_SIZE.XLARGE,
        }}
        rightImage={LoginOrSignUpImage}
        onFormSubmit={onSubmit}
        underImageContent={
          <>
            {showFeatures ? (
              <div className="mt-10 flex max-w-sm flex-col items-start space-y-2 px-4">
                {JoinChecklistItems.map(x => (
                  <ChecklistItem key={x} item={x} />
                ))}
              </div>
            ) : null}
          </>
        }
      >
        <Input
          {...register('email')}
          errors={errors.email}
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          autoCapitalize="none"
        />

        <input type="hidden" autoComplete="current-password" disabled />
        {router.query.error ? (
          <FormInputError
            errors={{ type: 'auth', message: router.query.error as string }}
          />
        ) : null}
      </LoginOrSignUpBaseFormLayout>
    </div>
  );
}

function ChecklistItem({ item }: { item: string }) {
  return (
    <div className="flex items-start space-x-4">
      <Check className="shrink-0 text-green-500" />
      <p className="h6 mt-0.5">{item}</p>
    </div>
  );
}

LoginOrSignUp.displayName = COMPONENT_NAME;

export default LoginOrSignUp;
