import classNames from 'classnames';
import React, { useState } from 'react';
import { FormInputError } from '../FormInputError';
import { COMPONENT_NAME, IInput } from './types';

const Input = React.forwardRef<HTMLInputElement, IInput>((props, ref) => {
  const {
    id,
    label,
    className = '',
    labelClassname,
    children,
    invalid,
    errors,
    info,
    type,
    placeholder,
    placeholderIsLabel = true,
    ...inputProps
  } = props;

  const isPassword = type === 'password';
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const showPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const hasErrors = invalid ?? errors;
  return (
    <div>
      {!placeholderIsLabel ? (
        <label
          className={classNames('h5 mb-3 block', labelClassname, {
            'text-red-800': hasErrors,
          })}
          htmlFor={inputProps.name}
        >
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'relative flex rounded-0.5xl',
          'border focus-within:outline-none focus-within:ring-1',
          hasErrors
            ? 'border-red-800 bg-red-50 placeholder-red-800 focus-within:border-red-800 focus-within:ring-red-800'
            : 'border-gray-400 bg-white focus-within:border-gray-900 focus-within:ring-gray-900',
        )}
      >
        <input
          ref={ref}
          className={classNames(
            'peer block w-full flex-1 appearance-none rounded-0.5xl border-transparent px-3 pb-2 text-base leading-5 text-gray-900 placeholder-gray-700 outline-none transition-all placeholder-shown:pb-4 placeholder-shown:pt-4 focus:border-transparent focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200',
            { 'pt-6': placeholder && placeholderIsLabel },
            { 'pt-2': !placeholder && placeholderIsLabel },
            { 'pb-4 pt-4': !placeholderIsLabel },
          )}
          placeholder={placeholder}
          type={isPassword ? (passwordShown ? 'text' : 'password') : type}
          {...inputProps}
        />
        {placeholderIsLabel ? (
          <label
            className={classNames(
              'p6 absolute left-3 top-2 leading-4 opacity-100 transition-all peer-placeholder-shown:pointer-events-none peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:leading-5 peer-placeholder-shown:opacity-0',
              hasErrors ? 'font-sans-semibold text-red-800' : 'text-gray-700',
            )}
          >
            {placeholder}
          </label>
        ) : null}
        {isPassword && (
          <button
            className="flex appearance-none items-center pr-3"
            type="button"
            onClick={showPassword}
          >
            <span className="px-2 font-sans-semibold text-sm text-gray-900 underline">
              {passwordShown ? 'Hide' : 'Show'}
            </span>
          </button>
        )}
      </div>
      <FormInputError errors={errors} info={info} />
    </div>
  );
});

Input.displayName = COMPONENT_NAME;

export default Input;
