import Script from 'next/script';

export default function GAMScript() {
  return (
    <Script
      strategy="afterInteractive"
      src="https://scripts.attriva.com/gam.js"
      onLoad={() => {
        if (!gam) return;
        // Get the subdomain for the site
        const topDomain = window.location.hostname.slice(
          window.location.hostname.indexOf('.'),
        );

        gam.config = {
          debug: false, //process.env.NODE_ENV === 'development',
          cookieExpiry: 365, // sets the cookie expiry in days
          topDomain,
          urlParams: [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term',
            'greenermid',
          ],

          urlDecorator: {
            decorateOnOwnDomain: false,

            // use only host names
            urlsToDecorate: [],

            // variables you want to pass from the current site url to the decorated urls
            queryParams: ['gclid', 'fbclid', 'msclkid'],
          },
        };
      }}
    />
  );
}
