import { Button } from '@/components/Button';
import CaseOfStudiesCarousel from '@/components/CaseOfStudiesCarousel/CaseOfStudiesCarousel';
import { DEFAULT_HEADER_IMAGE_HREF } from '@/components/Header/Header';
import ThirdPartyLoginButtons from '@/components/ThirdPartyLoginButtons/ThirdPartyLoginButtons';
import usePageHeight from '@/hooks/usePageHeight';
import PrivacyPolicy from '@/lib/session/components/PrivacyPolicy';
import Logo from '@/public/logo.svg';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { ILoginOrSignUpBaseFormLayout } from './types';

export default function LoginOrSignUpBaseFormLayout({
  isPopUp = false,
  rightImage,
  disabled,
  loading,
  children,
  buttonProps,
  underImageContent,
  onFormSubmit,
}: ILoginOrSignUpBaseFormLayout) {
  const pageHeight = usePageHeight();

  if (isPopUp) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div
          className={
            'flex h-full w-full px-4 py-2 md:items-center md:justify-center md:px-10 md:py-10 lg:px-20 lg:py-20'
          }
        >
          <form onSubmit={onFormSubmit}>
            <div className="space-y-5">
              <div className="space-y-2.5">
                <h3>Welcome to Greener</h3>
                <p className="p3">
                  Enter your email to create a free account or log in.
                </p>
              </div>
              {children}
              {buttonProps && (
                <Button
                  disabled={disabled}
                  loading={loading}
                  {...buttonProps}
                />
              )}
              <ThirdPartyLoginButtons />
              <PrivacyPolicy />
            </div>
          </form>
        </div>

        <div className="hidden h-full w-full flex-col items-center justify-center overflow-hidden bg-pageGradient2 md:flex">
          <Image
            aria-hidden
            alt=""
            src={rightImage}
            width={495}
            height={415}
            layout="fixed"
            objectFit="contain"
          />
          {underImageContent}
        </div>
      </div>
    );
  }

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2"
      style={{ minHeight: pageHeight }}
    >
      <div
        className={
          'flex h-full w-full px-4 py-2 md:items-center md:justify-center'
        }
      >
        <div className="absolute top-0 flex h-16 w-[50%] items-center justify-start px-20 md:h-18">
          <Link href={DEFAULT_HEADER_IMAGE_HREF} passHref>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}

            <div className="hidden md:block">
              <Image
                layout="fixed"
                src={Logo}
                alt="Greener for Business"
                width={223}
                height={20}
              />
            </div>
          </Link>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="space-y-8 md:max-w-[30rem]">
            <div className="space-y-2.5">
              <h3>Welcome to Greener</h3>
              <p className="p3">
                Enter your email to create a free account or log in.
              </p>
            </div>
            <div className="space-y-4">
              {children}
              {buttonProps && (
                <Button
                  disabled={disabled}
                  loading={loading}
                  {...buttonProps}
                />
              )}
            </div>
            <ThirdPartyLoginButtons />
            <PrivacyPolicy />
          </div>
        </form>
      </div>

      <CaseOfStudiesCarousel />
    </div>
  );
}
