// mainSlice.js
import { createSlice } from '@reduxjs/toolkit';

const dynamicSavingsSlice = createSlice({
  name: 'dynamicSavings',
  initialState: {
    loading: false,
    actionLoading: {} as { [actionId: string]: boolean },
  },
  reducers: {
    setLoading(state, { payload: loading }) {
      state.loading = loading;
    },
    setActionLoading(state, { payload: { actionId, loading } }) {
      state.actionLoading[actionId] = loading;
    },
  },
});

export const { setActionLoading, setLoading } = dynamicSavingsSlice.actions;
export default dynamicSavingsSlice;
