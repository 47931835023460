import { Buffer } from 'buffer';
import EventEmitter from 'events';
import { Channel, Socket } from 'phoenix';

const LOG_PHOENIX_TOPIC = false;
const LOG_PHOENIX_EVENT = false;

function getUserIdFromToken(token: string) {
  try {
    // Decode the token to get the user ID
    // so we know which channel to join
    const jwtString = Buffer.from(token.split('.')?.[1], 'base64').toString(
      'ascii',
    );
    const jwt = JSON.parse(jwtString);
    const userId = jwt.sub.split(':')[1];
    return userId;
  } catch (e) {
    console.error('Error parsing JWT', e);
    return undefined;
  }
}

// Exponential back off:
// 0 = 1000ms,
// 1 = 2000ms
// 3 = 4000ms
// ... up to 30,000ms max
function getDelayForRetryCount(retryCount: number) {
  return Math.min(30000, 2 ** retryCount * 1000);
}

class SocketManager extends EventEmitter {
  static shared = new SocketManager();

  // The websocket
  private _socket?: Socket;

  // The user channel e.g. `user:USER_ID`
  private _userChannel?: Channel;

  // Are we connected or not?
  private _connected = false;

  // The User ID
  private _accessToken?: string;

  // Retry count when disconnected
  private _reconnectRetryCount = 0;

  // Timer for reconnection attempts
  private _reconnectTimer?: number = undefined;

  connect = (accessToken: string, user_id: string) => {
    this._accessToken = accessToken;

    // If we hvae a socket, we can just return after recording the new access token
    // if we lose connection, the onError will sort out the new token...
    if (this._socket) {
      console.debug('Socket updated access token');
      return;
    }

    // Create socket
    console.debug('Creating new socket');
    this._socket = this.createSocket();
    this._socket.connect();

    // Join the users channel
    const userChannelId = `user:${user_id}`;
    this._userChannel = this._socket.channel(userChannelId, {});
    this._userChannel.on('msg', msg => {
      console.debug('User channel message received', msg);
    });

    this._userChannel.join();
  };

  doConnect() {
    this._socket?.connect();
  }

  scheduleReconnect() {
    const timeout = getDelayForRetryCount(this._reconnectRetryCount);

    this._reconnectRetryCount++;
    console.log(
      `Scheduling reconnect attempt ${this._reconnectRetryCount} in ${timeout}ms`,
    );

    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);
      this._reconnectTimer = undefined;
    }

    this._reconnectTimer = setTimeout(
      () => this.doConnect(),
      timeout,
    ) as unknown as number;
  }

  disconnect = () => {
    this._socket?.disconnect();
    this._socket = undefined;
    this._userChannel = undefined;
    this._accessToken = undefined;
  };

  joinChannel = (channelName: string, params?: object) => {
    return this._socket?.channel(channelName, params);
  };

  private getParams = () => {
    return {
      access_token: this._accessToken,
    };
  };

  connected = () => this._connected;

  // Add and remove channel listeners

  addChannelListener = <T = object>(message: string, cb: (cb: T) => void) => {
    return this._userChannel?.on(message, cb);
  };

  removeChannelListener = (message: string, listenerRef: number) => {
    this._userChannel?.off(message, listenerRef);
  };

  private createSocket = () => {
    const socket = new Socket(
      process.env.NEXT_PUBLIC_SOCKET_URL ||
        'ws://api.local.greenr.global:4000/socket/greener_web',
      {
        params: this.getParams,
      },
    );

    socket.onOpen(() => {
      console.log('Socket opened');
      this._connected = true;
      this._reconnectRetryCount = 0;
      if (this._reconnectTimer) {
        clearTimeout(this._reconnectTimer);
        this._reconnectTimer = undefined;
      }
      this.emit('SocketConnectionStateChange', true);
    });

    socket.onClose(close => {
      console.log('Socket closed', close);
      this._connected = false;
      this._socket?.disconnect();

      this.emit('SocketConnectionStateChange', false);
      this.scheduleReconnect();
    });

    socket.onMessage((msg: any) => {
      if (msg.event.startsWith('phx_' && !LOG_PHOENIX_EVENT)) {
        // Not logging pheonix events
      } else if (msg.topic === 'phoenix' && !LOG_PHOENIX_TOPIC) {
        // Not logging phoenix topic
      } else {
        console.log('Socket message received', msg);
      }
    });
    // Disable the automatic error retry, so we can call connect again ourselves
    // this way the connection will get the updated token
    // https://github.com/phoenixframework/phoenix/issues/3515#issuecomment-628192821
    socket.onError((e: any) => {
      console.log('Socket error', e);
      this._socket?.disconnect(); // cancel auto connection recovery

      // Stops reconnecting if the socket isn't on this endpoint
      if (e.message === 'received bad response code from server 404') {
        console.warn('Socket not scheduling reconnection because of 404');
        return;
      }
      this.scheduleReconnect();
    });

    return socket;
  };
}

export default SocketManager;
