import Tooltip from '@/components/Tooltip/Tooltip';
import { useGetOrganisationQuery } from '@/lib/organisations/api';
import {
  OrganisationLogoDefault,
  OrganisationLogoUploadEnabled,
} from '@/lib/organisations/helpers';
import { useOrganisationId } from '@/lib/organisations/OrganisationIDProvider';
import OrganisationLogoModal from '@/lib/organisation_logo/components/OrganisationLogoModal';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { Plus } from 'react-feather';
import { formatCount } from '../helpers';
import { SustainabilityActionStatistics } from '../types';
import CompletedOrganisationsModal from './CompletedOrganisationsModal';

export function OrganisationLogo({
  name,
  tooltip,
  url,
  onClick,
}: {
  name: string;
  tooltip?: string;
  url: string;
  onClick?: () => void;
}) {
  return (
    <Tooltip tooltip={tooltip ?? name}>
      <li className="-mr-2 cursor-pointer" onClick={onClick}>
        <div className="relative h-[2.125rem] w-[2.125rem] overflow-hidden rounded-full border-[0.088rem] border-white bg-gray-200 shadow first:ml-0">
          <Image alt={name} src={url} layout="fill" objectFit="cover" />
        </div>
      </li>
    </Tooltip>
  );
}

export default function CompletedOrganisationsDisplay({
  actionSlug,
  statistics,
  completed,
  isActionsGrid,
  reverse,
  mode = 'actions',
}: {
  actionSlug?: string;
  statistics?: SustainabilityActionStatistics;
  completed?: boolean;
  isActionsGrid?: boolean;
  reverse?: boolean;
  mode?: 'details' | 'actions';
}) {
  const organisationId = useOrganisationId();
  const { data: organisation } = useGetOrganisationQuery(
    organisationId ? { id: organisationId } : skipToken,
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const [showUploadModal, setShowUploadModal] = React.useState<boolean>(false);

  const showUploadButton =
    OrganisationLogoUploadEnabled &&
    completed &&
    organisation &&
    !organisation.logo_image_url;

  if (!actionSlug) {
    return null;
  }

  return (
    <div>
      {statistics && statistics.completed_count > 0 ? (
        <div
          className={classNames('flex flex-row items-center gap-x-3.5', {
            'flex-row-reverse justify-end': reverse,
          })}
        >
          <span
            className={classNames('p5 block', {
              'text-gray-700': isActionsGrid,
            })}
          >
            {mode === 'actions'
              ? `${formatCount(statistics?.completed_count)}+`
              : `${formatCount(statistics?.completed_count)} completed this`}
          </span>
          {/* Render logos */}

          {(statistics?.organisation_logos || []).length > 0 ? (
            <ul className="flex flex-row">
              {statistics?.organisation_logos?.map((o, i) => (
                <OrganisationLogo
                  key={o.id}
                  url={o.logo_image_url ?? OrganisationLogoDefault}
                  name={o.name}
                  tooltip={organisationId == o.id ? 'It’s you!' : o.name}
                  onClick={() =>
                    !isActionsGrid ? setShowModal(true) : undefined
                  }
                />
              ))}
            </ul>
          ) : null}

          {showUploadButton ? (
            <UploadButton onClick={() => setShowUploadModal(true)} />
          ) : null}
        </div>
      ) : null}

      {statistics?.completed_count ? (
        <CompletedOrganisationsModal
          completionCount={statistics?.completed_count}
          actionSlug={actionSlug}
          open={showModal}
          onClose={onCloseModal}
        />
      ) : null}

      <OrganisationLogoModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />
    </div>
  );
}

function UploadButton({ onClick }: { onClick?: () => void }) {
  return (
    <div className="ml-[-2]">
      <Tooltip
        mode="light"
        tooltip={
          <div className={classNames('w-64 rounded-lg bg-white p-4 shadow')}>
            <h5 className="mb-2">Add your logo</h5>
            <p className="p4 text-gray-600">
              Share your progress with the Greener community.
            </p>
          </div>
        }
      >
        <button
          className="flex h-[2.125rem] w-[2.125rem] items-center justify-center rounded-full border-2 border-dashed border-green-500"
          onClick={e => {
            e.preventDefault();
            onClick?.();
          }}
        >
          <Plus className="h-6 w-6 text-green-500" />
        </button>
      </Tooltip>
    </div>
  );
}
