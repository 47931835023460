import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { IFormInputError } from './types';

function FormInputError({ errors, info }: IFormInputError) {
  if (!errors && !info) return null;

  return (
    <>
      {errors?.message ? (
        <div className="mb-5 mt-1.5 flex items-start space-x-1.5">
          <ExclamationCircleIcon className="mt-[0.1rem] h-[0.9375rem] w-[0.9375rem] text-red-800" />
          <p className="p6 text-red-800">{errors?.message}</p>
        </div>
      ) : (
        <>{info && <p className="p6 mb-5 mt-1.5 text-gray-700">{info}</p>}</>
      )}
    </>
  );
}

export default FormInputError;
