import { AppState } from '@/lib/store';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import Router from 'next/router';
import { clearSessionData } from '../auth/sessionSlice';

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { credentials?: RequestCredentials } | undefined
> = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: process.env['NEXT_PUBLIC_API_URL'] ?? 'https://api.greenr.global',
    credentials: extraOptions?.credentials ?? 'include',
    prepareHeaders: (headers, { getState }) => {
      // Get the cookies from the store for SSR
      const { cookie } = (getState() as AppState).session;
      if (cookie) {
        headers.set('cookie', cookie);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions ?? {});
};

const baseQueryWithAuthRedirect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // If we got a 401, redirect to the login screen
    if (process.browser) {
      if (!Router.asPath.startsWith('/session')) {
        await api.dispatch(clearSessionData());
        Router.push(`/session?redirect=${encodeURIComponent(Router.asPath)}`);
      }
    }
  }
  return result;
};
// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAuthRedirect,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [
    'CurrentUser',
    'CurrentUserOnboarding',
    'OrganisationSustainabilityActions',
    'OrganisationSustainabilityAction',
    'CurrentUserCampaign',
    'OrganisationDynamicSavings',
  ],
  endpoints: _builder => ({}),
});

export default api;
