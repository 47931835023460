import classNames from 'classnames';

export default function CarouselPageDots({
  selectedIndex,
  scrollSnaps,
  alwaysVisible = false,
  scrollTo,
}: {
  selectedIndex: number;
  scrollSnaps: number[];
  alwaysVisible?: boolean;
  scrollTo?: (index: number) => void;
}) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center space-x-1.5',
        { 'md:flex': alwaysVisible },
        { 'md:hidden': !alwaysVisible },
      )}
    >
      {scrollSnaps.map((_x, index) => (
        <button
          key={index}
          onClick={() => scrollTo?.(index)}
          className={classNames(
            'transform-opacity h-2 w-2 rounded-full bg-gray-900 duration-200 ease-linear',
            {
              'opacity-1': index === selectedIndex,
              'opacity-20': index !== selectedIndex,
            },
          )}
        />
      ))}
    </div>
  );
}
