import classNames from '@/helpers/classNames';
import ActionImpactAndEffortDisplay from '@/lib/actions/components/ActionImpactAndEffortDisplay';
import CompletedOrganisationsDisplay from '@/lib/actions/components/CompletedOrganisationsDisplay';
import Image from 'next/legacy/image';
import { useState } from 'react';
import ActionFeedbackModalDialog from '../ActionFeedback/ActionFeedbackModalDialog';
import { Button } from '../Button';
import { BUTTON_KIND, BUTTON_SIZE } from '../Button/Button';
import Icon from '../Icons/Icon';
import CardWrapper from './CardWrapper';
import { ICard } from './types';

export default function CardActionContentListMode(props: ICard) {
  const {
    image,
    progress_status,
    containerStyle,
    title,
    short_description,
    href,
    action,
    isLink = true,
    parent_slug,
    slug,
    statistics,
  } = props;
  const isCompleted = progress_status === 'completed';

  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);

  return (
    <CardWrapper
      href={href}
      isLink={isLink}
      className={classNames(
        'relative flex w-full flex-col overflow-hidden rounded-2xl bg-white shadow-card md:flex-row',
        containerStyle || '',
      )}
    >
      <div>
        <div className="md:h-full md:w-37.5">
          <div
            className={classNames(
              'relative rounded-xl p-1.5 max-md:aspect-video md:h-full',
              isCompleted ? 'opacity-50' : '',
            )}
          >
            <Image
              layout="fill"
              objectFit="cover"
              src={image?.url || ''}
              alt={title}
            />
          </div>
          {isCompleted ? (
            <div className="absolute bottom-0 left-1.5 right-1.5 top-0 z-10 flex w-60 items-center justify-center">
              <Icon className="h-16 w-16" name="check" />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex w-full flex-col p-5">
        <div className="mb-4 flex-1">
          <div className="mb-2">
            <h6 className="mb-1">{title}</h6>
            <ActionImpactAndEffortDisplay
              impact={action?.impact}
              effort={action?.effort}
            />
          </div>
          <p className="p5">{short_description}</p>
        </div>

        <div className="items-between mt-4 flex w-full justify-between">
          <Button
            href={href}
            title="Learn how"
            kind={BUTTON_KIND.SECONDARY}
            size={BUTTON_SIZE.MEDIUM}
          />
          <div className="flex flex-1 items-center justify-end">
            <CompletedOrganisationsDisplay
              actionSlug={parent_slug || slug}
              statistics={statistics ?? undefined}
              completed={progress_status === 'completed'}
              isActionsGrid
            />
          </div>
        </div>
      </div>
      {showFeedbackDialog && (
        <ActionFeedbackModalDialog
          isOpen={showFeedbackDialog}
          actionIdOrSlug={props.parent_slug ?? props.slug}
          setIsOpen={setShowFeedbackDialog}
        />
      )}
    </CardWrapper>
  );
}

CardActionContentListMode.displayName = 'CardActionContentListMode';
