import { useInterval } from '@/hooks/useInterval';
import { CarouselItems, CaseOfStudyItem } from '@/lib/origin/data';
import CaseOfStudyCarouselOne from '@/public/images/session/case_study/Sign up/Hero_CaseStudy01.png';
import CaseOfStudyCarouselTwo from '@/public/images/session/case_study/Sign up/Hero_CaseStudy02.png';
import CaseOfStudyCarouselThree from '@/public/images/session/case_study/Sign up/Hero_CaseStudy03.png';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { type EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/legacy/image';
import { useCallback, useEffect, useState } from 'react';
import CarouselPageDots from '../Carousel/CarouselPageDots';

const AUTO_SCROLL_TIME = 5000;

// Dev note: This carousel item will be used once we have CMS content for this.
function CaseOfStudyCarouselItem({
  item,
  show,
}: {
  item: CaseOfStudyItem;
  show: boolean;
}) {
  const {
    backgroundImage,
    logoImage,
    annualSaving,
    carbonFootprintTonnesReduction,
    name,
    location,
  } = item;

  return (
    <div className="relative h-[32.5rem] w-[27.5rem] flex-[0_0_27.5rem] bg-white">
      <Transition
        appear={true}
        show={show}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={classNames('h-full w-full')}>
          <Image
            src={backgroundImage}
            alt="Busines Savings image"
            layout="fill"
            objectFit="cover"
            className="rounded-2xl"
          />
        </div>

        <div
          className={classNames(
            'absolute bottom-4 left-4 right-4 z-10 flex space-x-4.5 rounded-xl bg-white px-3.5 py-3.5 ease-in-out',
          )}
        >
          <div className="flex h-[4.875rem] w-[4.875rem] items-center justify-center rounded-full border-2 border-[#8CDDAA]">
            <Image
              src={logoImage}
              alt="Vertical how it works image"
              className="rounded-full"
              width={68}
              height={68}
            />
          </div>
          <div className="flex flex-1 flex-col justify-center space-y-1.5">
            <div className="h7">
              {name}
              {location ? `, ${location}` : ''}
            </div>
            <div className="flex">
              <div className="flex flex-1 flex-col">
                <h4>${annualSaving}</h4>
                <div className="h9">Annual savings</div>
              </div>

              <div className="flex flex-1 flex-col">
                <div className="flex items-center space-x-1">
                  <h4>{carbonFootprintTonnesReduction} tonnes</h4>
                </div>
                <div className="h9">Annual emissions saved</div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

function CaseOfStudyImageCarouselItem({
  image,
  show,
}: {
  image: any;
  show: boolean;
}) {
  return (
    <div className="relative md:w-[24.75rem] md:flex-[0_0_24.75rem] lg:h-[32.75rem] lg:w-[33.75rem] lg:flex-[0_0_33.75rem]">
      <Transition
        appear={true}
        show={show}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Image alt="Case of study image" src={image} />
      </Transition>
    </div>
  );
}

export default function CaseOfStudiesCarousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center',
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  // Autoscroll
  useInterval(() => {
    scrollTo((selectedIndex + 1) % CarouselItems.length);
  }, AUTO_SCROLL_TIME);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);

    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  // Reset scroll position on items change
  useEffect(() => {
    emblaApi?.scrollTo(0);
    setSelectedIndex(0);
  }, [emblaApi]);

  /* Lets use this until we get them from the cms */
  return (
    <div className="hidden h-full w-full flex-col items-center justify-center gap-y-4.5 border bg-pageGradient2 md:flex">
      <div
        className="overflow-hidden rounded-2xl md:w-[24.75rem] lg:w-[33.75rem]"
        ref={emblaRef}
      >
        <div className="flex gap-x-4 ">
          {[
            CaseOfStudyCarouselOne,
            CaseOfStudyCarouselTwo,
            CaseOfStudyCarouselThree,
          ]?.map((item, index) => (
            <CaseOfStudyImageCarouselItem
              key={index}
              image={item}
              show={selectedIndex === index}
            />
          ))}
        </div>
      </div>

      <CarouselPageDots
        scrollSnaps={scrollSnaps}
        selectedIndex={selectedIndex}
        scrollTo={scrollTo}
        alwaysVisible
      />
    </div>
  );
}
