import { Button } from '@/components/Button';
import { BUTTON_KIND } from '@/components/Button/Button';
import { FormInputError } from '@/components/FormInputError';
import ImageUploader from '@/lib/app_listing_editor/components/ImageUploader';
import {
  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
} from '@/lib/organisations/api';
import { useOrganisationId } from '@/lib/organisations/OrganisationIDProvider';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { Fragment } from 'react';
import { FileError } from 'react-dropzone';

export default function OrganisationLogoModal({
  open,
  onClose,
}: {
  open?: boolean;
  onClose: () => void;
}) {
  const organisationId = useOrganisationId();
  const [updateOrganisation, updateOrganisationQuery] =
    useUpdateOrganisationMutation();
  const { data: organisation } = useGetOrganisationQuery(
    organisationId
      ? {
          id: organisationId,
        }
      : skipToken,
  );
  const [fileId, setFileId] = React.useState<string | undefined>(undefined);
  const [fileUrl, setFileUrl] = React.useState<string | undefined>(undefined);

  const [logoError, setLogoError] = React.useState<string | undefined>(
    undefined,
  );

  const logoUrl = fileUrl || organisation?.logo_image_url;

  const onSaveClicked = async () => {
    if (!organisationId) return;

    if (updateOrganisationQuery.isLoading) return;

    try {
      const _org = await updateOrganisation({
        id: organisationId,
        orgParams: { logo_file_id: fileId },
      });
      onClose();
    } catch {
      // HALP
    }
  };

  const onRequestClose = () => {
    if (updateOrganisationQuery.isLoading) return false;

    onClose();
  };

  return (
    <Transition show={open} appear>
      <Dialog open={true} onClose={onRequestClose} className="relative z-50">
        <Transition.Child
          className="fixed bottom-0 left-0 right-0 top-0"
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="max-md:translate-y-full md:opacity-0 md:scale-105"
          enterTo="md:opacity-100 translate-y-0"
          leave="duration-300 ease-in"
          leaveFrom="translate-y-0 md:opacity-100"
          leaveTo="max-md:translate-y-full md:opacity-0 md:scale-105"
        >
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center max-md:items-end max-md:pt-14">
              <Dialog.Panel className="relative flex w-full max-w-3xl flex-col items-center justify-start overflow-y-auto rounded-t-3xl bg-white md:mx-4 md:my-4 md:justify-center md:rounded-[1rem] md:shadow-cardHover">
                {/* Small devices button */}
                <div
                  className={`flex w-full items-center justify-end overflow-hidden p-4 md:hidden`}
                >
                  <button
                    className="flex items-center justify-center p-1.5"
                    onClick={onClose}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>

                {/* Larger devices button */}
                <div className={`absolute right-4 top-4 hidden md:flex`}>
                  <button
                    className="flex items-center justify-center p-1.5"
                    onClick={onRequestClose}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>

                <div className="w-full p-4 md:p-10">
                  <div className="flex flex-col items-center">
                    <h3 className="mb-10">Add your logo</h3>
                    <div className="mb-10 w-64">
                      <ImageUploader
                        title="Add your logo"
                        initialImageUrl={logoUrl ?? undefined}
                        onInvalidFile={(error: FileError) => {
                          console.error({ error });
                          setLogoError(error.message);
                        }}
                        onImageUploaded={(imageId, url) => {
                          setLogoError(undefined);
                          setFileId(imageId);
                          setFileUrl(url);
                        }}
                      />
                      {logoError ? (
                        <FormInputError
                          errors={{ type: 'required', message: logoError }}
                        />
                      ) : undefined}
                    </div>
                    <p className="p4 mb-10 max-w-md text-center text-gray-700">
                      We suggest square images that are at least 700x700 pixels.
                      Use either JPG or PNG files that are under 10MB.
                    </p>

                    {/* Show buttons after uploading a file */}
                    {fileId && !logoError ? (
                      <div className="flex w-full flex-row justify-end space-x-4">
                        <Button
                          disabled={updateOrganisationQuery.isLoading}
                          kind={BUTTON_KIND.SECONDARY}
                          onClick={onRequestClose}
                          title="Cancel"
                        />
                        <Button
                          disabled={updateOrganisationQuery.isLoading}
                          loading={updateOrganisationQuery.isLoading}
                          kind={BUTTON_KIND.PRIMARY}
                          onClick={onSaveClicked}
                          title="Save"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
