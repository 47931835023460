import classNames from '@/helpers/classNames';

export default function Wrapper(props: {
  children: React.ReactNode;
  fullWidth?: boolean;
  withoutAutoSizing?: boolean;
}): JSX.Element {
  const { children, fullWidth = false, withoutAutoSizing = false } = props;

  return (
    <div
      className={classNames(
        'relative  h-full px-4 md:px-10 lg:px-12',
        fullWidth ? 'xl:px-20' : 'xl:max-w-6xl xl:px-20 2xl:max-w-7xl',
        withoutAutoSizing ? 'mx-0 box-border' : 'mx-auto box-content',
      )}
    >
      {children}
    </div>
  );
}
