import Link from 'next/link';

export default function CardWrapper({
  isLink,
  href,
  children,
  className,
}: {
  isLink?: boolean;
  href?: string;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <>
      {isLink && href ? (
        <>
          <Link href={href} passHref className={className}>
            {children}
          </Link>
        </>
      ) : (
        <>
          <div className={className}>{children}</div>
        </>
      )}
    </>
  );
}
