import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React from 'react';
import { FormInputError } from '../FormInputError';
import { COMPONENT_NAME, IInputSelect } from './types';

const InputSelect = React.forwardRef<HTMLDivElement, IInputSelect>(
  (props, ref) => {
    const { items, value, hideIcon, small, onChange, onBlur, errors } = props;

    return (
      <RadioGroup
        value={value}
        onChange={onChange}
        className="w-full"
        onBlur={onBlur}
        ref={ref}
      >
        {items.map(item => (
          <RadioGroup.Option
            value={item.value}
            key={item.value}
            className="group mb-4 focus:outline-none"
          >
            {({ checked }) => (
              <div
                className={classNames(
                  'flex cursor-pointer gap-4 rounded-2xl border p-4 transition-all hover:border-gray-900 active:bg-gray-100 group-active:ring-0 md:p-6',
                  checked
                    ? 'border-green-500 bg-gray-100 ring-2 ring-green-500 ring-offset-0'
                    : 'border-gray-400 bg-white group-focus:border-gray-900 group-focus:ring-1 group-focus:ring-gray-900',
                  item.image && !hideIcon ? 'flex-col md:flex-row' : '',
                  small ? 'rounded-xl p-2 md:p-2 lg:p-2' : '',
                )}
              >
                <div
                  className={classNames(
                    item.image && !hideIcon
                      ? 'flex w-full justify-between md:block md:w-auto'
                      : '',
                  )}
                >
                  <div
                    className={classNames(
                      'h-5.5 w-5.5 shrink-0 rounded-full border p-1 transition-all md:h-6 md:w-6',
                      checked ? ' border-gray-900' : ' border-gray-400',
                      small ? 'md:h-5.5 md:w-5.5' : '',
                    )}
                  >
                    <div
                      className={classNames(
                        'rounded-full transition-all',
                        checked
                          ? 'h-full w-full bg-gray-900'
                          : 'bg-transparent',
                      )}
                    />
                  </div>
                  {item.image && !hideIcon && (
                    <div className="w-22.5 md:hidden">
                      <Image
                        src={item.image.url}
                        alt={item.label}
                        width={item.image.width}
                        height={item.image.height}
                        layout="responsive"
                      />
                    </div>
                  )}
                </div>
                <div
                  className={classNames('flex flex-col justify-center', {
                    'justify-center': !item.info,
                  })}
                >
                  <h6>{item.label}</h6>
                  {item.info ? (
                    <p className="p5 mt-0.5 text-gray-700">{item.info}</p>
                  ) : null}
                </div>
                {item.image && !hideIcon && (
                  <div className="hidden w-22.5 shrink-0 md:block">
                    <Image
                      width={item.image.width}
                      height={item.image.height}
                      src={item.image.url}
                      alt={item.label}
                      layout="responsive"
                    />
                  </div>
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
        <FormInputError errors={errors} />
      </RadioGroup>
    );
  },
);

InputSelect.displayName = COMPONENT_NAME;

export default InputSelect;
