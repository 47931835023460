import { ImageAsset } from '@/lib/api/types';
import { FieldError } from 'react-hook-form';

export const COMPONENT_NAME = 'InputSelect';

export interface IInputSelect {
  items: {
    value: string;
    label: string;
    info?: string;
    image?: ImageAsset;
  }[];
  value?: string;
  small?: boolean;
  hideIcon?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  errors?: FieldError;
}
