import { safeRedirectUrl } from '@/helpers/session';
import { useRouter } from 'next/router';

// Returns the URL to redirect to after signing in
export default function useLoginRedirectUrl() {
  const router = useRouter();

  let redirectUrl = '/';

  if (typeof window !== 'undefined') {
    // we are in the browser so we can check local storage
    const redirect = localStorage.getItem('redirect');
    redirectUrl = (router.query.redirect as string) ?? redirect ?? '/';
  } else {
    // On server no local storage
    redirectUrl = (router.query.redirect as string) ?? '/';
  }

  // Don't allow redirect to the new org page...
  // stops dumb people doing dumb things.
  if (redirectUrl.toLocaleLowerCase() == '/organisations/new') {
    redirectUrl = '/organisations';
  }
  return safeRedirectUrl(redirectUrl);
}
