import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import React from 'react';

const ARROW_HEIGHT = 7;
const GAP = 4;

export default function Tooltip({
  tooltip,
  children,
  mode = 'dark',
}: {
  tooltip: React.ReactNode;
  children: React.ReactNode;
  mode?: 'dark' | 'light';
}) {
  const arrowRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      shift({
        padding: { top: 200 },
      }),
      offset(ARROW_HEIGHT + GAP),
      arrow({
        element: arrowRef,
      }),
      autoPlacement({ allowedPlacements: ['top', 'bottom'] }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
  });

  const hover = useHover(context, { restMs: 100, delay: { open: 1000 } });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
  });

  const tooltipIsString = typeof tooltip === 'string';

  return (
    <div>
      {isMounted ? (
        <div
          className="z-10"
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div
            style={styles}
            className={classNames(
              {
                'p6 rounded px-2 py-0.5 text-center': tooltipIsString,
              },
              {
                'bg-gray-900 bg-opacity-80 text-white':
                  mode == 'dark' && tooltipIsString,
              },
              { 'bg-white text-gray-900': mode == 'light' && tooltipIsString },
            )}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className={classNames(
                { 'text-gray-900': mode == 'dark' },
                { 'text-white': mode == 'light' },
              )}
              opacity={0.8}
              fill="currentColor"
            />

            {tooltip}
          </div>
        </div>
      ) : null}
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
    </div>
  );
}
