import { IIcon } from './types';

function SocialFacebook({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 25 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 12C22.5 6.477 18.023 2 12.5 2C6.977 2 2.5 6.477 2.5 12C2.5 16.991 6.157 21.128 10.938 21.878V14.891H8.398V12H10.938V9.797C10.938 7.291 12.43 5.907 14.715 5.907C15.809 5.907 16.953 6.102 16.953 6.102V8.562H15.693C14.45 8.562 14.063 9.333 14.063 10.124V12H16.836L16.393 14.89H14.063V21.878C18.843 21.128 22.5 16.991 22.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

SocialFacebook.displayName = 'SocialFacebook';

export default SocialFacebook;
