import classNames from 'classnames';
import Button, { BUTTON_SIZE } from '../Button/Button';
import { COMPONENT_NAME, IInlineLoadError } from './types';

function InlineLoadError({
  textStyle,
  isLoading,
  isError,
  refetch,
  message,
}: IInlineLoadError) {
  if (isLoading) return null;
  if (!isError) return null;
  return (
    <div className="flex flex-row items-baseline justify-between space-x-4">
      <h4 className={classNames('font-lg mb-2 font-bold', textStyle)}>
        {message}
      </h4>
      <Button size={BUTTON_SIZE.SMALL} onClick={refetch} title="Retry" />
    </div>
  );
}

InlineLoadError.displayName = COMPONENT_NAME;

export default InlineLoadError;
