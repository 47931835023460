import classNames from '@/helpers/classNames';
import { useGetCurrentUserQuery } from '@/lib/auth/api';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
// import useScrollDirection from '@/hooks/useScrollDirection';
import Wrapper from '@/containers/Wrapper';
import { useListOrganisationsQuery } from '@/lib/organisations/api';
import ProfilePlaceholder from '@/public/images/profile_placeholder.png';
import Logo from '@/public/logo.svg';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { Button } from '../Button';
import HeaderMenu from './HeaderMenu';
import HeaderNav from './HeaderNav';
import { COMPONENT_NAME, IHeaderMenuItem } from './types';
// import { useGetOrganisationQuery } from '@/lib/organisations/api';
// import { skipToken } from '@reduxjs/toolkit/dist/query';

export const DEFAULT_HEADER_IMAGE_HREF = 'https://greener.com.au/business/';

const OffersEnabled = process.env.NEXT_PUBLIC_OFFERS_ENABLED == 'true';

function Header({
  showLoginOnHeader,
  minimalHeader = false,
  notLoggedIn = false,
  signUpHeader = false,
  fullWidthHeader = true,
}: {
  showLoginOnHeader?: boolean;
  minimalHeader?: boolean;
  notLoggedIn?: boolean;
  signUpHeader?: boolean;
  fullWidthHeader?: boolean;
}) {
  // const { scrollDirection, lastScrollY } = useScrollDirection();
  const { data: user } = useGetCurrentUserQuery();
  const userProfileImage = user?.profile_image_url ?? ProfilePlaceholder;
  const {
    query: { organisationId },
  } = useRouter();

  const organisationQuery = useListOrganisationsQuery(
    organisationId ? undefined : skipToken,
    {
      // poll every 5 minutes
      pollingInterval: 300000,
      skip: notLoggedIn,
      selectFromResult: r => ({
        ...r,
        // Filter out the selected org
        member_orgs: r.data
          ?.filter(o => o.id !== organisationId)
          .filter(o => !o.current_user_member?.is_invitation),
        invitation_orgs: r.data?.filter(
          o => o.current_user_member?.is_invitation,
        ),
        // Get the selected org
        selectedOrg: r.data?.find(o => o.id === organisationId),
      }),
    },
  );

  // Hidded for now until desition is made to add it back.
  // const selectedOrgHasMerchant =
  //   organisationQuery.selectedOrg?.merchant_id !== null;

  const menuItems = React.useMemo((): IHeaderMenuItem[] => {
    const items: IHeaderMenuItem[] = [
      {
        id: '1',
        name: 'Home',
        href: organisationId
          ? `/organisations/${organisationId}`
          : '/organisations',
      },
      {
        id: 'actions',
        name: 'Action plan',
        href: organisationId
          ? `/organisations/${organisationId}/actions`
          : '/organisation/actions',
      },
      // {
      //   id: '3',
      //   name: selectedOrgHasMerchant ? 'App listing' : 'Join the app',
      //   href: organisationId
      //     ? `/organisations/${organisationId}/app-listing`
      //     : '/organisation/app-listing',
      // },

      {
        id: '4',
        name: 'Offers',
        href: `/organisations/${organisationId}/offers`,
        hidden: !OffersEnabled,
      },
      {
        id: '5',
        name: 'News',
        href: organisationId
          ? `/organisations/${organisationId}/resources`
          : '/organisation/resources',
      },
    ];

    if (process.env.NEXT_PUBLIC_ENABLE_XERO_INTEGRATION === 'true') {
      items.push({
        id: '6',
        name: 'Emissions',
        href: `/organisations/${organisationId}/emissions`,
        beta: true,
      });
    }

    return items;
  }, [organisationId]);

  const navItems = React.useMemo(() => {
    return [
      {
        id: '5',
        name: 'Account',
        subItems: [
          // {
          //   id: 'OrganisationName',
          //   name: organisation?.name,
          // },
          {
            id: 'badges',
            name: 'Achievements',
            href: organisationId
              ? `/organisations/${organisationId}/badges`
              : '/organisation/badges',
          },
          {
            id: '5-2',
            name: 'Business settings',
            href: organisationId
              ? `/organisations/${organisationId}/business-information`
              : '/organisation/business-information',
          },
          {
            id: '5-6',
            name: 'Team members',
            href: organisationId
              ? `/organisations/${organisationId}/settings/members`
              : '/organisation/settings/members',
          },
          {
            id: '5-5',
            name: 'Switch business',
            href: '/organisations/select',
            badge: organisationQuery.invitation_orgs?.length,
          },
          {
            break: true,
            id: '5-3',
            name: 'Contact us',
            href: organisationId
              ? `/organisations/${organisationId}/contact-us`
              : '/organisation/contact-us',
          },
          {
            id: '5-1',
            name: 'Account settings',
            href: `/account-settings`,
          },
          {
            id: '5-4',
            name: 'Log out',
            href: '/',
            isLogout: true,
          },
        ],
      },
    ];
  }, [organisationId, organisationQuery.invitation_orgs]);

  const newInvitationCount = organisationQuery.invitation_orgs?.length ?? 0;
  const badge = newInvitationCount;

  return (
    <header
      className={classNames(
        'sticky top-0 z-40 h-16 w-full border-b border-black/5 bg-white  md:h-18',
        signUpHeader ? 'border-b-0' : 'shadow-navigation',
      )}
    >
      <Wrapper fullWidth={fullWidthHeader}>
        <div className="flex h-full w-full items-center justify-between">
          {minimalHeader ? (
            <Link
              href={
                organisationId
                  ? `/organisations/${organisationId}`
                  : DEFAULT_HEADER_IMAGE_HREF
              }
            >
              <div className="hidden md:block">
                <Image
                  priority
                  layout="fixed"
                  src={Logo}
                  alt="Greener for Business"
                  width={223}
                  height={20}
                />
              </div>
              <div className="md:hidden">
                <Image
                  priority
                  layout="fixed"
                  src={Logo}
                  alt="Greener for Business"
                  width={178}
                  height={16}
                />
              </div>
            </Link>
          ) : (
            <Link
              href={
                organisationId
                  ? `/organisations/${organisationId}`
                  : DEFAULT_HEADER_IMAGE_HREF
              }
              passHref
            >
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}

              <div className="hidden md:block">
                <Image
                  priority
                  layout="fixed"
                  src={Logo}
                  alt="Greener for Business"
                  width={223}
                  height={20}
                />
              </div>
              <div className="md:hidden">
                <Image
                  priority
                  layout="fixed"
                  src={Logo}
                  alt="Greener for Business"
                  width={178}
                  height={16}
                />
              </div>
            </Link>
          )}
          {user && !minimalHeader && <HeaderMenu items={menuItems} />}
          {user && !minimalHeader && (
            <HeaderNav
              items={menuItems}
              navItems={navItems}
              userName={user.first_name}
              userProfileImage={userProfileImage}
              badge={badge}
            />
          )}
          {!user && showLoginOnHeader && (
            <>
              <div className="hidden md:block">
                <Button title="Sign up or log in" href="/session" />
              </div>
              <div className="md:hidden">
                <Link
                  href="/session"
                  passHref
                  className="appearance-none font-sans-semibold text-base leading-5 tracking-tight underline"
                >
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  Sign up or log in
                </Link>
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </header>
  );
}

Header.displayName = COMPONENT_NAME;

export default Header;
