import { Button } from '@/components/Button';
import { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button/Button';
import {
  PhotoFile,
  useUploadOrganisationImageMutation,
} from '@/lib/images/api';
import { useOrganisationId } from '@/lib/organisations/OrganisationIDProvider';
import { CameraIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { PlusCircle } from 'react-feather';

type UploaderState = 'initial' | 'uploading' | 'error' | 'uploaded';

function fileSizeValidator(file: File, maxSize: number = 10_000_000) {
  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: `This file is too large. It must be less than ${
        maxSize / 1_000_000
      } MB.`,
    };
  }

  return null;
}

function fileValidator(file: File): FileError[] | null {
  let errors = [fileSizeValidator(file)];

  const result = errors.filter(f => !!f) as FileError[];

  if (result.length > 0) {
    return result;
  }

  return null;
}

export function ProgressBar({ progress }: { progress?: number }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-70 p-12">
      <div className="flex flex-col">
        <div className="p4 mb-2 block text-white">
          <span className="font-bold">
            {progress === 100 ? 'Processing' : 'Uploading'}
          </span>
          <span className="ml-2">{progress ?? 0}%</span>
        </div>
        <div className="h-1 w-full overflow-clip rounded-full bg-white">
          {progress ? (
            <div
              className="h-full origin-left rounded-full bg-green-400 transition-transform duration-100"
              style={{ transform: `scaleX(${progress / 100}` }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export type ImageUploaderRef = {
  reset: (state: UploaderState) => void;
};

export type ImageUploaderProps = {
  initialImageUrl?: string;
  title?: string;
  onInvalidFile?: (error: FileError) => void;
  onImageUploaded?: (imageId: string, url: string) => void;
};

const ImageUploader = React.forwardRef<ImageUploaderRef, ImageUploaderProps>(
  function ImageUploader(
    {
      initialImageUrl,
      title = 'Upload image',
      onInvalidFile,
      onImageUploaded,
    }: ImageUploaderProps,
    ref,
  ) {
    const organisationId = useOrganisationId();
    const [uploaderState, setUploaderState] = React.useState<UploaderState>(
      initialImageUrl ? 'uploaded' : 'initial',
    );
    const [_file, setFile] = React.useState<File | undefined>(undefined);
    const [preview, setPreview] = React.useState<string | undefined>(undefined);
    const [progress, setProgress] = React.useState<number | undefined>(0);
    const [uploadedFile, setUploadedFile] = React.useState<
      PhotoFile | undefined
    >(undefined);

    const [uploadFile] = useUploadOrganisationImageMutation();

    React.useImperativeHandle(ref, () => {
      return {
        reset: async (state: UploaderState = 'initial') => {
          setFile(undefined);
          setPreview(undefined);
          setProgress(undefined);
          setUploadedFile(undefined);
          setUploaderState(state);
        },
      };
    });

    const onDrop = React.useCallback(
      async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (!organisationId) return;

        if (uploaderState === 'uploading') return;

        if (acceptedFiles.length > 0) {
          // Clear previously uploaded file
          setUploadedFile(undefined);

          setUploaderState('uploading');
          setFile(acceptedFiles[0]);
          const url = URL.createObjectURL(acceptedFiles[0]);
          setPreview(url);
          setProgress(0);

          try {
            const photo = await uploadFile({
              organisationId,
              file: acceptedFiles[0],
              onProgress: setProgress,
            }).unwrap();
            setUploadedFile(photo.data);
            setUploaderState('uploaded');
            onImageUploaded?.(photo.data.id, photo.data.large_url!);
          } catch (e) {
            setProgress(undefined);
            setPreview(undefined);
            setUploaderState(initialImageUrl ? 'uploaded' : 'initial');
            onInvalidFile?.({
              code: '-1',
              message: 'Error uploading the file. Please try again.',
            });
          } finally {
            setProgress(undefined);
          }
        } else if (rejectedFiles.length > 0) {
          if (!onInvalidFile) {
            const message =
              rejectedFiles[0].errors[0].message ?? 'Invalid file selected';
            alert(message);
          }
          onInvalidFile?.(rejectedFiles[0].errors[0]);
        }
      },
      [
        initialImageUrl,
        onImageUploaded,
        onInvalidFile,
        organisationId,
        uploadFile,
        uploaderState,
      ],
    );

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragReject,
      isDragAccept,
      open,
    } = useDropzone({
      onDrop,
      preventDropOnDocument: true,
      multiple: false,
      disabled: uploaderState === 'uploading',
      accept: {
        'image/png': [],
        'image/jpeg': [],
      },
      validator: fileValidator,
    });

    // Stop blob memory leaks..
    React.useEffect(() => {
      return () => {
        if (preview) {
          URL.revokeObjectURL(preview);
        }
      };
    }, [preview]);

    let imageUrl = React.useMemo(() => {
      if (uploadedFile?.large_url) {
        return uploadedFile.large_url;
      }

      if (preview) {
        return preview;
      }

      if (initialImageUrl) {
        return initialImageUrl;
      }

      return undefined;
    }, [initialImageUrl, preview, uploadedFile]);

    return (
      <div
        {...getRootProps()}
        className={classNames(
          'relative flex  aspect-square cursor-pointer overflow-clip rounded-xl',
          {
            'bg-white hover:bg-gray-100': !isDragActive,
            'bg-green-100': isDragActive && !isDragReject,
            'cursor-not-allowed bg-red-100': isDragActive && isDragReject,
          },
        )}
      >
        {/* Either the intial image, the preview image (during upload), or hte uploaded image */}
        {imageUrl ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            className="absolute bottom-0 left-0 right-0 top-0 aspect-square h-full w-full overflow-hidden object-cover"
            alt="Preview of uploaded image"
            src={imageUrl}
            onLoad={() => {
              if (preview && imageUrl == preview) {
                URL.revokeObjectURL(preview);
              }
            }}
          />
        ) : null}

        {/* Borders - these are inside as a seperate element so they can sit above the images */}
        <div
          className={classNames(
            'absolute bottom-0 left-0 right-0 top-0 rounded-xl',
            {
              // When no image, thick dashed border
              'border-[0.2rem] border-dashed border-green-500':
                !imageUrl || isDragActive,

              // When image, thin transparent border
              'border border-black border-opacity-30': imageUrl,
            },
          )}
        />

        {/* Upload instructions  */}
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          {uploaderState === 'uploading' ? (
            <ProgressBar progress={progress} />
          ) : null}

          <input {...getInputProps()} />

          {uploaderState === 'initial' ? (
            <div className="flex flex-col items-center justify-center">
              <PlusCircle className="mb-4 h-10 w-10 text-green-500" />
              <h6 className="h6 mb-2 text-gray-900">{title}</h6>
              {isDragActive ? (
                <p className="p5 text-gray-700">Drop the file here ...</p>
              ) : (
                <p className="p5 hidden text-gray-700 md:block">
                  or drag and drop
                </p>
              )}
            </div>
          ) : null}

          {uploaderState === 'uploaded' ? (
            <div className="absolute right-4 top-4">
              <Button
                className="bg-white"
                kind={BUTTON_KIND.SECONDARY}
                size={BUTTON_SIZE.SMALL}
                title="Edit"
                icon={<CameraIcon />}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

export default ImageUploader;
