import axios from 'axios';
import api from '../api/api';
import { DataResponse } from '../api/types';

export interface PhotoFile {
  blur_url: string | null;
  file: string | null; // Check this typo
  id: string;
  large_url: string | null;
  medium_url: string | null;
  name: string;
  original_url: string | null;
  small_url: string | null;
  thumb_url: string | null;
  upload_url: string;
}

const imagesApi = api
  .enhanceEndpoints({
    addTagTypes: ['Image'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      uploadOrganisationImage: builder.mutation<
        DataResponse<PhotoFile>,
        {
          organisationId: string;
          file: File;
          onProgress: (percentage: number) => void;
        }
      >({
        queryFn: ({ file, organisationId, onProgress }) => {
          let data = new FormData();
          data.append('file', file);

          onProgress(0);
          return axios
            .post(
              `${
                process.env['NEXT_PUBLIC_API_URL'] ??
                'https://api.greenr.global'
              }/api/cso/organisations/${organisationId}/files`,
              data,
              {
                withCredentials: true,
                onUploadProgress: progressEvent => {
                  const { loaded, total } = progressEvent;

                  let percent = Math.floor((loaded * 100) / total);

                  if (percent <= 100) {
                    onProgress(percent);
                  }
                },
              },
            )
            .then(result => {
              return { data: result.data };
            })
            .catch(error => {
              return { error: error };
            });
        },
      }),
    }),
  });

export default imagesApi;

export const { useUploadOrganisationImageMutation } = imagesApi;
