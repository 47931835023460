import Head from 'next/head';
import Image from 'next/legacy/image';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { isAndroid, isChrome } from 'react-device-detect';
import { Button } from '../Button';
import { BUTTON_KIND, BUTTON_SIZE } from '../Button/Button';
import OrSeparator from '../Separator/OrSeparator';

type ThirdPartyLoginTypes = 'apple' | 'google' | 'facebook' | 'xero';

export function getEnabledButtons() {
  let services: ThirdPartyLoginTypes[] = [];
  if (process.env.NEXT_PUBLIC_APPLE_AUTH_ENABLED === 'true') {
    services.push('apple');
  }
  if (process.env.NEXT_PUBLIC_GOOGLE_AUTH_ENABLED === 'true') {
    // If android, add it to to the start of the list
    if (isChrome || isAndroid) {
      services.unshift('google');
    } else {
      services.push('google');
    }
  }
  if (process.env.NEXT_PUBLIC_FACEBOOK_AUTH_ENABLED === 'true') {
    services.push('facebook');
  }

  if (process.env.NEXT_PUBLIC_XERO_AUTH_ENABLED === 'true') {
    services.push('xero');
  }

  return services;
}

export function AppleButton() {
  // Fix buttons not appearing after navigate
  React.useEffect(() => {
    const AppleID = (window as unknown as any).AppleID;
    if (!AppleID) return;
    AppleID.auth.renderButton();
  }, []);

  return (
    <div>
      <Head>
        <meta name="appleid-signin-client-id" content="com.getgreener.app" />
        <meta name="appleid-signin-scope" content="name email" />
        <meta
          name="appleid-signin-redirect-uri"
          content={`${
            process.env.NEXT_PUBLIC_API_URL
          }/api/auth/apple/callback?redirect=https://${
            process.env.NEXT_PUBLIC_SITE_URL ??
            process.env.NEXT_PUBLIC_VERCEL_URL
          }/session/thirdparty/apple/callback`}
        />
        <meta name="appleid-signin-use-popup" content="false" />
      </Head>
      <div
        className="flex-1"
        id="appleid-signin"
        data-color="black"
        data-border="true"
        data-width="302"
        data-height="44"
        data-logo-size="medium"
        data-border-radius="50"
        data-type="sign in"
      />
      <Script
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="afterInteractive"
      />
    </div>
  );
}

export function XeroButton() {
  // Fix buttons not appearing after navigate
  useEffect(() => {
    console.log('mounting');
    // Load the Xero SSO script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://edge.xero.com/platform/sso/xero-sso.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const redirect = `${
    process.env.NEXT_PUBLIC_API_URL
  }/api/auth/xero/callback?redirect=https://${
    process.env.NEXT_PUBLIC_SITE_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
  }/session/thirdparty/xero/callback`;

  const clientId = process.env.NEXT_PUBLIC_XERO_CLIENT_ID;

  return (
    <div>
      <Head>
        <meta name="xero-client-id" content={clientId} />
        <meta name="xero-scopes" content="openid,profile,email" />
        <meta name="xero-response-type" content="code" />
        <meta name="xero-redirect-uri" content={redirect} />
        <meta name="xero-state" content="type=login" />
      </Head>
      <div
        style={{ width: 302 }}
        data-xero-sso
        data-size="large"
        data-border-radius="50"
        data-box-shadow="0"
        data-height="44"
      ></div>
      {/* <Script
        src="https://edge.xero.com/platform/sso/xero-sso.js"
        async
        defer
      ></Script> */}
    </div>
  );
}

export function GoogleButton() {
  const [gsiScriptLoaded, setGsiScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    if (gsiScriptLoaded) return;

    const initializeGsi = () => {
      // Typescript will complain about window.google
      // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
      if (!window.google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGsi;
    script.async = true;
    script.id = 'google-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      (window.google as any)?.accounts.id.cancel();
      document.getElementById('google-client-script')?.remove();
    };
  }, [gsiScriptLoaded]);

  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id={process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID}
        data-login_uri={`${
          process.env.NEXT_PUBLIC_API_URL
        }/api/auth/google/callback?redirect=https://${
          process.env.NEXT_PUBLIC_SITE_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
        }/session/thirdparty/google/callback`}
        data-auto_prompt="false"
      ></div>
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="pill"
        data-width="302"
        data-height="52"
        data-logo_alignment="center"
      ></div>
      {/* <Script
        src="https://accounts.google.com/gsi/client"
        async
        defer
        strategy="afterInteractive"
      /> */}
    </div>
  );
}

interface SocialLoginButtonProps {
  socialName: string;
  socialImage: any;
  onClick: () => void;
}

export function SocialLoginButton(props: SocialLoginButtonProps) {
  const { socialName, socialImage, onClick } = props;
  return (
    <Button
      style={{ maxWidth: 302 }}
      className="w-[18.875rem] justify-center"
      onClick={onClick}
      title={`Continue with ${socialName}`}
      icon={
        <Image
          width={20}
          height={20}
          className="block h-5 w-5 object-cover"
          src={socialImage}
          alt="{socialName}"
        />
      }
      kind={BUTTON_KIND.SECONDARY}
      size={BUTTON_SIZE.SMALL}
    />
  );
}

export default function ThirdPartyLoginButtons() {
  const services = getEnabledButtons();

  if (services.length === 0) return null;

  return (
    <div>
      <OrSeparator className="pb-8" />
      <div className="flex min-h-[8rem] flex-col items-center space-y-4">
        {services.map(service => {
          switch (service) {
            case 'apple':
              return <AppleButton key={service} />;
            case 'google':
              return <GoogleButton key={service} />;
            case 'facebook':
              return (
                <SocialLoginButton
                  key={service}
                  socialName="Facebook"
                  socialImage="/icons/ic_facebook.png"
                  onClick={() => {
                    alert('Not Implemented yet');
                  }}
                />
              );
            case 'xero':
              return <XeroButton key={service} />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}
