import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { LoginOrSignUp } from '../LoginOrSignUp';
import { COMPONENT_NAME } from './types';

function LoginOrSignUpPopUp() {
  const router = useRouter();

  const onDismissPress = () => {
    router.push(
      { query: { ...router.query, loginModal: 'false' } },
      undefined,
      { shallow: true },
    );
  };

  return (
    <Transition show={router.query.loginModal === 'true'}>
      <Dialog onClose={onDismissPress} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="max-md:translate-y-full md:opacity-0 md:scale-105"
          enterTo="md:opacity-100 translate-y-0"
          leave="duration-300 ease-in"
          leaveFrom="translate-y-0 md:opacity-100"
          leaveTo="max-md:translate-y-full md:opacity-0 md:scale-105"
        >
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center max-md:items-end max-md:pt-4">
              <Dialog.Panel className="relative flex w-full max-w-4xl flex-col items-center justify-start overflow-y-auto rounded-t-3xl bg-white md:mx-4 md:my-4 md:justify-center md:rounded-[1rem] md:shadow-cardHover">
                {/* Small devices button */}
                <div
                  className={`flex w-full items-center justify-end overflow-hidden p-4 md:hidden`}
                >
                  <button
                    className="flex items-center justify-center p-1.5"
                    onClick={onDismissPress}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>

                {/* Larger devices button */}
                <div className={`absolute right-4 top-4 hidden md:flex`}>
                  <button
                    className="flex items-center justify-center p-1.5"
                    onClick={onDismissPress}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>

                <LoginOrSignUp isPopUp />
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

LoginOrSignUpPopUp.displayName = COMPONENT_NAME;

export default LoginOrSignUpPopUp;
