import { useRouter } from 'next/router';
import React from 'react';
const OrganisationIDContext = React.createContext<string | undefined>(
  undefined,
);

const OrganisationIDProvider = OrganisationIDContext.Provider;

function OrganisationIDRouteProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    query: { organisationId, selectedBadge },
  } = useRouter();

  return (
    <OrganisationIDProvider value={organisationId as string}>
      {children}
    </OrganisationIDProvider>
  );
}

function useOrganisationId() {
  return React.useContext(OrganisationIDContext);
}

export {
  useOrganisationId,
  OrganisationIDContext,
  OrganisationIDProvider,
  OrganisationIDRouteProvider,
};
