// export function urlIsAppScheme(url: string) {
//   return url.startsWith('greener://');
// }

export function safeRedirectUrl(
  urlString?: string,
  fallback: string | undefined = '/',
) {
  if (!urlString) return fallback;

  // Allow paths, because those are by definition on the same site
  if (urlString.startsWith('/')) {
    return urlString;
  }

  try {
    const url = new URL(urlString);

    // // Allow all app scheme urls
    // if (url.protocol === 'greener:') {
    //   return urlString;
    // }

    // Allow urls with same host name as the site
    if (
      url.host == process.env.NEXT_PUBLIC_SITE_URL ||
      url.host == process.env.NEXT_PUBLIC_VERCEL_URL
    ) {
      return urlString;
    }

    // Anything else, NOPE!
    return fallback;
  } catch {
    return fallback;
  }
}

// export function addSessionDataToAuthRedirectUrl(url: string, session: Session) {
//   const safeUrl = safeLoginRedirect(url);

//   if (urlIsAppScheme(safeUrl)) {
//     const updatedUrl = new URL(safeUrl);
//     const searchParams = updatedUrl.searchParams;
//     searchParams.set('access_token', session.access_token ?? '');
//     searchParams.set('refresh_token', session.refresh_token ?? '');
//     return updatedUrl.toString();
//   }

//   return safeUrl;
// }
