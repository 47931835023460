import { getPreviewTokenFromQueryString } from '@/helpers/craftCMS';
import { setActionLoading } from '@/lib/actions/state/dynamicSavingsSlice';
import api from '../api/api';
import {
  DataResponse,
  PagedDataResponse,
  PaginationParams,
} from '../api/types';
import { GreenerCategoryBase } from '../categories/types';
import { Supplier } from '../suppliers/types';
import {
  ActionFeedbackBody,
  CarbonProgress,
  ListActionsForOrganisationParams,
  OrganisationDynamicSavings,
  OrganisationSustainabilityAction,
  OrganisationSustainabilityActionCompletetionResult,
  OrganisationSustainabilityActionProgressChecklistResult,
  SustainabilityAction,
  SustainabilityActionCompletedOrganisation,
  SustainabilityActionContent,
  SustainabilityCategory,
  UpdateActionProgressChecklistParams,
  UpdateActionProgressParams,
} from './types';

function searchParamsforListActionsParams({
  progressStatus,
  progressStatuses,
  badgeGroup,
  badgeSlugs,
  hidden,
  sequence,
  effort,
  orderBy,
  categorySlugs,
}: ListActionsForOrganisationParams) {
  const params = new URLSearchParams();
  if (progressStatuses && Array.isArray(progressStatuses)) {
    progressStatuses.forEach(t => params.append('progress_statuses[]', t));
  } else if (progressStatus) {
    params.set('progress_status', progressStatus);
  }

  if (hidden !== undefined) {
    params.set('is_hidden', hidden.toString());
  }

  if (badgeGroup) {
    params.set('badge_group', badgeGroup);
  }

  if (badgeSlugs) {
    badgeSlugs.forEach(t => params.append('badge_slugs[]', t));
  }

  if (effort) {
    params.set('effort', effort);
  }

  if (sequence) {
    params.set('sequence', sequence);
  }

  if (orderBy) {
    params.set('order_by', orderBy);
  }

  if (categorySlugs) {
    categorySlugs.forEach(t => params.append('sustainability_categories[]', t));
  }

  return params;
}

const actionsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'OrganisationBadge',
      'OrganisationPoints',
      'SustainabilityCategory',
    ],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      // List actions for an organisation
      listActionsForOrganisation: builder.query<
        OrganisationSustainabilityAction[],
        ListActionsForOrganisationParams
      >({
        query: params => {
          const searchParams = searchParamsforListActionsParams(params);

          return {
            url: `/api/cso/organisations/${
              params.organisationId
            }/sustainability_actions?${searchParams.toString()}`,
          };
        },
        providesTags: (_response, _error, c) => {
          return [
            {
              type: 'OrganisationSustainabilityActions',
              id: `${c.organisationId}_${c.progressStatuses}_${c.hidden}`,
            },
          ];
        },
        transformResponse: (
          r: DataResponse<OrganisationSustainabilityAction[]>,
        ) => r.data,
        // load the dynamic savings for each action
        onQueryStarted: async (params, { queryFulfilled, dispatch }) => {
          try {
            const result = await queryFulfilled;
            const { organisationId } = params;
            const actionIds = result.data.map(a => a.id);

            if (actionIds.length > 0) {
              dispatch(
                actionsApi.endpoints.getDynamicSavingsForOrganisation.initiate(
                  {
                    organisationId,
                    actionIds: actionIds,
                    listingParams: params,
                  },
                  { forceRefetch: true },
                ),
              );
            }
          } catch (_) {}
        },
      }),

      // Get an action for an organisation
      getActionForOrganisation: builder.query<
        OrganisationSustainabilityAction,
        { actionIdOrSlug: string; organisationId: string }
      >({
        query: ({ organisationId, actionIdOrSlug }) => ({
          url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}`,
        }),
        providesTags: result => [
          { type: 'OrganisationSustainabilityAction', id: result?.id },
          { type: 'OrganisationSustainabilityAction', id: result?.slug },
        ],
        transformResponse: (
          r: DataResponse<OrganisationSustainabilityAction>,
        ) => r.data,
      }),
      getDynamicSavingsForOrganisation: builder.query<
        OrganisationDynamicSavings[],
        {
          actionIds: string[];
          organisationId: string;
          listingParams: ListActionsForOrganisationParams;
        }
      >({
        query: ({ organisationId, actionIds }) => ({
          url: `/api/cso/organisations/${organisationId}/sustainability_actions/dynamic_savings?action_ids=${actionIds.join()}`,
        }),
        extraOptions: { timeout: 30000 },
        transformResponse: (r: DataResponse<OrganisationDynamicSavings[]>) =>
          r.data,
        onQueryStarted: async (
          { listingParams, actionIds },
          { queryFulfilled, dispatch },
        ) => {
          try {
            actionIds.forEach(actionId => {
              dispatch(setActionLoading({ actionId, loading: true }));
            });
            const result = await queryFulfilled;
            actionIds.forEach(actionId => {
              dispatch(setActionLoading({ actionId, loading: false }));
            }); // Update the listActionsForOrganisation with the result from dynamic savings value
            dispatch(
              actionsApi.util.updateQueryData(
                'listActionsForOrganisation',
                listingParams,
                draft => {
                  // Update the listActionsForOrganisation with the result from dynamic savings value
                  result.data.forEach(dynamicSavings => {
                    const index = draft?.findIndex(a => {
                      return a.id == dynamicSavings.id;
                    });

                    if (index !== -1) {
                      draft[index] = {
                        ...draft[index],
                        ...dynamicSavings,
                      };
                    }
                  });
                },
              ),
            );
          } catch (_) {}
        },
      }),
      // Mark an action as completed
      updateActionProgressForOrganisation: builder.mutation<
        OrganisationSustainabilityActionCompletetionResult,
        UpdateActionProgressParams
      >({
        query: ({ organisationId, actionIdOrSlug, method, hide }) => {
          return {
            url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}/progress/${method}`,
            method: 'POST',
            body: {
              hide,
            },
          };
        },
        invalidatesTags: (_result, _error, arg) => {
          return [
            // This is the cache for most of the actions
            {
              type: 'OrganisationSustainabilityActions' as const,
              id: `${arg.organisationId}_undefined_${arg?.hide?.is_hidden}`,
            },
            {
              type: 'OrganisationSustainabilityActions' as const,
              id: `${arg.organisationId}_undefined_undefined`,
            },
            {
              type: 'OrganisationSustainabilityAction',
              id: arg.actionIdOrSlug,
            },
            'OrganisationBadge',
            'OrganisationPoints',
            'SustainabilityCategory',
          ];
        },
        transformResponse: (
          r: DataResponse<OrganisationSustainabilityActionCompletetionResult>,
        ) => r.data,
      }),
      // Get progress checklist
      getActionProgressChecklistForOrganisation: builder.query<
        OrganisationSustainabilityActionProgressChecklistResult,
        { organisationId: string; actionIdOrSlug: string }
      >({
        query: ({ organisationId, actionIdOrSlug }) => {
          return {
            url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}/progress/show_checklist`,
          };
        },
        transformResponse: (
          r: DataResponse<OrganisationSustainabilityActionProgressChecklistResult>,
        ) => r.data,
      }),
      // Update progress checklist
      updateActionProgressChecklistForOrganisation: builder.mutation<
        OrganisationSustainabilityActionProgressChecklistResult,
        UpdateActionProgressChecklistParams
      >({
        query: ({ organisationId, actionIdOrSlug, checklist }) => {
          return {
            url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}/progress/update_checklist`,
            method: 'POST',
            body: {
              checklist,
            },
          };
        },
        invalidatesTags: (_result, _error, _arg) => {
          return [
            'OrganisationBadge',
            'OrganisationPoints',
            'SustainabilityCategory',
          ];
        },
        transformResponse: (
          r: DataResponse<OrganisationSustainabilityActionProgressChecklistResult>,
        ) => r.data,
      }),
      // Update action feedback
      updateActionFeedback: builder.mutation<
        void,
        ActionFeedbackBody & {
          organisationId: string;
          actionIdOrSlug: string;
        }
      >({
        query: ({ organisationId, actionIdOrSlug, feedback }) => {
          return {
            url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}/feedback`,
            method: 'PUT',
            body: {
              feedback,
            },
          };
        },
        invalidatesTags: (_result, _error, arg) => {
          return [
            {
              type: 'OrganisationSustainabilityAction',
              id: arg.actionIdOrSlug,
            },
            // These are the caching for the list of actions
            {
              type: 'OrganisationSustainabilityActions' as const,
              id: `${arg.organisationId}_undefined_${arg?.feedback?.is_hidden}`,
            },
            {
              type: 'OrganisationSustainabilityActions' as const,
              id: `${arg.organisationId}_undefined_undefined`,
            },
            'OrganisationBadge',
            'OrganisationPoints',
            'SustainabilityCategory',
          ];
        },
        transformResponse: (r: DataResponse<void>) => r.data,
      }),
      // Get sustainability action categories
      ListSustainabiltyActionsCategories: builder.query<
        SustainabilityCategory[],
        ListActionsForOrganisationParams
      >({
        query: params => {
          const searchParams = searchParamsforListActionsParams(params);

          return {
            url: `/api/cso/organisations/${
              params.organisationId
            }/sustainability_actions_categories?${searchParams.toString()}`,
          };
        },
        providesTags: ['SustainabilityCategory'],
        transformResponse: (r: DataResponse<GreenerCategoryBase[]>) => r.data,
      }),
      getSustainabilityAction: builder.query<
        SustainabilityAction,
        { idOrSlug: string }
      >({
        query: ({ idOrSlug }) => ({
          url: `/api/cso/sustainability_actions/${idOrSlug}`,
        }),
        transformResponse: (r: DataResponse<SustainabilityAction>) => r.data,
      }),
      listSustainabilityActionSuppliers: builder.query<
        Supplier[],
        { idOrSlug: string; tags?: string[]; variation?: string }
      >({
        query: ({ idOrSlug, tags, variation }) => {
          const searchParams = new URLSearchParams();

          tags?.forEach(tag => searchParams.append('supplier_tags[]', tag));

          if (variation) {
            searchParams.set('variation', variation);
          }

          return {
            url: `/api/cso/sustainability_actions/${idOrSlug}/suppliers?${searchParams.toString()}`,
          };
        },
        transformResponse: (r: DataResponse<Supplier[]>) => r.data,
      }),
      getSustainabilityActionContent: builder.query<
        SustainabilityActionContent,
        { idOrSlug: string }
      >({
        query: ({ idOrSlug }) => {
          let token = getPreviewTokenFromQueryString();
          const params = new URLSearchParams();
          params.set('site[]', 'greener_business');
          if (token) {
            params.set('token', token);
          }
          return {
            url: `/api/cso/sustainability_actions/${idOrSlug}/content?${params.toString()}`,
          };
        },
        transformResponse: (r: DataResponse<SustainabilityActionContent>) =>
          r.data,
      }),
      getSustainabilityActionContentForOrganisation: builder.query<
        SustainabilityActionContent,
        { actionIdOrSlug: string; organisationId: string }
      >({
        query: ({ organisationId, actionIdOrSlug }) => {
          let token = getPreviewTokenFromQueryString();
          const params = new URLSearchParams();
          params.set('site[]', 'greener_business');
          if (token) {
            params.set('token', token);
          }
          return {
            url: `/api/cso/organisations/${organisationId}/sustainability_actions/${actionIdOrSlug}/content?${params.toString()}`,
          };
        },
        transformResponse: (r: DataResponse<SustainabilityActionContent>) =>
          r.data,
      }),

      paginateOrganisationsForAction: builder.query<
        PagedDataResponse<SustainabilityActionCompletedOrganisation[]>,
        {
          actionIdOrSlug: string;
          organisationId?: string;
          paginationParams?: PaginationParams;
        }
      >({
        query: ({ actionIdOrSlug, organisationId, paginationParams: page }) => {
          const params = new URLSearchParams();
          if (page?.page) {
            params.append('page', page.page.toString());
          }
          if (page?.page_size) {
            params.append('page_size', page.page_size.toString());
          }

          if (organisationId) {
            params.append('organisation_id', organisationId);
          }

          return {
            url: `/api/cso/sustainability_actions/${actionIdOrSlug}/organisations?${params.toString()}`,
          };
        },
      }),
      getCarbonFootprintProgress: builder.query<CarbonProgress, void>({
        query: () => {
          return {
            url: `/api/current_user/footprint/carbon_footprint_progress?version=2`,
            method: 'get',
          };
        },
        transformResponse: (r: DataResponse<CarbonProgress>) => r.data,
        providesTags: ['OrganisationSustainabilityAction'],
      }),
    }),
  });

export const {
  useGetActionForOrganisationQuery,
  useLazyGetActionForOrganisationQuery,
  useListActionsForOrganisationQuery,
  useUpdateActionProgressForOrganisationMutation,
  useGetActionProgressChecklistForOrganisationQuery,
  useUpdateActionProgressChecklistForOrganisationMutation,
  useUpdateActionFeedbackMutation,
  useListSustainabiltyActionsCategoriesQuery,
  useGetSustainabilityActionQuery,
  useGetSustainabilityActionContentQuery,
  useGetSustainabilityActionContentForOrganisationQuery,
  useListSustainabilityActionSuppliersQuery,
  usePaginateOrganisationsForActionQuery,
  useGetCarbonFootprintProgressQuery,
  usePrefetch,
} = actionsApi;
