import { useListActionsForOrganisationQuery } from '@/lib/actions/api';
import { ORGANISATION_REFETCH_TIME } from '@/lib/api/types';
import { useGetMultipleBadgesForOrganisationQuery } from '@/lib/badges/api';
import { BadgeWithEarnedValue } from '@/lib/badges/types';
import GenericShare from '@/lib/sharing/components/GenericShare';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { type EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import truncate from 'lodash/truncate';
import moment from 'moment';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import ReactConfetti from 'react-confetti';
import Skeleton from 'react-loading-skeleton';
import CardActionContentListMode from '../Card/CardActionContentListMode';
import CarouselButton from '../Carousel/CarouselButton';
import Spinner from '../Icons/Spinner';
import { InlineLoadError } from '../InlineLoadError';

function EarnedBadgesCarousel({ badges }: { badges: BadgeWithEarnedValue[] }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'center',
  });

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

  const onScrollPrev = React.useCallback(() => {
    // emblaApi?.scrollPrev();
    // ScrolLPrev() wont loop if theres not enough items to fit, but we want it to
    let targetIndex = selectedIndex - 1;
    if (targetIndex < 0) {
      targetIndex = badges.length - 1;
    }

    emblaApi?.scrollTo(targetIndex);
  }, [emblaApi, badges.length, selectedIndex]);

  const onScrollNext = React.useCallback(() => {
    // emblaApi?.scrollNext();
    // scrollNext() wont loop if theres not enough items to fit but we want it to...
    emblaApi?.scrollTo((selectedIndex + 1) % badges.length);
  }, [emblaApi, badges.length, selectedIndex]);

  const onInit = React.useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  React.useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);

    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  // Reset scroll position on items change
  React.useEffect(() => {
    emblaApi?.scrollTo(0);
    setSelectedIndex(0);
  }, [emblaApi, badges]);

  return (
    <div
      className="flex w-full touch-auto flex-col space-y-6 overflow-hidden"
      ref={emblaRef}
    >
      <div className="box-content flex w-full">
        {badges.map(badge => {
          return (
            <div
              key={badge.id}
              className="flex flex-[0_0_100%]  flex-col items-center space-y-6"
            >
              <div className="relative h-[7.125rem] w-[7.125rem]">
                <Image
                  src={
                    badge.is_earned
                      ? badge.icon.url
                      : (badge.inactive_icon?.url ?? badge.icon?.url)
                  }
                  unoptimized
                  layout="fill"
                  objectFit="contain"
                  alt={`Icon for ${badge.title} badge`}
                />
              </div>

              <div className="space-y-2 ">
                {badge.is_earned ? (
                  <h1 className="h9 text-center uppercase">
                    Achievement unlocked
                  </h1>
                ) : null}
                <h2 className="h2 text-center">{badge.title}</h2>
                <p className="p2 text-center">
                  {badge.is_earned
                    ? badge.business_description_complete
                    : badge.business_description}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {scrollSnaps.length > 1 ? (
        <div className="flex flex-col items-center space-y-2">
          <div className="h7">
            {selectedIndex + 1} of {scrollSnaps.length}
          </div>
          <div className="flex items-center justify-center space-x-2.5 py-1">
            <CarouselButton
              direction="previous"
              onPress={onScrollPrev}
              disabled={selectedIndex == 0}
            />
            <CarouselButton
              direction="next"
              onPress={onScrollNext}
              disabled={selectedIndex == scrollSnaps.length - 1}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default function EarnedBadgeModal({
  badgeSlug,
  onClose,
}: {
  badgeSlug?: string[];
  onClose?: () => void;
}) {
  const router = useRouter();
  const routerSelectedBadge = router.query.selectedBadge
    ? [router.query.selectedBadge as string]
    : undefined;

  const slugs: string[] | undefined =
    badgeSlug || routerSelectedBadge || undefined;

  const {
    data: badges,
    isLoading: isLoadingBadge,
    isFetching: isFetchingBadge,
    isError: isErrorBadge,
    refetch,
  } = useGetMultipleBadgesForOrganisationQuery(
    router.query.organisationId && slugs && slugs.length > 0
      ? {
          organisationId: router.query.organisationId as string,
          badgesIdorSlug: slugs,
        }
      : skipToken,
    { refetchOnMountOrArgChange: false },
  );

  const hideShearing = () => {
    let onboardingTime;
    try {
      onboardingTime = localStorage.getItem('onboarding_completed_time');
    } catch {}

    if (!onboardingTime) {
      return false;
    }

    const now = moment(moment().valueOf());
    const onboarding = moment(+onboardingTime);

    const timeDiff = now.diff(onboarding, 'minutes');
    return timeDiff < 2;
  };

  const onCloseRequested = React.useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }

    delete router.query.selectedBadge;
    router.replace(
      { pathname: router.pathname, query: router.query },
      undefined,
      { shallow: true },
    );
  }, [onClose, router]);

  // Lets use the sharing hiding function to disable te earned badge post onboarding
  if (hideShearing()) {
    return null;
  }

  if (isLoadingBadge || isFetchingBadge || isErrorBadge) {
    return (
      <Transition show appear>
        <Dialog
          open={true}
          onClose={onCloseRequested}
          className="relative  z-50"
        >
          <Transition.Child
            className="fixed bottom-0 left-0 right-0 top-0"
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="duration-300 ease-out"
            enterFrom="max-md:translate-y-full md:opacity-0 md:scale-105"
            enterTo="md:opacity-100 translate-y-0"
            leave="duration-300 ease-in"
            leaveFrom="translate-y-0 md:opacity-100"
            leaveTo="max-md:translate-y-full md:opacity-0 md:scale-105"
          >
            {/* Full-screen scrollable container */}
            <div className="fixed inset-0 w-screen overflow-y-auto">
              {/* Container to center the panel */}
              <div className="flex min-h-full items-center justify-center max-md:items-end max-md:pt-14">
                <Dialog.Panel className="relative flex w-full max-w-3xl flex-col items-center justify-start overflow-y-auto rounded-t-3xl bg-white md:mx-4 md:my-4 md:justify-center md:rounded-[1rem] md:shadow-cardHover">
                  {/* Small devices button */}
                  <div
                    className={`flex w-full items-center justify-end overflow-hidden p-4 md:hidden`}
                  >
                    <button
                      className="flex items-center justify-center p-1.5"
                      onClick={onCloseRequested}
                    >
                      <XIcon className="h-6 w-6" />
                    </button>
                  </div>

                  {/* Larger devices button */}
                  <div className={`absolute right-4 top-4 hidden md:flex`}>
                    <button
                      className="flex items-center justify-center p-1.5"
                      onClick={onCloseRequested}
                    >
                      <XIcon className="h-6 w-6" />
                    </button>
                  </div>

                  <div className="p-4 md:p-10">
                    <div className="mb-8 flex flex-col items-center">
                      <Skeleton className="relative mb-7.5 h-36 w-36 rounded-full" />
                      {isErrorBadge ? (
                        <InlineLoadError
                          message="Error loading badge"
                          isError={!isErrorBadge}
                          isLoading={isLoadingBadge}
                          refetch={refetch}
                        />
                      ) : null}
                      {!isErrorBadge ? (
                        <>
                          <Skeleton className="mb-3 h-5 w-[25rem]" />
                          <Skeleton className="h-5 w-[25rem]" />{' '}
                        </>
                      ) : null}
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    );
  }
  if (!badges || !slugs) {
    return null;
  }

  return (
    <Transition show={slugs.length > 0} appear>
      <Dialog open={true} onClose={onCloseRequested} className="relative z-50">
        <Transition.Child
          className="fixed bottom-0 left-0 right-0 top-0"
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />

          {/* Confetti if earned... */}
          {badges[0].is_earned ? (
            <ReactConfetti
              className="fixed left-0 right-0 top-0"
              colors={['#0F7D69', '#149072', '#19A47A', '#3ABC8E', '#5BD5A2']}
            />
          ) : null}
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="max-md:translate-y-full md:opacity-0 md:scale-105"
          enterTo="md:opacity-100 translate-y-0"
          leave="duration-300 ease-in"
          leaveFrom="translate-y-0 md:opacity-100"
          leaveTo="max-md:translate-y-full md:opacity-0 md:scale-105"
        >
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center max-md:items-end max-md:pt-14">
              <Dialog.Panel className="relative flex w-full max-w-3xl flex-col items-center justify-start overflow-y-auto rounded-t-[1.25rem] bg-white md:mx-4 md:my-4 md:justify-center md:rounded-[1.25rem] md:shadow-cardHover">
                {/* Larger devices button */}
                <div
                  className={`flex w-full items-center justify-end overflow-hidden p-4`}
                >
                  <button
                    className="flex items-center justify-center"
                    onClick={onCloseRequested}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>

                {/* Content */}
                <div className="space-y-10 p-4 pt-0 md:p-10">
                  <EarnedBadgesCarousel badges={badges} />
                  {/* Earned badge has share section */}
                  {badges[0]?.is_earned && !hideShearing() ? (
                    <GenericShare />
                  ) : null}
                </div>

                {/* Unearned badge has action list */}
                {badges[0].badge_group !== 'making_progress' &&
                !badges[0].is_earned ? (
                  <EarnedBadgeModalActionList
                    slug={badges[0].slug}
                    onClose={onCloseRequested}
                  />
                ) : null}

                {/* Its milestone */}
                {badges[0].badge_group === 'making_progress' &&
                !badges[0].is_earned ? (
                  <div className="p5 mb-8 text-gray-700">
                    {truncate(badges[0].short_description || '', {
                      length: 120,
                      omission: '...',
                    })}
                  </div>
                ) : null}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

function EarnedBadgeModalActionList({
  slug,
  onClose,
}: {
  slug: string;
  onClose: () => void;
}) {
  const {
    query: { organisationId },
  } = useRouter();

  const { data: actions, isLoading } = useListActionsForOrganisationQuery(
    organisationId
      ? {
          organisationId: organisationId as string,
          badgeSlugs: [slug],
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: ORGANISATION_REFETCH_TIME,
      selectFromResult: r => ({
        ...r,
        data: r.data?.slice(0, 1),
      }),
    },
  );

  return (
    <div className="mb-8 w-full px-8">
      <h5 className="text-center">
        {(actions?.length ?? 0) > 1
          ? 'Earn this badge by completing the following actions:'
          : 'Earn this badge by completing the following action:'}
      </h5>

      {isLoading ? (
        <div className="mt-5 flex items-center justify-center">
          <Spinner />
        </div>
      ) : null}
      {!isLoading && actions ? (
        <div className="">
          {actions.map(item => {
            return (
              <div key={item.id}>
                <CardActionContentListMode
                  containerStyle="mt-5"
                  {...{
                    ...item,
                    image: item.hero_images[0],
                    href: `/organisations/${organisationId}/actions/${
                      item.parent_slug ?? item.slug
                    }`,
                    action: {
                      ...item,
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
