import { sendSegementNavButtonClicked } from '@/helpers/analytics';
import { useEndSessionMutation } from '@/lib/auth/api';
import { clearSessionData } from '@/lib/auth/sessionSlice';
import { useGetOrganisationQuery } from '@/lib/organisations/api';
import { useAppDispatch } from '@/lib/store/hooks';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, MenuIcon } from '@heroicons/react/solid';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Image, { StaticImageData } from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import BetaFeatureBadge from './BetaFeatureBadge';
import { IHeaderMenuItem } from './types';

function Badge({ count, className }: { count?: number; className?: string }) {
  if (!count) return null;

  return (
    <div className={className}>
      <div className="h-3 w-3 rounded-full bg-red-600" />
    </div>
  );
}

function HeaderNav({
  items,
  navItems,
  userName,
  userProfileImage,
  badge,
}: {
  items?: IHeaderMenuItem[];
  navItems: IHeaderMenuItem[];
  userName: string;
  userProfileImage: string | StaticImageData;
  badge?: number;
}) {
  const router = useRouter();

  const organisationId = React.useMemo(() => {
    if (!router.isReady) return;

    if (typeof router.query.organisationId === 'string') {
      return router.query.organisationId;
    }
    return localStorage.getItem('organisationId') ?? undefined;
  }, [router.isReady, router.query.organisationId]);

  const { data: organisation } = useGetOrganisationQuery(
    organisationId ? { id: organisationId } : skipToken,
  );
  const [endSession] = useEndSessionMutation();
  const dispatch = useAppDispatch();

  const onLogoutClicked = async () => {
    try {
      await endSession();
      await router.replace('/session/signing_out');
      await dispatch(clearSessionData());
      await router.replace('/');
    } catch (e) {
      router.replace('/');
    }
  };

  if (!navItems) return null;

  return (
    <div className="block">
      <div className="flex items-center overflow-hidden">
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button className="group inline-flex h-16 items-center text-left transition-all focus:outline-none md:h-18">
                <div className="hidden items-center lg:flex">
                  <div className="relative">
                    <div className="rounded-full border border-gray-300">
                      <div className="relative h-8 w-8 overflow-hidden rounded-full border border-white">
                        <Image
                          width={32}
                          height={32}
                          src={userProfileImage}
                          alt="Logged user image"
                          className="block"
                        />
                      </div>
                    </div>
                    <Badge
                      className="absolute bottom-0 right-0"
                      count={badge}
                    />
                  </div>
                  <div className="ml-2 flex flex-col justify-center">
                    {userName ? (
                      <p className="h8 fs-exclude">{userName}</p>
                    ) : null}
                    {organisation?.name ? (
                      <p className="p7 fs-exclude text-gray-700">
                        {organisation.name}
                      </p>
                    ) : null}
                  </div>
                  <div className="ml-2.5">
                    <ChevronDownIcon className="h-5 w-5 text-gray-700" />
                  </div>
                </div>
                <div className="flex items-center lg:hidden">
                  <div className="relative p-4">
                    <MenuIcon className="h-6 w-6 text-gray-900" />
                    <Badge
                      className="absolute bottom-3 right-3"
                      count={badge}
                    />
                  </div>
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-0.5 w-80 transform bg-white">
                  <div className="overflow-hidden rounded-0.5xl rounded-tl-none rounded-tr-none p-5 shadow-card">
                    <div className="relative mb-4 flex flex-col space-y-4 bg-white lg:hidden">
                      {items &&
                        items.map(item => {
                          if (item.hidden) return null;
                          if (item.href) {
                            return (
                              <Link
                                href={item.href}
                                key={item.id}
                                legacyBehavior
                              >
                                <button
                                  onClick={() => {
                                    if (item.href) {
                                      sendSegementNavButtonClicked(
                                        item.name,
                                        item.name,
                                        item.href,
                                      );
                                    }
                                    close();
                                  }}
                                  className="appearance-none whitespace-nowrap text-left font-sans-semibold text-[0.9375rem] outline-none transition duration-150 ease-in-out hover:text-green-500 focus:outline-none"
                                >
                                  <div className="flex items-center gap-x-1">
                                    {item.name}
                                    {item.beta ? <BetaFeatureBadge /> : null}
                                  </div>
                                </button>
                              </Link>
                            );
                          }

                          if (item.subItems) {
                            return (
                              <div
                                className="flex flex-col space-y-4 border-t border-gray-300 pt-4"
                                key={item.id}
                              >
                                <div className="h8 text-gray-700">
                                  {item.name}
                                </div>
                                {item.subItems.map(subItem => {
                                  if (subItem.href) {
                                    return (
                                      <>
                                        <Link
                                          href={subItem.href as string}
                                          key={subItem.id}
                                          legacyBehavior
                                        >
                                          <button
                                            onClick={() => close()}
                                            className="appearance-none whitespace-nowrap text-left font-sans-semibold text-[0.9375rem] outline-none transition duration-150 ease-in-out hover:text-green-500 focus:outline-none"
                                          >
                                            {subItem.name}
                                          </button>
                                        </Link>
                                      </>
                                    );
                                  }
                                  return (
                                    <span
                                      className="font-sans text-gray-600"
                                      key={subItem.id}
                                    >
                                      {subItem.name}
                                    </span>
                                  );
                                })}
                              </div>
                            );
                          }
                        })}
                    </div>
                    <div className="relative flex flex-col space-y-4 bg-white">
                      {navItems &&
                        navItems.map(item => {
                          if (item.hidden) return null;
                          if (item.href) {
                            return (
                              <Link
                                href={item.href}
                                key={item.id}
                                legacyBehavior
                              >
                                <button
                                  onClick={() => close()}
                                  className="appearance-none whitespace-nowrap text-left font-sans-semibold text-[0.9375rem] outline-none transition duration-150 ease-in-out hover:text-green-500 focus:outline-none"
                                >
                                  {item.name}
                                </button>
                              </Link>
                            );
                          }

                          if (item.subItems) {
                            return (
                              <div
                                key={item.id}
                                className="flex flex-col space-y-4 border-t border-gray-300 pt-4 lg:border-none lg:pt-0"
                              >
                                {/* <div className="h7 text-gray-700">
                                  {item.name}
                                </div> */}
                                {item.subItems.map(subItem => {
                                  if (subItem.isLogout) {
                                    return (
                                      <button
                                        key={subItem.id}
                                        onClick={onLogoutClicked}
                                        className="appearance-none whitespace-nowrap text-left font-sans-semibold text-sm outline-none transition duration-150 ease-in-out hover:text-green-500"
                                      >
                                        {subItem.name}
                                      </button>
                                    );
                                  } else if (subItem.href) {
                                    return (
                                      <React.Fragment key={subItem.id}>
                                        {subItem.break ? (
                                          <hr className="border-t border-gray-300" />
                                        ) : undefined}
                                        <Link
                                          href={subItem.href as string}
                                          key={subItem.id}
                                          legacyBehavior
                                        >
                                          <button
                                            onClick={() => {
                                              sendSegementNavButtonClicked(
                                                subItem.name,
                                                subItem.name,
                                                subItem.href,
                                              );
                                              close();
                                            }}
                                            className="flex appearance-none flex-row items-center whitespace-nowrap text-left font-sans-semibold text-sm outline-none transition duration-150 ease-in-out hover:text-green-500 focus:outline-none"
                                          >
                                            {subItem.name}
                                            <Badge
                                              count={subItem.badge}
                                              className="ml-2"
                                            />
                                          </button>
                                        </Link>
                                      </React.Fragment>
                                    );
                                  }
                                  return (
                                    <span
                                      className="font-sans text-gray-600"
                                      key={subItem.id}
                                    >
                                      {subItem.name}
                                    </span>
                                  );
                                })}
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

HeaderNav.displayName = 'HeaderNav';

export default HeaderNav;
