import { sendSegementNavButtonClicked } from '@/helpers/analytics';
import classNames from '@/helpers/classNames';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import BetaFeatureBadge from './BetaFeatureBadge';
import { IHeaderMenuItem } from './types';

function HeaderMenu({ items }: { items?: IHeaderMenuItem[] }) {
  const router = useRouter();

  if (!items) return null;

  return (
    <div className="hidden lg:block">
      <div className="flex items-center">
        {items.map(item => {
          if (item.hidden) return;
          return (
            <Popover className="relative" key={item.id}>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      'h7 group mx-4 mb-0.5 inline-flex items-center border-b-2 py-6.5 text-gray-900 outline-none transition-all hover:border-green-500 hover:text-green-500',
                      router.asPath === item.href
                        ? 'border-green-500 text-green-500'
                        : 'border-transparent',
                    )}
                    onClick={() => {
                      if (item.href) {
                        sendSegementNavButtonClicked(
                          item.name,
                          item.name,
                          item.href,
                        );
                        router.push(item.href);
                      }
                    }}
                  >
                    <div className="flex items-center gap-x-1">
                      <span
                        className={classNames(
                          'text-sm',
                          router.asPath === item.href ? 'text-green-500' : '',
                        )}
                      >
                        {item.name}
                      </span>

                      {item.beta ? <BetaFeatureBadge /> : null}
                    </div>
                    {item && item.subItems && (
                      <ChevronDownIcon
                        className={classNames(
                          'ml-1 h-4 w-4 transform transition-all',
                          open ? 'rotate-180' : '',
                        )}
                        aria-hidden="true"
                      />
                    )}
                  </Popover.Button>
                  {item && item.subItems && (
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-auto max-w-sm -translate-x-1/2 transform">
                        <div className="overflow-hidden rounded-0.5xl bg-white p-5 shadow-navigation">
                          <div className="relative flex flex-col space-y-5">
                            {item.subItems.map(subItem => {
                              if (subItem.href) {
                                return (
                                  <Link
                                    href={subItem.href as string}
                                    key={subItem.id}
                                    passHref
                                    className="whitespace-nowrap font-sans-semibold text-[0.9375rem] transition duration-150 ease-in-out hover:text-green-500 focus:outline-none"
                                  >
                                    {subItem.name}
                                  </Link>
                                );
                              }

                              return <h5 key={item.id}>{item.name}</h5>;
                            })}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
          );
        })}
      </div>
    </div>
  );
}

HeaderMenu.displayName = 'HeaderMenu';

export default HeaderMenu;
