export default function ImpactLeaf({
  className,
  filled = false,
}: {
  className?: string;
  filled?: boolean;
}) {
  return (
    <svg className={className} fill="none" viewBox="0 0 16 16">
      <path
        fill={filled ? '#19A47A' : '#0000001A'}
        d="M15.504 1.119c-.435-.125-1.057.65-3.572 1.545-2.514.895-6.139-.189-8.555 2.35-1.583 1.662-1.296 3.696-1.042 5.27.705-.71 2.308-2.049 6.293-3.242C12.013 6.028 13.97 3.5 13.97 3.5s-1.238 3.02-5.117 4.314C3.93 9.458 2.16 11.843 2.16 11.846.875 13.128.356 14.217.54 14.618c.296.645 1.548.858 1.925.237.173-.285.78-1.13 1.591-2.086 2.238.752 6.297 1.574 9.603-2.16 2.003-2.263 2.28-9.366 1.845-9.49Z"
      />
    </svg>
  );
}
