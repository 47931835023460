import { useUpdateActionFeedbackMutation } from '@/lib/actions/api';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../Button';
import { BUTTON_KIND } from '../Button/Button';
import { InputSelect } from '../InputSelect';

import { handleFormSubmitException } from '@/lib/forms/helper/validation';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import * as Yup from 'yup';
import { FormInputError } from '../FormInputError';

const MAX_FEEDBACK_LENGTH = 2000;

type FormData = {
  reason: 'not_relevant' | 'unable' | 'other';
  feedback: string;
};

const schema = Yup.object({
  reason: Yup.string().required('Please select a reason'),
  feedback: Yup.string()
    .required('Please describe why this action is not relevant to you')
    .max(
      MAX_FEEDBACK_LENGTH,
      `Your feedback must be less than ${MAX_FEEDBACK_LENGTH} characters`,
    ),
});

const Reasons = [
  {
    value: 'not_relevant',
    label: 'This is not relevant to me',
  },
  {
    value: 'unable',
    label: 'I’m unable to take this action',
  },
  {
    value: 'other',
    label: 'Other reason',
  },
];

export default function ActionFeedbackModalDialog({
  isOpen,
  actionIdOrSlug,
  setIsOpen,
  setIsFeedbackSent,
}: {
  isOpen: boolean;
  actionIdOrSlug?: string;
  setIsOpen: (open: boolean) => void;
  setIsFeedbackSent?: (sent: boolean) => void;
}) {
  const {
    query: { organisationId, actionId },
    back,
  } = useRouter();

  const form = useForm<FormData>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      feedback: '',
    },
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    watch,
  } = form;

  const feedbackValue = watch('feedback');

  const [updateFeedback, { isLoading }] = useUpdateActionFeedbackMutation();

  const onSubmit = handleSubmit(async (data, e) => {
    if (!organisationId) return;

    try {
      await updateFeedback({
        actionIdOrSlug: actionIdOrSlug || '',
        organisationId: organisationId as string,
        feedback: {
          is_hidden: true,
          user_vote: false,
          user_vote_reason: data.reason,
          user_vote_feedback: data.feedback,
        },
      }).unwrap();

      setIsOpen(false);
      setIsFeedbackSent?.(true);
    } catch (e) {
      handleFormSubmitException(e, setError);
    }
  });

  const onClose = () => {
    if (isLoading) return;
    setIsOpen(false);
  };

  // Reset the form when we close
  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const feedbackLength = feedbackValue.length || 0;

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50 m-1">
      <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
      <div className="fixed inset-4 flex items-center justify-center">
        <Dialog.Panel className="w-[32rem] max-w-full rounded-[1rem] bg-white p-4 shadow-cardHover md:p-6 xl:p-8">
          <div className="mb-2 flex w-full justify-end">
            <button className="p-1.5" onClick={onClose}>
              <XIcon className="h-4 w-4" />
            </button>
          </div>
          <Dialog.Title as="h4" className="mb-2">
            Mark action as not relevant?
          </Dialog.Title>
          <Dialog.Description as="p" className="p4 mb-6 text-gray-700">
            Tell us why and we’ll use this information to improve your
            recommendations
          </Dialog.Description>
          <div className="mb-6">
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <>
                  <InputSelect items={Reasons} {...field} />
                  <FormInputError errors={errors.reason} />
                </>
              )}
            />
          </div>
          <div className="mb-6">
            <label
              className={classNames('h5 mb-3 block', {
                'text-red-800': errors.feedback,
              })}
              htmlFor="feedback"
            >
              Please tell us why{' '}
              <span className="font-sans text-gray-700">(required)</span>
            </label>
            <textarea
              className="block w-full flex-1 appearance-none rounded-0.5xl border border-gray-400 bg-white px-3 pb-2 pt-6 text-base leading-5 text-gray-900 placeholder-gray-700 transition-all placeholder-shown:pb-4 placeholder-shown:pt-4 focus:border-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-900 disabled:cursor-not-allowed disabled:bg-gray-200"
              placeholder="Describe the reason"
              rows={3}
              {...register('feedback')}
            />
            <div className="my-2 block text-right text-xs text-gray-600">
              {feedbackLength} / {MAX_FEEDBACK_LENGTH}
            </div>
            <FormInputError errors={errors.feedback} />
          </div>
          <div className="flex w-full items-center justify-end space-x-2 py-2">
            <Button
              kind={BUTTON_KIND.SECONDARY}
              title="Cancel"
              disabled={isLoading}
              onClick={() => setIsOpen(false)}
            />
            <Button
              title="Continue"
              disabled={isLoading}
              loading={isLoading}
              onClick={onSubmit}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
