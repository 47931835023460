import ImpactLeaf from '@/components/Icons/ImpactLeaf';
import classNames from 'classnames';
import { ActionImpact } from '../types';

function ActionHeaderImpactBubble({ isFilled }: { isFilled?: boolean }) {
  return <ImpactLeaf className="h-4 w-4" filled={isFilled} />;
}

export function ActionHeaderImpactBubbles({
  className,
  impact,
}: {
  className?: string;
  impact: ActionImpact | undefined;
}) {
  return (
    <div className={classNames('flex space-x-[0.125rem] py-1', className)}>
      <ActionHeaderImpactBubble
        isFilled={impact === 'low' || impact === 'medium' || impact === 'high'}
      />
      <ActionHeaderImpactBubble
        isFilled={impact === 'medium' || impact === 'high'}
      />
      <ActionHeaderImpactBubble isFilled={impact === 'high'} />
    </div>
  );
}
