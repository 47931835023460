import { AppState } from '@/lib/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MerchantDraft } from '../api/types';

interface AppListingEditorState {
  draftId?: string;
  title?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  short_description?: string;
  description?: string;
  shoppingOnline?: boolean;
  shoppingInStore?: boolean;
  logoUrl?: string;
  logoImageFileId?: string;
  heroImageUrl?: string;
  heroImageFileId?: string;

  // Are we creating a new merchant or editing an existing merchant
  update_mode: 'create' | 'edit';
  stepIndex: number;
}

const initialState: AppListingEditorState = {
  stepIndex: 0,
  update_mode: 'create',
};

export const appListingStateSlice = createSlice({
  name: 'AppListingEditor',
  initialState,
  reducers: {
    setInitialData: (
      state,
      action: PayloadAction<{ merchantDraft?: MerchantDraft }>,
    ) => {
      const {
        payload: { merchantDraft },
      } = action;
      state.stepIndex = 0;
      state.update_mode = merchantDraft?.merchant_id ? 'edit' : 'create';

      state.draftId = merchantDraft?.id ?? undefined;
      state.title = merchantDraft?.title;
      state.shoppingInStore =
        (merchantDraft?.shopping_types?.findIndex(f => f == 'in_store') ??
          -1) >= 0;
      state.shoppingOnline =
        (merchantDraft?.shopping_types?.findIndex(f => f == 'online') ?? -1) >=
        0;
      state.heroImageUrl = merchantDraft?.hero_image_urls?.[0];
      state.logoUrl = merchantDraft?.logo_image_url;
      state.description = merchantDraft?.description ?? undefined;
      state.short_description = merchantDraft?.short_description ?? undefined;
      state.facebookUrl = merchantDraft?.facebook_url ?? undefined;
      state.instagramUrl = merchantDraft?.instagram_url ?? undefined;
      state.websiteUrl = merchantDraft?.website_url ?? undefined;

      state.heroImageFileId = undefined;
      state.logoImageFileId = undefined;
    },

    // Basic info
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    updateWebsiteUrl: (state, action: PayloadAction<string>) => {
      state.websiteUrl = action.payload;
    },
    updateFacebookUrl: (state, action: PayloadAction<string>) => {
      state.facebookUrl = action.payload;
    },
    updateInstagramUrl: (state, action: PayloadAction<string>) => {
      state.instagramUrl = action.payload;
    },
    updateShoppingInStore: (state, action: PayloadAction<boolean>) => {
      state.shoppingInStore = action.payload;
    },
    updateShoppinOnline: (state, action: PayloadAction<boolean>) => {
      state.shoppingOnline = action.payload;
    },

    // Images
    updateHeroImage: (
      state,
      action: PayloadAction<{ imageId: string; url: string }>,
    ) => {
      state.heroImageUrl = action.payload.url;
      state.heroImageFileId = action.payload.imageId;
    },
    updateLogoImage: (
      state,
      action: PayloadAction<{ imageId: string; url: string }>,
    ) => {
      state.logoUrl = action.payload.url;
      state.logoImageFileId = action.payload.imageId;
    },
    // Headline and description
    updateHeadline: (state, action: PayloadAction<string>) => {
      state.short_description = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setStepIndex: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },
  },
});

export const {
  setInitialData,
  updateTitle,
  updateWebsiteUrl,
  updateFacebookUrl,
  updateInstagramUrl,
  updateShoppinOnline,
  updateShoppingInStore,
  updateLogoImage,
  updateHeroImage,
  updateHeadline,
  updateDescription,
  setStepIndex,
} = appListingStateSlice.actions;

export const selectAll = (state: AppState) => state.appListingEditor;
export const selectTitle = (state: AppState) => state.appListingEditor.title;
export const selectShortDescription = (state: AppState) =>
  state.appListingEditor.short_description;
export const selectWebsiteUrl = (state: AppState) =>
  state.appListingEditor.websiteUrl;
export const selectInstagramUrl = (state: AppState) =>
  state.appListingEditor.instagramUrl;
export const selectFacebookmUrl = (state: AppState) =>
  state.appListingEditor.facebookUrl;
export const selectDescription = (state: AppState) =>
  state.appListingEditor.description;
export const selectShoppingOnline = (state: AppState) =>
  state.appListingEditor.shoppingOnline;
export const selectShoppingInStore = (state: AppState) =>
  state.appListingEditor.shoppingInStore;
export const selectLogoUrl = (state: AppState) =>
  state.appListingEditor.logoUrl;
export const selectLogoImageFileId = (state: AppState) =>
  state.appListingEditor.logoImageFileId;
export const selectHeroImageUrl = (state: AppState) =>
  state.appListingEditor.heroImageUrl;
export const selectHeroImageFileId = (state: AppState) =>
  state.appListingEditor.heroImageFileId;
export const selectStepIndex = (state: AppState) =>
  state.appListingEditor.stepIndex;

export const selectUpdateMode = (state: AppState) =>
  state.appListingEditor.update_mode;

export default appListingStateSlice.reducer;
