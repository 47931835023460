import { IIcon } from './types';

function Planet({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M21.9989 42.4286C33.2813 42.4286 42.4275 33.2824 42.4275 22C42.4275 10.7176 33.2813 1.57141 21.9989 1.57141C10.7165 1.57141 1.57031 10.7176 1.57031 22C1.57031 33.2824 10.7165 42.4286 21.9989 42.4286Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52431 34.5621L6.5427 24.9816C6.15348 23.7283 7.46039 22.6177 8.63518 23.2105L16.441 27.1419C17.3015 27.5776 17.5301 28.7024 16.9052 29.438L12.0846 35.0906C11.3204 35.9761 9.87425 35.6797 9.52431 34.5621Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3479 18.215H27.5924C24.8072 18.215 22.5469 19.2148 22.5469 22C22.5469 22.8892 22.5469 24.1282 24.1109 24.9495C24.268 25.0316 24.443 25.0673 24.6072 25.1388L29.5635 27.3098L33.5164 34.4871C33.8235 35.4691 35.9517 36.9224 36.6694 36.219C39.8831 32.9053 41.9828 28.5025 42.3648 23.6212C42.3648 23.6176 41.8292 18.215 37.3479 18.215Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9213 14.2978C39.3572 10.4628 36.6613 7.20618 33.2476 4.953H33.0548C28.3449 4.953 24.5312 8.77019 24.5312 13.4801C24.5312 14.7156 25.5311 15.7154 26.7666 15.7154H38.8395C39.7857 15.7118 40.5927 15.1262 40.9213 14.2978Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4128 2.08918C17.1128 2.61051 16.9414 3.21398 16.9414 3.86029V5.10294C16.9414 6.58482 17.8412 7.85603 19.1267 8.39879C19.1267 8.39879 20.4872 5.32076 24.0973 5.10294V3.86029C24.0973 3.01044 23.8009 2.22844 23.3046 1.61426C22.8725 1.58569 22.4404 1.57141 22.0012 1.57141C20.4194 1.57498 18.8875 1.75352 17.4128 2.08918Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.57031 22C1.57031 22.3821 1.58103 22.7641 1.60246 23.1427L8.80834 19.8289C10.4545 19.0719 11.665 17.6079 12.1006 15.8475L14.2181 7.27756C14.5502 5.9278 14.3967 4.51733 13.8075 3.2854C6.60515 6.43842 1.57031 13.6336 1.57031 22Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Planet.displayName = 'Planet';

export default Planet;
