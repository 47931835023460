import { IIcon } from './types';

function Trust({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M13.1117 27.546L7.82812 41.2037L12.4593 40.5691C12.7604 40.5268 13.0616 40.6466 13.2479 40.8723L15.9937 44.2144L21.1052 31.0221M23.3491 31.0221L28.4462 44.2144L31.1919 40.8723C31.3783 40.6396 31.6794 40.5197 31.9877 40.562L36.6189 41.1966L31.3354 27.546"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.3976 27.007C10.0901 25.6994 10.9573 22.9544 10.2918 21.3459C9.60187 19.6785 7.07031 18.3365 7.07031 16.5599C7.07031 14.7835 9.60189 13.4415 10.2918 11.7741C10.9573 10.1656 10.0901 7.42056 11.3976 6.11304C12.7051 4.80552 15.4502 5.67275 17.0587 5.00723C18.7261 4.31733 20.0681 1.78577 21.8446 1.78577C23.6211 1.78577 24.963 4.31734 26.6304 5.00724C28.2389 5.67275 30.984 4.80552 32.2915 6.11304C33.599 7.42056 32.7318 10.1656 33.3973 11.7741C34.0872 13.4415 36.6188 14.7836 36.6188 16.5601C36.6188 18.3365 34.0872 19.6785 33.3973 21.3459C32.7318 22.9544 33.599 25.6994 32.2915 27.007C30.984 28.3145 28.2389 27.4473 26.6304 28.1128C24.963 28.8027 23.621 31.3342 21.8445 31.3342C20.068 31.3342 18.726 28.8027 17.0587 28.1128C15.4502 27.4472 12.7051 28.3145 11.3976 27.007Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.095 13.1505L19.7608 21.1059L15.5938 17.1282"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Trust.displayName = 'Trust';

export default Trust;
