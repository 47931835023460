import api from '@/lib/api/api';
import { DataResponse } from '@/lib/api/types';
import {
  CreateUserParams,
  CurrentUser,
  CurrentUserOnboarding,
  CurrentUserReferralData,
  Session,
  UserEmailLookupResponse,
} from './types';

const currentUserApi = api
  .enhanceEndpoints({
    addTagTypes: ['CurrentUser', 'CurrentUserOnboarding', 'Organisation'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      createUser: builder.mutation<
        { user: CurrentUser } & Session,
        CreateUserParams
      >({
        query: userData => ({
          url: `/api/users`,
          method: 'post',
          body: userData,
        }),
        transformResponse: r =>
          (r as DataResponse<{ user: CurrentUser } & Session>).data,
        invalidatesTags: ['CurrentUser'],
      }),

      createSession: builder.mutation<
        Session,
        {
          email?: string;
          password?: string;
          token?: string;
          type?: 'browser' | 'token';
          totp_code?: string;
          totp_token?: string;
          aud: 'Greener' | 'Greener Business';
          device_id?: string | null;
        }
      >({
        query: params => ({
          url: '/api/session',
          method: 'post',
          body: params,
        }),
        invalidatesTags: ['CurrentUser', 'Organisation'],
        transformResponse: r => (r as DataResponse<Session>).data,
      }),

      refreshSession: builder.mutation<
        Session,
        {
          device_id?: string | null;
          session_type?: 'browser' | 'api';
          aud: 'Greener' | 'Greener Business';
          app_info?: { device?: string; client?: string };
        } | void
      >({
        query: params => ({
          url: '/api/session/renew',
          method: 'put',
          body: params,
        }),
        invalidatesTags: ['CurrentUser'],
        transformResponse: r => (r as DataResponse<Session>).data,
      }),

      // updateSession: builder.mutation<Session, { refreshToken: string }>({
      //   query: ({ refreshToken }) => ({
      //     url: "/api/session",
      //     method: "put",
      //     body: {
      //       refresh_token: refreshToken,
      //     },
      //   }),
      //   invalidatesTags: ["CurrentUser"],
      //   transformResponse: (r) => (r as DataResponse<Session>).data,
      // }),

      endSession: builder.mutation<void, void>({
        query: () => ({
          url: '/api/session',
          method: 'delete',
        }),
      }),

      getCurrentUser: builder.query<CurrentUser | null, void>({
        query: () => ({
          url: '/api/current_user',
          method: 'get',
        }),
        providesTags: ['CurrentUser'],
        transformResponse: r => (r as DataResponse<CurrentUser | null>).data,
      }),

      validateEmail: builder.mutation<
        UserEmailLookupResponse,
        { email: string }
      >({
        query: ({ email }) => {
          return {
            url: '/api/session/email',
            method: 'post',
            body: {
              email,
              aud: 'Greener Business',
              magic: true,
            },
          };
        },
        transformResponse: r =>
          (r as DataResponse<UserEmailLookupResponse>).data,
      }),

      getCurrentUserOnboarding: builder.query<
        CurrentUserOnboarding,
        { accessToken?: string } | undefined
      >({
        query: params => ({
          url: '/api/current_user/onboarding',
          method: 'get',
          headers: params?.accessToken
            ? { authorization: `Bearer ${params?.accessToken}` }
            : undefined,
        }),
        providesTags: ['CurrentUserOnboarding'],
        transformResponse: r => (r as DataResponse<CurrentUserOnboarding>).data,
      }),

      updateCurrentUserOnboarding: builder.mutation<
        CurrentUserOnboarding,
        { onboarding_completed?: boolean; onboarding_permissions?: boolean }
      >({
        query: params => ({
          url: '/api/current_user/onboarding',
          method: 'PUT',
          body: params,
        }),
        invalidatesTags: ['CurrentUserOnboarding'],
        transformResponse: r => (r as DataResponse<CurrentUserOnboarding>).data,
      }),

      updateCurrentUser: builder.mutation<
        CurrentUser,
        { first_name: string; phone_number: string | null }
      >({
        query: params => ({
          url: '/api/current_user',
          method: 'PUT',
          body: params,
        }),
        invalidatesTags: ['CurrentUser'],
        transformResponse: r => (r as DataResponse<CurrentUser>).data,
      }),

      updateCurrentUserEmail: builder.mutation<CurrentUser, { email: string }>({
        query: ({ email }) => ({
          url: '/api/current_user/update_email',
          method: 'POST',
          body: {
            email,
          },
        }),
        invalidatesTags: ['CurrentUser'],
        transformResponse: r => (r as DataResponse<CurrentUser>).data,
      }),

      getCurrentUserWebsocketToken: builder.query<string, void>({
        query: () => ({
          url: '/api/current_user/websocket_token',
          method: 'GET',
        }),
        transformResponse: r =>
          (r as DataResponse<{ token: string }>).data.token,
      }),

      resendVerificationLink: builder.mutation<void, void>({
        query: () => ({
          url: '/api/current_user/email_verify',
          method: 'POST',
        }),
      }),

      getCurrentUserReferralData: builder.query<
        CurrentUserReferralData,
        { organisationId?: string; personalised?: boolean }
      >({
        query: ({ organisationId, personalised }) => {
          const searchParams = new URLSearchParams();
          if (organisationId) {
            searchParams.set('organisation_id', organisationId);
          }
          if (personalised) {
            searchParams.set('personalised', personalised.toString());
          }

          return {
            url: `/api/current_user/referral?${searchParams.toString()}`,
            method: 'GET',
          };
        },
        transformResponse: r =>
          (r as DataResponse<CurrentUserReferralData>).data,
      }),
    }),
  });

export default currentUserApi;

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserOnboardingQuery,
  useLazyGetCurrentUserOnboardingQuery,
  useGetCurrentUserWebsocketTokenQuery,
  useLazyGetCurrentUserWebsocketTokenQuery,
  useUpdateCurrentUserOnboardingMutation,
  useCreateSessionMutation,
  useRefreshSessionMutation,
  useEndSessionMutation,
  useValidateEmailMutation,
  useCreateUserMutation,
  // useUpdateSessionMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentUserEmailMutation,
  useResendVerificationLinkMutation,
  useGetCurrentUserReferralDataQuery,
  util: { getRunningOperationPromises },
} = currentUserApi;

export const { getCurrentUser } = currentUserApi.endpoints;
