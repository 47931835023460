import { getPageSourceCookies } from '@/helpers/cookieHelper';
import { CurrentUser } from '@/lib/auth/types';
import { getGamEventProperties } from '@/lib/gam';
import { AnalyticsBrowser } from '@segment/analytics-next';
export interface AnalyticsData {
  event: string;
  properties?: {
    [key: string]: unknown;
  };
}

export interface IUserAnalyticsObject {
  name?: string;
  email?: string;
  joined_date?: string;
  opt_out_marketing?: boolean;
  business_questionnaire_completed?: boolean;
  auth_provider?: string;
  organisation_id?: string[];
}

export const segmentEventName = {
  actionViewed: 'Action Viewed',
  actionHidden: 'Action Hidden',
  badgeViewed: 'Badge Viewed',
  buttonClicked: 'Button Clicked',
  screenViewed: 'Screen Viewed',
  userSignup: 'G4B Signup',
  userSignin: 'User Signed In',
  userSignedout: 'User Signed Out',
  organisationCreated: 'Organisation Created',
  quizStarted: 'Quiz Started',
  quizQuestionAnswered: 'Quiz Question Answered',
  quizCompleted: 'Quiz Completed',
  forgotPassword: 'Forgot Password Requested',
  accountUpdated: 'Account Updated',
  emailEntered: 'Email Entered',
  listingSubmitted: 'Listing Submitted',
  listingUpdateSubmitted: 'Listing Update Submitted',
  contactFormSubmitted: 'Contact Form Submitted',
  articleViewed: 'Article Viewed',
  outboundLinkClicked: 'Outbound Link Clicked',
  caseStudyClicked: 'Case Study Clicked',
  freeTrialStarted: 'Free Trial Started',
  // Offers
  offerRedeemed: 'Offer Redeemed',
  offerClaimClicked: 'Offer Claim Clicked',
  offerPageVisited: 'Offer Page Visited',
  offerFormSubmitted: 'Offer Form Submitted',
  offerStepCompleted: 'Offer Step Completed',
  offerFormCancelled: 'Offer Form Cancelled',
  offerFeedbackSubmitted: 'Offer Feedback Submitted',
  partnerWebsiteVisited: 'Partner Website Visited',
};

export const campaignEventName = {
  modalViewed: 'Modal Viewed',
  electricityBillUploadStarted: 'Electricity Bill Upload Started',
  electricityBillUploadCompleted: 'Electricity Bill Upload Completed',
  electricityBillUploadFailed: 'Electricity Bill Upload Failed',
  electricityBillUploadOld: 'Electricity Bill Upload Old',
  electricitySavingSucceded: 'Electricity Saving Succeded',
  electricitySavingFailed: 'Electricity Saving Failed',
  addressEntered: 'Address Entered',
  addressOfferSuccess: 'Address Offer Success',
  addressOfferFailed: 'Address Offer Failed',
  postcodeEntered: 'Postcode Entered',
  postcodeOfferSuccess: 'Postcode Offer Success',
  postcodeOfferFailed: 'Postcode Offer Failed',
};

const LOG_ANALYTICS =
  process.env.NEXT_PUBLIC_LOG_ANALYTICS === 'true' ||
  process.env.NODE_ENV === 'development';

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || '',
});

export function loginTypeFromRedirectUrl(url: string): 'web' | 'mobile' {
  // if (urlIsAppScheme(url)) {
  //   return 'mobile';
  // }
  return 'web';
}

export function sendAnalyticsSignInEvent(
  user: CurrentUser,
  type: string,
  event = segmentEventName.userSignin,
) {
  //send to segment with sign in event
  sendAnalyticsEvent({
    event,
    properties: {
      email: user.email,
      type,
      'end-source': 'frontend',
      platform_name: 'G4B',
    },
  });
}

export async function sendAnalyticsEvent(data: AnalyticsData | undefined) {
  if (data?.event) {
    const fullProperties = {
      ...data.properties,
      platform_name: 'G4B',
    };
    if (LOG_ANALYTICS) {
      console.log(
        `%cAnalytics%c ${data.event}`,
        'color: color(display-p3 1 0.213 0.228 / 1); background: #000',
        'color: #000; background: #fff',
      );
      console.table(fullProperties);
    }

    try {
      analytics.track(data.event, fullProperties);
    } catch {}
  }
}

export async function sendAnalyticsUserID(
  user_id: string | null,
  userProperties?: IUserAnalyticsObject | null,
  options?: { first_login?: boolean; referrer?: string },
) {
  if (user_id && userProperties) {
    let fullProperties = {
      ...removeNullProperties(userProperties),
      // Send page_source cookies if first login
      ...(options?.first_login ? getPageSourceCookies() : {}),
      // Send UTM tags if first login
      ...(options?.first_login ? getGamEventProperties() : {}),
      // Send referrer if first login
      ...(options?.first_login ? { g4b_referrer: options.referrer } : {}),
      platform_name: 'G4B',
    };

    if (LOG_ANALYTICS) {
      console.log(
        `%cAnalytics%c Identify`,
        'color: color(display-p3 1 0.213 0.228 / 1); background: #000',
        'color: #000; background: #fff',
      );
      console.table(fullProperties);
    }

    try {
      await analytics.identify(user_id, fullProperties);
    } catch {}
  }
}

export function sendResetEvent() {
  try {
    analytics.reset();
  } catch {}
}

export const sendSegementNavButtonClicked = (
  name: string,
  button_text: string,
  href?: string,
) => {
  sendAnalyticsEvent({
    event: segmentEventName.buttonClicked,
    properties: {
      action: `${name} Button Clicked`,
      button_text,
      href,
      platform_name: 'G4B',
    },
  });
};

// Remove any object key that contains null.
function removeNullProperties(obj: any) {
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;
    if (value === null) {
      delete obj[key];
    } else if (typeof value !== 'string' && hasProperties) {
      removeNullProperties(value);
    }
  });
  return obj;
}
