import { IIcon } from './types';

function Promotions({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M14.5146 27.6816C15.9163 26.28 17.5758 26.1252 18.6573 27.2067C19.9113 28.4607 19.7815 30.2341 18.3105 31.7051C17.7075 32.3081 16.8626 32.808 16.0159 33.0997C15.5979 33.2438 15.1425 33.0054 15.0055 32.5839C14.865 32.1481 15.1158 31.6731 15.5605 31.5664C16.1528 31.4259 16.6669 31.1502 17.0547 30.7624C17.7644 30.0527 17.8409 29.3181 17.2788 28.756C16.7683 28.2455 16.0586 28.3504 15.3595 29.0495C15.1496 29.2594 14.7281 29.6952 14.3474 30.1007C14.0041 30.4654 13.4278 30.476 13.072 30.1203L11.2364 28.2846C10.7561 27.8044 10.7561 27.0253 11.2364 26.545L13.7747 24.0067C14.0895 23.6919 14.6018 23.6919 14.9166 24.0067C15.2314 24.3216 15.2314 24.8338 14.9166 25.1487L12.7625 27.3027L13.8262 28.3664L14.5128 27.6798L14.5146 27.6816Z"
        fill="#19A47A"
      />
      <path
        d="M23.0312 20.8137C25.0128 22.7952 25.2707 24.7145 23.7659 26.2193C22.2521 27.733 20.3311 27.4733 18.3514 25.4936C16.3787 23.5209 16.1119 21.5928 17.6256 20.0791C19.1305 18.5743 21.0586 18.8411 23.0312 20.8137ZM19.6747 24.1702C21.1102 25.6056 22.0618 25.9169 22.7626 25.2161C23.4546 24.5242 23.1433 23.5725 21.7079 22.1371C20.2635 20.6927 19.3208 20.3904 18.6288 21.0823C17.928 21.7831 18.2304 22.7259 19.6747 24.1702Z"
        fill="#19A47A"
      />
      <path
        d="M25.7443 15.6586C26.8773 16.7917 26.9378 18.1151 25.9079 19.145C24.8869 20.166 23.5635 20.1055 22.4305 18.9725C21.3063 17.8483 21.2369 16.516 22.2579 15.495C23.2878 14.4651 24.6201 14.5345 25.7443 15.6586ZM23.2878 18.1151C23.962 18.7892 24.6628 18.9031 25.1644 18.4015C25.6749 17.891 25.5611 17.1901 24.8869 16.516C24.2128 15.8419 23.512 15.728 23.0015 16.2385C22.5016 16.7383 22.6137 17.4409 23.2878 18.1151ZM27.6991 11.7081L29.1115 20.3812C29.1488 20.6071 29.0741 20.8384 28.9105 21.002L28.8962 21.0162C28.4836 21.4289 27.7774 21.1995 27.6849 20.6231L26.2886 11.9339C26.2512 11.708 26.3259 11.4768 26.4878 11.3149C26.9005 10.9058 27.6066 11.1317 27.6991 11.7081ZM33.0105 13.3054C34.1435 14.4384 34.204 15.7618 33.1741 16.7917C32.1531 17.8127 30.8297 17.7522 29.6967 16.6192C28.5725 15.495 28.5031 14.1627 29.5241 13.1417C30.554 12.1118 31.8863 12.1812 33.0105 13.3054ZM30.554 15.7618C31.2282 16.436 31.929 16.5498 32.4306 16.0482C32.9411 15.5377 32.8273 14.8369 32.1531 14.1627C31.479 13.4886 30.7781 13.3747 30.2676 13.8852C29.766 14.3868 29.8799 15.0877 30.554 15.7618Z"
        fill="#19A47A"
      />
      <path
        d="M2 27.6847L16.3153 42L42.8367 15.4787C42.9498 15.3655 43.0078 15.2117 42.9991 15.0521L42.3318 3.83548C42.2622 2.66914 41.3309 1.7378 40.1645 1.66816L28.9479 1.00085C28.7883 0.992151 28.6345 1.05018 28.5214 1.16333L2 27.6847Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle cx="36.5" cy="7.5" r="2.5" stroke="#19A47A" strokeWidth="2" />
    </svg>
  );
}

Promotions.displayName = 'Promotions';

export default Promotions;
