import classNames from 'classnames';

export default function OrSeparator({ className }: { className?: string }) {
  return (
    <div className={classNames(`flex items-center`, className)}>
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="mx-2 flex-shrink font-sans-semibold text-base font-semibold text-gray-700">
        or
      </span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  );
}
