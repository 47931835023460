import Link from 'next/link';

export default function PrivacyPolicy() {
  return (
    <div>
      <p className="p6">
        By continuing I agree to the{' '}
        <Link
          href="https://greener.com.au/greener-for-business-terms/"
          passHref
          className="font-sans-bold text-sm underline"
          target="_blank"
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          href="https://greener.com.au/privacy-policy/"
          passHref
          className="font-sans-bold text-sm underline"
          target="_blank"
        >
          Privacy Policy
        </Link>
        , and to receive important email updates. You may unsubscribe at any
        time.
      </p>
    </div>
  );
}
