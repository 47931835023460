import { IIcon } from './types';

function Insights({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 44 44">
      <path
        d="M15.1875 38.6875H29.5625"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1016 43H28.647"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.375 33.9829V23.9204"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.625 18.1704L22.375 23.9204L28.125 18.1704"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5171 28.0552C11.8078 26.7202 10.4233 25.0151 9.46781 23.0681C8.51227 21.1211 8.01051 18.9828 8.00022 16.814C7.95716 9.02233 14.2387 2.5453 22.0284 2.36196C25.0469 2.28927 28.0117 3.16887 30.5021 4.87598C32.9926 6.58309 34.8823 9.03104 35.9033 11.8726C36.9242 14.7141 37.0245 17.805 36.19 20.7068C35.3554 23.6085 33.6284 26.1738 31.2538 28.0388C30.7308 28.444 30.3068 28.9629 30.0138 29.5561C29.7208 30.1493 29.5665 30.8013 29.5625 31.4629L29.5625 32.5454C29.5625 32.9266 29.4111 33.2923 29.1415 33.5618C28.8719 33.8314 28.5063 33.9829 28.125 33.9829H16.625C16.2437 33.9829 15.8781 33.8314 15.6085 33.5618C15.3389 33.2923 15.1875 32.9266 15.1875 32.5454L15.1875 31.4617C15.1858 30.8043 15.0346 30.156 14.7452 29.5658C14.4557 28.9756 14.0358 28.459 13.5171 28.0552V28.0552Z"
        stroke="#19A47A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Insights.displayName = 'Insights';

export default Insights;
