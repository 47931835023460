import OneSignal from 'react-onesignal';
import { CurrentUser } from '../auth/types';

class OneSignalTokenManager {
  static shared = new OneSignalTokenManager();

  private token?: string = undefined;
  private canAsk = true;
  private initialized = false;

  initialize = async () => {
    if (this.initialized) return;
    if (!process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID) return;

    this.initialized = true;

    try {
      await OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID as string,
        notifyButton: {
          enable: true,
        },
      });
      // await OneSignal.showSlidedownPrompt();
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  identifyUser = (user: CurrentUser) => {
    if (!this.initialized) return;

    // Set the external user id, with hash
    OneSignal.setExternalUserId(user.id, user.onesignal_business_id_hash);

    // Set the email, with hash
    OneSignal.setEmail(user.email, {
      emailAuthHash: user.onesignal_business_email_hash,
    });

    // Add some tags
    OneSignal.sendTags({
      environment:
        process.env.NODE_ENV !== 'production'
          ? 'local'
          : (process.env.NEXT_PUBLIC_GREENER_BUSINESS_ENVIRONMENT ?? 'prod'),
      greener_business: 'true',
    });
  };
}

export default OneSignalTokenManager;
