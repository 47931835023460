import { MILESTONES_ITEMS } from './data';
import {
  ActionCategory,
  ActionProgress,
  OrganisationSustainabilityAction,
} from './types';

export function formatCount(count?: number | null) {
  if (!count) return undefined;

  return Intl.NumberFormat('en', { notation: 'compact' }).format(count);
}

export function lastAchivedMilestone(
  completedActions?: OrganisationSustainabilityAction[],
) {
  if (!completedActions || completedActions.length === 0) {
    return;
  }

  let lastMilestone;
  for (let i = 0; i < completedActions.length; i++) {
    const milestone = MILESTONES_ITEMS.find(x => x.value === i + 1);
    if (milestone) {
      lastMilestone = milestone;
    }
  }

  return lastMilestone;
}

export function mapActionProgressToActionProgressMethod(
  status: ActionProgress,
) {
  switch (status) {
    case 'incomplete':
      return 'uncomplete';
    case 'in_progress':
      return 'in_progress';
    case 'completed':
      return 'complete';
    case 'hide':
      return 'hide';
  }
}

// This function will retrun the first category title without "Your plan" option
export function getCategoryTitle(categories?: ActionCategory[]) {
  if (!categories) return '';
  return (
    // Not sure how backend will return it, just to be sure ill check different formats
    categories.filter(
      x =>
        x.title !== 'Your plan' &&
        x.title !== 'your plan' &&
        x.title !== 'YOUR PLAN',
    )[0]?.title || ''
  );
}

export function abbreviateNumber(number: number) {
  if (number === 0) return number;

  const prefixes = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const tier = prefixes.filter(n => number >= n.value).pop();
  const numberFixed = +(number / tier!!.value).toFixed(1);

  return `${numberFixed}${tier!!.symbol}`;
}

// Abbreviation for all saving values
export function abbreviateSavingsValue(annualized_cost_savings: number) {
  return `$${abbreviateNumber(+annualized_cost_savings.toFixed(0))}/year`;
}
