import Spinner from '@/components/Icons/Spinner';
import { InlineLoadError } from '@/components/InlineLoadError';
import { OrganisationLogoDefault } from '@/lib/organisations/helpers';
import { useOrganisationId } from '@/lib/organisations/OrganisationIDProvider';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { Fragment } from 'react';
import { usePaginateOrganisationsForActionQuery } from '../api';
import { formatCount } from '../helpers';
import { SustainabilityActionCompletedOrganisation } from '../types';

export default function CompletedOrganisationsModal({
  completionCount,
  open = false,
  actionSlug,
  onClose,
}: {
  completionCount: number;
  open?: boolean;
  onClose: () => void;
  actionSlug: string;
}) {
  const organisationId = useOrganisationId();

  const { data, isError, isLoading, isFetching, refetch } =
    usePaginateOrganisationsForActionQuery(
      {
        actionIdOrSlug: actionSlug,
        organisationId,
        paginationParams: {
          page_size: 50,
        },
      },
      { skip: !open, refetchOnMountOrArgChange: true },
    );

  return (
    <Transition show={open} appear>
      <Dialog open={true} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="max-md:translate-y-full md:opacity-0 md:scale-105"
          enterTo="md:opacity-100 translate-y-0"
          leave="duration-300 ease-in"
          leaveFrom="translate-y-0 md:opacity-100"
          leaveTo="max-md:translate-y-full md:opacity-0 md:scale-105"
        >
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center max-md:items-end max-md:pt-4">
              <Dialog.Panel className="relative flex w-full max-w-4xl flex-col items-center justify-start overflow-y-auto rounded-t-3xl bg-white md:mx-4 md:my-4 md:justify-center md:rounded-[1rem] md:shadow-cardHover">
                <div className="border-1 flex h-16 w-full flex-grow flex-row items-center justify-center border-b border-gray-300 text-center">
                  <h1 className="h6">
                    {formatCount(completionCount)} completed this action
                  </h1>
                </div>
                {/* Larger devices button */}
                <button
                  className="absolute right-0 top-0 flex h-16 w-16 items-center justify-center"
                  onClick={onClose}
                >
                  <XIcon className="h-6 w-6" />
                </button>

                <InlineLoadError
                  isError={isError}
                  isLoading={isLoading}
                  message="Error loading list. Please try again"
                  refetch={refetch}
                />

                {isLoading || isFetching ? (
                  <div className="flex w-full px-7 py-3">
                    <Spinner />
                  </div>
                ) : null}

                {!isLoading && !isFetching ? (
                  <div className="w-full">
                    {data?.data?.map(r => (
                      <OrganisationRow key={r.id} organisation={r} />
                    ))}
                  </div>
                ) : null}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

function OrganisationRow({
  organisation,
}: {
  organisation: SustainabilityActionCompletedOrganisation;
}) {
  return (
    <div className="border-1 flex w-full flex-row items-center justify-start space-x-4 border-b border-gray-300 px-7 py-3 last:border-none">
      <Image
        className="h-12 w-12 rounded-full border-2 border-gray-300"
        src={organisation.logo_image_url ?? OrganisationLogoDefault}
        alt={`${organisation.name} logo`}
        width={48}
        height={48}
      />
      <div className="flex flex-col items-start justify-center">
        <div className="h6">{organisation.name}</div>
        <div className="p5 text-gray-700">{organisation.location}</div>
      </div>
    </div>
  );
}
