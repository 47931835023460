import api from '../api/api';
import { DataResponse } from '../api/types';
import { BadgeWithEarnedValue, ListBadgesForOrganisationParams } from './types';

const actionsApi = api
  .enhanceEndpoints({
    addTagTypes: ['OrganisationBadge'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      // List badges for an organisation
      listBadgesForOrganisation: builder.query<
        BadgeWithEarnedValue[],
        ListBadgesForOrganisationParams
      >({
        query: ({ organisationId, earned }) => {
          const params = new URLSearchParams();
          if (earned !== undefined) {
            params.set('earned', earned.toString());
          }

          return {
            url: `/api/cso/organisations/${organisationId}/badges?${params.toString()}`,
          };
        },
        providesTags: ['OrganisationBadge'],
        transformResponse: (r: DataResponse<BadgeWithEarnedValue[]>) => r.data,
      }),

      // Get badge for an organisation
      getBadgeForOrganisation: builder.query<
        BadgeWithEarnedValue,
        { organisationId: string; badgeIdorSlug: string }
      >({
        query: ({ organisationId, badgeIdorSlug }) => ({
          url: `/api/cso/organisations/${organisationId}/badges/${badgeIdorSlug}`,
        }),
        providesTags: badge => [{ type: 'OrganisationBadge', id: badge?.id }],
        transformResponse: (r: DataResponse<BadgeWithEarnedValue>) => r.data,
      }),
      getMultipleBadgesForOrganisation: builder.query<
        BadgeWithEarnedValue[],
        { organisationId: string; badgesIdorSlug: string[] }
      >({
        query: ({ organisationId, badgesIdorSlug }) => {
          const searchParams = new URLSearchParams();
          badgesIdorSlug?.forEach(slug => searchParams.append('slugs[]', slug));

          return {
            url: `/api/cso/organisations/${organisationId}/badges?${searchParams.toString()}`,
          };
        },
        providesTags: [{ type: 'OrganisationBadge' }],
        transformResponse: (r: DataResponse<BadgeWithEarnedValue[]>) => r.data,
      }),
    }),
  });

export default actionsApi;

export const {
  useListBadgesForOrganisationQuery,
  useGetBadgeForOrganisationQuery,
  useGetMultipleBadgesForOrganisationQuery,
  usePrefetch,
} = actionsApi;
