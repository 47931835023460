import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from 'react-feather';

export default function CarouselButton({
  disabled = false,
  direction = 'previous',
  onPress,
}: {
  disabled?: boolean;
  direction?: 'previous' | 'next';
  onPress?: () => void;
}) {
  return (
    <button
      className={classNames(
        'flex h-10 w-10 items-center justify-center rounded-full border  bg-white',
        { 'cursor-not-allowed border-gray-200 text-gray-400': disabled },
        { 'border-gray-400 text-gray-900': !disabled },
      )}
      disabled={disabled}
      onClick={onPress}
    >
      {direction == 'next' ? <ArrowRight /> : <ArrowLeft />}
    </button>
  );
}
