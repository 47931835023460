interface HubspotWindow {
  _hsq: unknown[];
}

export default function getHSQ(): unknown[] {
  const hsw: HubspotWindow | undefined =
    typeof window !== 'undefined'
      ? (window as unknown as HubspotWindow)
      : { _hsq: [] };
  const _hsq = hsw._hsq || [];
  return _hsq;
}
