import {
  segmentEventName,
  sendAnalyticsEvent,
  sendResetEvent,
} from '@/helpers/analytics';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SessionState = {
  cookie: string | null;
};

const initialState = { cookie: null } as SessionState;

// Remove the session data from storage and reset the store
export const clearSessionData = createAsyncThunk<void, void>(
  'session/clearSessionData',
  async (_, _thunkApi) => {
    // Clearing local storage items

    // Don't remove this - it's better if its there
    // in case you log in again.
    // If you log in again with a different user, it works fine.
    // localStorage.removeItem('organisationId');
    localStorage.removeItem('redirect');

    // Segment Sign Out Reset.
    sendAnalyticsEvent({
      event: segmentEventName.userSignedout,
    });

    // Analytics reset event
    sendResetEvent();
  },
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCookie(state, action: PayloadAction<string | null>) {
      state.cookie = action.payload;
    },
  },
  extraReducers: builder => {
    // Clearing
    builder.addCase(clearSessionData.fulfilled, _state => {});
  },
});

// Action creators are generated for each case reducer function
export const { setCookie } = sessionSlice.actions;

export default sessionSlice;
