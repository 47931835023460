export interface DataResponse<T> {
  data: T;
}

export interface ImageAsset {
  url: string;
  width: number;
  height: number;
  mime_type: string;
}

export interface PagedDataResponse<T> extends DataResponse<T> {
  data: T;
  page: {
    page_number: number;
    page_size: number;
    total_entries: number;
    total_pages: number;
  };
}

export interface PaginationParams {
  page_size?: number;
  page?: number;
}

export const ORGANISATION_REFETCH_TIME = 120;
