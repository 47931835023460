/* eslint-disable @next/next/no-script-component-in-head */
import EarnedBadgeModal from '@/components/EarnedBadges/EarnedBadgeModal';
import LoggedInLayout from '@/components/Layouts/LoggedInLayout';
import LoginOrSignUpPopUp from '@/components/LoginSignUpPopUp/LoginOrSignUpPopUp';
import {
  analytics,
  segmentEventName,
  sendAnalyticsEvent,
} from '@/helpers/analytics';
import useCachedResources from '@/hooks/useCachedResources';
import useEffectOnce from '@/hooks/useEffectOnce';
import {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserWebsocketTokenQuery,
} from '@/lib/auth/api';
import { GAMScript } from '@/lib/gam';
import getHSQ from '@/lib/hubspot';
import SocketManager from '@/lib/socket/SocketManager';
import { wrapper } from '@/lib/store';
import { NextComponentType, NextPage, NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React, { ReactElement, ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/addressfinder.css';
import '../styles/globals.css';

const isBrowser = () => typeof window !== 'undefined';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// eslint-disable-next-line react/display-name
function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  // useFullstory();

  // Track initial pageview
  useEffectOnce(() => {
    const origin =
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : '';

    sendAnalyticsEvent({
      event: segmentEventName.screenViewed,
      properties: {
        title: document.title,
        url: origin + router.asPath,
        page_name: Component.name,
      },
    });
    getHSQ().push(['setPath', window.location.href]);
    getHSQ().push(['trackPageView']);
  });

  // Track page changes
  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      const origin =
        typeof window !== 'undefined' && window.location.origin
          ? window.location.origin
          : '';

      // console.log ('page_name', Component.name);
      // console.log ('url', url);

      analytics.page(Component.displayName, {
        title: document.title,
        url: origin + url,
      });

      sendAnalyticsEvent({
        event: segmentEventName.screenViewed,
        properties: {
          title: document.title,
          url: origin + url,
          page_name: Component.displayName,
        },
      });
      getHSQ().push(['setPath', url]);
      getHSQ().push(['trackPageView']);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [Component, pageProps, router.events]);

  const _isLoadingComplete = useCachedResources();

  // Track currentUser, joins socket if user is logged in
  const { data: user } = useGetCurrentUserQuery();
  const [getWebsoketToken] = useLazyGetCurrentUserWebsocketTokenQuery();

  React.useEffect(() => {
    if (user) {
      getWebsoketToken()
        .unwrap()
        .then(token => {
          SocketManager.shared.connect(token, user.id);
        });
    }
  }, [user, getWebsoketToken]);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? MyApp.getDefaultLayout;

  return (
    <>
      <Head>
        <title>Greener for business</title>
        <meta
          name="description"
          content="The only all-in-one sustainability solution that makes it easy for businesses to reduce emissions and save money at the same time. With a tailored climate action plan and exclusive offers on more sustainable solutions from Australia’s leading providers."
        />
        <link rel="icon" href="/favicon.ico" />
        {/* <link rel="manifest" href="/manifest.json" /> */}
        <link rel="apple-touch-icon" href="/logo192.png" />
        <meta name="theme-color" content="#fff" />
      </Head>
      <SkeletonTheme baseColor="#EEEEEE" highlightColor="#FFFFFF">
        {getLayout(<Component {...pageProps} />)}

        <LoginOrSignUpPopUp />
        <EarnedBadgeModal />

        <Toaster position="bottom-center" />
        <GAMScript />
      </SkeletonTheme>
    </>
  );
}

MyApp.getDefaultLayout = function getLayout(page: ReactElement) {
  return <LoggedInLayout>{page}</LoggedInLayout>;
};

MyApp.getInitialProps = async function ({
  Component,
  ctx,
}: {
  Component: NextComponentType;
  ctx: NextPageContext;
}) {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  const nonce = ctx.req?.headers?.['x-nonce'] as string | undefined;
  return { pageProps: { ...pageProps, nonce } };
};

export default wrapper.withRedux(MyApp);
