import { ReactNode } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';

export default function LoggedInLayout({
  children,
  notLoggedIn,
  hideHeader,
  showLoginOnHeader,
  minimalHeader,
  signUpHeader,
  // hideBreadcrumbs,
  hideFooter,
  minimalFooter,
}: {
  children: ReactNode;
  hideHeader?: boolean;
  notLoggedIn?: boolean;
  showLoginOnHeader?: boolean;
  minimalHeader?: boolean;
  signUpHeader?: boolean;
  hideBreadcrumbs?: boolean;
  hideFooter?: boolean;
  minimalFooter?: boolean;
}) {
  return (
    <div className="flex min-h-screen flex-col justify-stretch">
      {!hideHeader ? (
        <Header
          notLoggedIn={notLoggedIn}
          showLoginOnHeader={showLoginOnHeader}
          minimalHeader={minimalHeader}
          signUpHeader={signUpHeader}
        />
      ) : null}
      {/* Scrolling area */}
      <div className="flex flex-grow flex-col">
        <main className="flex-grow">{children}</main>

        {!hideFooter && <Footer minimalFooter={minimalFooter} />}
      </div>
    </div>
  );
}
