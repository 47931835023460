import Wrapper from '@/containers/Wrapper';
import { sendSegementNavButtonClicked } from '@/helpers/analytics';
import classNames from '@/helpers/classNames';
import Logo from '@/public/logo-greener.svg';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SocialFacebook from '../Icons/SocialFacebook';
import SocialInstagram from '../Icons/SocialInstagram';
import SocialLinkedIn from '../Icons/SocialLinkedIn';
import { COMPONENT_NAME } from './types';

const FACEBOOK_URL = 'https://www.facebook.com/greener.au';
const INSTAGRAM_URL = 'https://www.instagram.com/greener.au';
const LINKEDIN_URL = 'https://www.linkedin.com/company/getgreener';

export const socialLinks = [
  {
    id: 'facebook',
    name: 'Facebook',
    href: FACEBOOK_URL,
  },
  {
    id: 'instagram',
    name: 'Instagram',
    href: INSTAGRAM_URL,
  },
  {
    id: 'linked_in',
    name: 'LinkedIn',
    href: LINKEDIN_URL,
  },
];

function iconForSocial(id: string) {
  switch (id) {
    case 'facebook':
      return SocialFacebook;
    case 'instagram':
      return SocialInstagram;
    case 'linked_in':
      return SocialLinkedIn;
    default:
      return undefined;
  }
}

function Footer({ minimalFooter }: { minimalFooter?: boolean }) {
  const {
    query: { organisationId },
  } = useRouter();

  const links = [
    {
      id: '5-1',
      name: 'Contact us',
      href: organisationId
        ? `/organisations/${organisationId}/contact-us`
        : '/contact-us',
    },
    {
      id: '5-2',
      name: 'Terms of Service',
      href: 'https://greener.com.au/greener-for-business-terms/',
      external: true,
    },
    {
      id: '5-3',
      name: 'Privacy policy',
      href: 'https://greener.com.au/privacy-policy/',
      external: true,
    },
  ];

  return (
    <footer className="relative w-full border-t border-black border-opacity-5 bg-white pb-16 pt-8">
      <div className={classNames('mb-4', minimalFooter ? 'hidden' : '')}>
        <Wrapper fullWidth>
          <div className="flex flex-col items-center gap-5 sm:flex-row sm:justify-between">
            <div className="shrink-0">
              <Link href="/" passHref className="inline-block">
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}

                <span className="block w-[6.9375rem]">
                  <Image
                    layout="fixed"
                    src={Logo as string}
                    alt="Greener"
                    height={24}
                    width={111}
                  />
                </span>
              </Link>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-3">
              {socialLinks &&
                socialLinks.map(socialLink => {
                  const Icon = iconForSocial(socialLink.id);
                  return (
                    <a
                      key={socialLink.id}
                      onClick={() =>
                        sendSegementNavButtonClicked(
                          socialLink.name,
                          socialLink.name,
                          socialLink.href,
                        )
                      }
                      href={socialLink.href}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={socialLink.name}
                      className="flex items-center justify-center p-2"
                    >
                      {Icon && <Icon className="inline-block h-6 w-6" />}
                    </a>
                  );
                })}
            </div>
          </div>
        </Wrapper>
      </div>
      <div>
        <Wrapper fullWidth>
          <div className="flex w-full flex-col items-center gap-5 sm:flex-row sm:justify-between">
            <div className="h8 w-auto space-x-8">
              {links &&
                links.length > 0 &&
                links.map(item => {
                  if (item.external) {
                    return (
                      <a
                        key={item.id}
                        onClick={() =>
                          sendSegementNavButtonClicked(
                            item.name,
                            item.name,
                            item.href,
                          )
                        }
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="h9"
                      >
                        {item.name}
                      </a>
                    );
                  }

                  return (
                    <Link
                      key={item.id}
                      href={item.href}
                      passHref
                      onClick={() =>
                        sendSegementNavButtonClicked(
                          item.name,
                          item.name,
                          item.href,
                        )
                      }
                      className="h9"
                    >
                      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}

                      {item.name}
                    </Link>
                  );
                })}
            </div>
            <div className="p5">&copy; {new Date().getFullYear()} Greener</div>
          </div>
        </Wrapper>
      </div>
    </footer>
  );
}

Footer.displayName = COMPONENT_NAME;

export default Footer;
