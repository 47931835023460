import React from 'react';
import Analytics from './Analytics';
import Check from './Check';
import Customers from './Customers';
import Emissions from './Emissions';
import Insights from './Insights';
import Listing from './Listing';
import Planet from './Planet';
import Promotions from './Promotions';
import Revenue from './Revenue';
import Trust from './Trust';

function Icon({
  name,
  className = 'h-11 w-11',
}: {
  name: string;
  className?: string;
}) {
  let Icon = null;
  switch (name) {
    case 'analytics':
      Icon = Analytics;
      break;
    case 'customers':
      Icon = Customers;
      break;
    case 'emissions':
      Icon = Emissions;
      break;
    case 'insights':
      Icon = Insights;
      break;
    case 'listing':
      Icon = Listing;
      break;
    case 'planet':
      Icon = Planet;
      break;
    case 'promotions':
      Icon = Promotions;
      break;
    case 'revenue':
      Icon = Revenue;
      break;
    case 'trust':
      Icon = Trust;
      break;
    case 'check':
      Icon = Check;
      break;
    default:
      Icon = null;
      break;
  }

  return Icon ? React.createElement(Icon, { className }) : null;
}

export default Icon;
